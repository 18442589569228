import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Backdrop, CircularProgress } from '@mui/material';
import { FormVerificacionResi } from './FormVerificacionResi';
import { ObtenerToken } from '../../api/Licencias';
import Swal from 'sweetalert2';

const steps = ['Información', 'Residencia'];

export const FormContribuyente = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const datos = location.state;
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState("")
    const [infoContr, setInfoContr] = useState("")
    const [token, setToken] = useState("");
    const [isRequired, setIsRequired] = useState(false); // Estado que cambiará según la consulta
    const [isDisabled, setIsDisabled] = useState(true);
    const [isRequiredTipo, setIsRequiredTipo] = useState(false); // Estado que cambiará según la consulta
    const [isDisabledTipo, setIsDisabledTipo] = useState(false);
    const [isDisabledNum, setIsDisabledNum] = useState(true);
    const schema = yup.object().shape({
        nombre: yup.string().required("Este campo es Obligatorio"),
        primerApellido: yup.string().required("Este campo es Obligatorio"),
        segundoApellido: yup.string(),
        tipoTramite: yup.string().required("Este campo es Obligatorio"),
        numLicencia: yup.string().test(
            'numLicencia',
            'Este campo es Obligatorio',
            function (value) {
                if (isRequired && !value) {
                    return false; // Si la condición es verdadera y el valor está vacío, la validación falla
                }
                return true; // De lo contrario, pasa la validación
            }
        ),
        curp: yup.string().required("Este campo es Obligatorio").matches(/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}(?:[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[HM]{1}(AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "Formato no válido"),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus }) => {

            if (values.tipoTramite === "233" || values.tipoTramite === "234") {
                setInfo(values)
                handleNext()
            } else {
                if (infoContr.Mensaje === undefined && infoContr.tipoLicenciaId !== undefined && infoContr.tipoLicenciaId !== datos) {

                    setInfo(values)
                    
                    handleNext()
                } else {
                    setInfo(values)
                    setInfoContr(values);
                    handleNext()
                }
            }
        },
        initialValues: {
            nombre: "",
            primerApellido: "",
            segundoApellido: "",
            curp: "",
            tipoTramite: "",
            numLicencia: ""
        },
    });
    useEffect(() => {
        ObtenerToken().then(result => {
            console.log(result)
            if (result.ok === true) {
                setToken(result.message.token)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);
    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
    
        if (name === "curp") {
            values = values.replace(/[^A-Z0-9]/g, ''); // Solo letras y números sin caracteres especiales
            formik.setFieldValue(name, values);
        } else if (name === "numLicencia") {
            values = values.replace(/[^0-9]/g, ''); // Solo números
            formik.setFieldValue(name, values);
        } else {
            // Permitir letras acentuadas, Ñ, y caracteres especiales
            values = values.replace(/[^A-ZÁÉÍÓÚÑÜ\s'.,-]/g, '');
            formik.setFieldValue(name, values);
        }
    };
    

    const ValidarRegistro = (values, curp) => {
        console.log(values, curp);
        if (values.nombre === "" && values.primerApellido === "") {
            toast.error("Datos Faltantes", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            formik.setFieldTouched("nombre", true)
            formik.setFieldTouched("primerApellido", true)
        } else {
            setLoading(true);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const raw = JSON.stringify({
                "tipoLicenciaId": parseInt(datos),
                "nombre": values.nombre,
                "apellido1": values.primerApellido,
                "apellido2": values.segundoApellido,
                "curp": curp
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://bw5ulxlmwh.execute-api.us-east-1.amazonaws.com/master/servicios/sei/servicios/check-license", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.error === undefined) {
                        if (result.Mensaje === "Persona registrada con tipo de licencia seleccionada. CURP registrada 2 veces") {
                            Swal.fire({
                                title: "CURP Registrada 2 veces",
                                text: "Registro de CURP con inconsistencias. Por favor acude al módulo de atención más cercano.",
                                icon: "error",
                                confirmButtonColor: "#607472",
                                confirmButtonText: "Aceptar"
                            }).then((result) => {
                                if (result.isConfirmed) {

                                }
                            });
                        } else if (result.Mensaje === "Persona no registrada con tipo de licencia seleccionada") {
                            toast.success("Validacion Correcta", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            formik.setFieldValue("tipoTramite", 232);
                            setIsRequiredTipo(false);
                            setIsDisabled(false);
                        } else if (result.Mensaje === "Persona registrada con tipo de licencia seleccionada") {
                            setIsRequired(true)
                            setIsDisabledNum(false);
                            setIsRequiredTipo(true);
                            setIsDisabledTipo(true);
                        } else if (result.Mensaje === undefined && result.tipoLicenciaId !== datos) {
                            console.log("----------------------")
                            toast.success("Validacion Correcta", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            formik.setFieldValue("tipoTramite", 232);
                            setIsRequiredTipo(false);
                            setIsDisabled(false);
                            setInfoContr(result);
                        }

                    } else {

                        toast.error(result.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    setLoading(false)


                });
        }
    }

    const ValidarLicencia = (values) => {
        setLoading(true)
        console.log(values);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "tipoLicencia": datos,
            "nombre": values.nombre,
            "apellido1": values.primerApellido,
            "apellido2": values.segundoApellido,
            "curp": values.curp,
            "numLicencia": values.numLicencia
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://bw5ulxlmwh.execute-api.us-east-1.amazonaws.com/master/servicios/sei/servicios/check-license", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.error === undefined) {
                    toast.success("Validacíon Correcta", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setInfoContr(result);
                    setIsDisabled(false)
                } else {

                    toast.error(result.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setIsDisabled(true)
                    setIsDisabledTipo(false);
                    formik.setFieldValue("tipoTramite", "");
                }
                setLoading(false)

            })
            .catch((error) => console.error(error));
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Trámite de Licencias</View>
                <View className='FormSubTitle'>Validación del Contribuyente</View>
            </div>

            <View className="ViewPrincipal">
                <Row className='mb-3'>
                    <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/tramite/DPC-Licencias")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <View className="">


                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};


                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel  {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <></>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 ?

                                    <Form onSubmit={formik.handleSubmit} className='mt-3 ViewActa'>

                                        <Box>
                                            <Row className='mb-3'>
                                                <Form.Label style={{ textDecoration: "underline" }}>Datos Requeridos</Form.Label>
                                            </Row>

                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Nombre (s)*</Form.Label>
                                                    <Form.Control
                                                        name='nombre'
                                                        type='text'
                                                        placeholder='Nombre (s)'
                                                        disabled={isDisabledTipo}
                                                        value={formik.values.nombre}
                                                        onChange={(event) => handleInputChange(event)}
                                                        isValid={formik.touched.nombre && !formik.errors.nombre}
                                                        isInvalid={formik.touched.nombre && !!formik.errors.nombre}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.nombre}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Primer Apellido*</Form.Label>
                                                    <Form.Control
                                                        name='primerApellido'
                                                        type='text'
                                                        disabled={isDisabledTipo}
                                                        placeholder='Primer Apellido'
                                                        value={formik.values.primerApellido}
                                                        onChange={(event) => handleInputChange(event)}
                                                        isValid={formik.touched.primerApellido && !formik.errors.primerApellido}
                                                        isInvalid={formik.touched.primerApellido && !!formik.errors.primerApellido}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.primerApellido}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Segundo Apellido</Form.Label>
                                                    <Form.Control
                                                        name='segundoApellido'
                                                        type='text'
                                                        placeholder='Segundo Apellido'
                                                        disabled={isDisabledTipo}
                                                        value={formik.values.segundoApellido}
                                                        onChange={(event) => handleInputChange(event)}
                                                        isValid={formik.touched.segundoApellido && !formik.errors.segundoApellido}
                                                        isInvalid={formik.touched.segundoApellido && !!formik.errors.segundoApellido}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.segundoApellido}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>CURP</Form.Label>
                                                    <Form.Control
                                                        name='curp'
                                                        type='text'
                                                        placeholder='CURP'
                                                        disabled={isDisabledTipo}
                                                        value={formik.values.curp}
                                                        maxLength={18}
                                                        onChange={(event) => {
                                                            handleInputChange(event);
                                                            const valueCurp = event.target.value.toUpperCase();

                                                            // Expresión regular para validar la CURP
                                                            const curpRegex = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}(?:[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[HM]{1}(AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;

                                                            console.log(valueCurp);


                                                            // Validar si la CURP tiene el formato correcto
                                                            if (valueCurp.length === 18) {
                                                                if (curpRegex.test(valueCurp)) {
                                                                    // Si la CURP es válida, se establece en Formik y se elimina el error
                                                                    formik.setFieldValue("curp", valueCurp);
                                                                    formik.setFieldError("curp", ""); // Elimina el error si es válido
                                                                    ValidarRegistro(formik.values, valueCurp);
                                                                } else {
                                                                    formik.setFieldTouched('curp', true); // Marca el campo como tocado
                                                                    // Si la CURP no es válida, se establece un mensaje de error en Formik
                                                                    formik.setFieldError("curp", "El formato de la CURP es incorrecto.");
                                                                }
                                                            }
                                                        }}


                                                        isValid={formik.touched.curp && !formik.errors.curp}
                                                        isInvalid={formik.touched.curp && !!formik.errors.curp}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.curp}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            {isRequired ?

                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label>Número de Licencia*</Form.Label>
                                                        <Form.Control
                                                            name='numLicencia'
                                                            type='text'
                                                            placeholder='Número de licencia'
                                                            disabled={isDisabledNum}
                                                            value={formik.values.numLicencia}
                                                            onChange={(event) => handleInputChange(event)}
                                                            onBlur={() => ValidarLicencia(formik.values)}
                                                            isValid={formik.touched.numLicencia && !formik.errors.numLicencia}
                                                            isInvalid={formik.touched.numLicencia && !!formik.errors.numLicencia}
                                                        />
                                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.numLicencia}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                : null
                                            }
                                            {isRequiredTipo &&

                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label>Tipo de Tramite*</Form.Label>
                                                        <Form.Select
                                                            name='tipoTramite'
                                                            type='text'
                                                            value={formik.values.tipoTramite}
                                                            onChange={formik.handleChange}
                                                            disabled={isDisabled}
                                                            isValid={formik.touched.tipoTramite && !formik.errors.tipoTramite}
                                                            isInvalid={formik.touched.tipoTramite && !!formik.errors.tipoTramite}
                                                        >
                                                            <option value="">SELECCIONE</option>
                                                            <option value={233}>REFRENDO</option>
                                                            <option value={234}>REPOSICION</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.tipoTramite}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                            }
                                        </Box>
                                        <Row className='mb-3'>
                                            <Button variant='secondary' disabled={isDisabled} type='submit'>SIGUIENTE</Button>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Button variant='link' onClick={() => navigate("/tramite/DPC-Licencias/Historial")}>REGRESAR</Button>
                                        </Row>
                                    </Form>


                                    :
                                    activeStep === 1 && (

                                        <FormVerificacionResi datos={datos} handleBack={handleBack} info={info} infoContr={infoContr} />
                                    )
                                }
                            </React.Fragment>
                        )}
                    </Box>


                    <ToastContainer />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}

                    >
                        <h1>Validando la Información</h1>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </View>
            </View>
        </>
    )
}
