import React from 'react';  
import '../../App.css'
import { useTramiteDataGeneral } from '../../TramiteContext'
import { Col, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import numeral from 'numeral';
import { Button as ButtonB } from 'react-bootstrap';
import { Loader } from "@aws-amplify/ui-react";
import { ButtonPago } from './ButtonPago';


const ResumenCard = ({ calculoImp, activeStep,desableCalc = false, tipo = "general", empleados = 0, montoAnt = 0, pagosProvisionales , annio, ligaPagoMit, idForm}) => {
    const { data, calculo } = useTramiteDataGeneral()
    console.log("------", data, calculo)
    function sumarPropiedadArray(array, propiedad) {
        // Verifica si el argumento es un array
        if (!Array.isArray(array)) {
            return "Error: El argumento no es un array.";
        }

        // Utiliza el método reduce para sumar los valores de la propiedad del array de objetos
        const suma = array.reduce((acumulador, objeto) => {
            // Asegura que el objeto actual tenga la propiedad especificada y que su valor sea un número
            const valorPropiedad = objeto[propiedad];

            // Suma el valor de la propiedad al acumulador si es un número válido
            return isNaN(valorPropiedad) ? acumulador : acumulador + valorPropiedad;
        }, 0);

        return suma;
    }
    // function obtenerValorPorDescripcion(arr, conceptoBuscada) {
    //     if (!arr || arr.length <= 0) {
    //         return null
    //     }
    //     const elementoEncontrado = arr.find(item => item.concepto === conceptoBuscada);
 
    //     if (elementoEncontrado) {
    //         return elementoEncontrado.monto;
    //     } else {
    //         return null; // O algún valor por defecto o un mensaje indicando que no se encontró
    //     }
    // }
   
    const ListaConceptos = (conceptos) => {
        // Validar si conceptos existe antes de renderizar la lista
        if (!conceptos || conceptos.length === 0) {
            return <p>No hay datos disponibles</p>;
        }

        return (
            <div style={{ display: "grid", gridTemplatedivumns: "1fr" }}>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                            <div className='text-left font-bold text-sm'>Clave</div>
                            <div className='text-left font-bold text-sm'>Descripción</div>
                        </div>
                        <div className='text-left justify-end font-bold text-sm'>Monto</div>
                    </div>
                    {conceptos && <div className=''>
                        <ul style={{ padding: "0" }}>
                            {conceptos.map((elemento, index) => (
                                <li key={index} style={{ display: "flex", justifyContent: "space-between" }} className='text-sm'><div style={{ display: "flex", justifyContent: "space-between", gap: "10px", textAlign: "left", padding: "1px" }}><div>{elemento.clave}</div><div style={{ overflow: "hidden", wordWrap: "break-words", fontSize: "1em" }}>{elemento.descripcion.trim()}</div></div><div className='font-bold'>{numeral(elemento.importe).format('$0,0.00')}</div></li>
                            ))}
                        </ul>
                    </div>}

                </div>
            </div>
        );
    };
    const renderContent = () => {
        if (calculo) {
            if (tipo === "nomina") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Número de empleados</div>
                            <div  >{empleados}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Base del impuesto</div>
                            <div >{numeral(calculo.baseImpuesto).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div >Impuesto Causado</div>
                            <div  >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Otros incentivos</div>
                            <div  >{numeral(calculo.otrosIncentivos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educativo</div>
                            <div  >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div >Actualización nómina</div>
                            <div  >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos nómina</div>
                            <div  >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal</div>
                            <div  >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div  >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div  >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div  >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }
            if (tipo === "autosnuevos") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Número de unidades enajenadas</div>
                            <div className=' underline' >{calculo.numeroUnidadesEnajenadas}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Importe de enajenaciones</div>
                            <div className=' underline' >{numeral(calculo.importeEnajenaciones).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto Determinado</div>
                            <div className=' underline' >{numeral(calculo.calculos.impuestoDeterminado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >ISAN Actualización</div>
                            <div className=' underline' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >ISAN Recargos</div>
                            <div className=' underline' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal</div>
                            <div className=' underline' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' underline' >{numeral(calculo.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div className=' underline' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div className=' underline' >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }

            if (tipo === "hospedaje") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Base del impuesto</div>
                            <div className='justify-end text-end underline' >{numeral(calculo.baseImpuesto).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto causado</div>
                            <div className=' underline' >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educación</div>
                            <div className=' underline' >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización hospedaje</div>
                            <div className=' underline' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos hospedaje</div>
                            <div className=' underline' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal</div>
                            <div className=' underline' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' underline' >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div className=' underline' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div className=' underline' >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }

            if (tipo === "bebidas") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Base del impuesto</div>
                            <div className='justify-end text-end underline' >{numeral(calculo.baseImpuesto).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto causado</div>
                            <div className=' underline' >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div className=' underline' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' underline' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Subtotal </div>
                            <div className=' underline' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' underline' >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' underline' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div className=' underline' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div className=' underline' >{numeral(calculo.calculos.montoAnteriorPagado).format('$0,0.00')}</div>
                        </div >
                        
                    </div>
                )
            }
            if (tipo === "residuos") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Toneladas de Residuos </div>
                            <div >{numeral(calculo.toneladasResiduos).format('0,0.000000')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto Determinado</div>
                            <div className=' ' >{numeral(calculo.calculos.impuestoDeterminado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educación</div>
                            <div className=' ' >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div className=' ' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' ' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Subtotal </div>
                            <div className=' ' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' ' >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' ' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div className=' ' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div className=' ' >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        
                    </div>
                )
            }
            if (tipo === "vehicular") {
                return (
                    <div className='seccionVehicular'>
                        {Object.entries(data).map(([clave, valor]) => (
                            <>
                                <Accordion className='mb-3'>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography style={{ fontWeight: "bold" }}>{`Año ${clave} - Importe ${clave} : ${numeral(sumarPropiedadArray(valor, 'importe')).format('$0,0.00')}`}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {ListaConceptos(valor)}
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ))}


                        {/*  <Row>
                            <Col className='justify-start text-start' md='8'>Tarifa Residuos</Col>
                            <Col className='justify-end text-end ' md='4'>{numeral(calculo.calculos.tarifaResiduos).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='justify-start text-start' md='8'>Impuesto Determinado</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.impuestoDeterminado).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='justify-start text-start' md='8'>Fomento educación</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='justify-start text-start' md='8'>Actualización</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='text-start' md='8'>Recargos</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.recargos).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='text-start' md='8'>SubTotal</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='text-start' md='8'>Compensación</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.compensacion).format('$0,0.00')}</Col>
                        </Row>
                        <Row>
                            <Col className='text-start' md='8'>Saldo a favor</Col>
                            <Col className='justify-end text-end flex items-center ' md='4'>{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</Col>
                        </Row> */}
                        {/*                         <Row>
                            <Col className='text-start' md='8'>Tarifa Residuos</Col>
                            <Col className='justify-end text-end flex items-center underline' md='4'>{numeral(calculo.calculos.tarifaResiduos).format('$0,0.00')}</Col>
                        </Row> */}
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }
            if (tipo === "gases") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className=' underline'>Base gravable:</div>
                        </div>
                        <ul>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>Bióxido de carbono por tonelada </li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo?.calculos?.valoresGases["G-BC"] : 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>Metano por tonelada  </li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-MET"] : 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>Óxido de nitroso  </li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-ONI"] : 0).format('$0,0.00')}</div>
                        </div>
                            {annio >= 2024 && (
                                <>
                            <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div ><li>Carbono negro</li></div>
                            <div  >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-CN"]:0).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className=' underline'>Hidrofluorocarbonos:</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CHF3 </li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC1"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CHF2CF3</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC2"] : 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CH2FCF3</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC3"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CH3CHF2</li></div>
                            <div >{ numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC4"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CF3CHFCF3</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC5"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CF3CH2CF3</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC6"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CF3CHFCHFCF2C_F3</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-HFC7"]:0).format('$0,0.00')}</div>
                        </div>
                       
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className=' underline'>Perfluorocarbonos:</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>CF4 </li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-PFC1"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>C2F6</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-PFC2"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>C4F10</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-PFC3"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>C6F14</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-PFC4"]:0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div ><li>SF6</li></div>
                            <div >{numeral(calculo?.calculos?.valoresGases !== null ? calculo.calculos.valoresGases["G-PFC5"]:0).format('$0,0.00')}</div>
                        </div>
                                </>
                            )}
                        </ul>
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div >Impuesto causado</div>
                            <div  >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educativo</div>
                            <div  >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div >Actualización</div>
                            <div  >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' ' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 1</div>
                            <div  >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div  >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 2</div>
                            <div  >{numeral(calculo.calculos.subtotal2).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Pago provisional</div>
                            <div  >{numeral(pagosProvisionales).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 3</div>
                            <div  >{numeral(calculo.calculos.subtotal3).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' ' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div  >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto anterior pagado</div>
                            <div  >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                    </div>
                );
            }          
            if (tipo === "ISR") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Ganancia Obtenida</div>
                            <div className=' ' >{numeral(calculo.calculos.gananciaObtenida).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Ganancia Base del Impuesto</div>
                            <div className=' ' >{numeral(calculo.calculos.gananciaBaseImpuesto).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Excedente Impuesto Cargo</div>
                            <div className=' ' >{numeral(calculo.calculos.excedenteImpuestoCargo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto a Cargo</div>
                            <div className=' ' >{numeral(calculo.calculos.impuestoACargo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Total ISR Enajenación bienes Inmuebles ART. 126 LISR</div>
                            <div className=' ' >{numeral(calculo.calculos.totalISREnajenacionBienesInmueblesART126LISR).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >ISR 5% Enajenación Bienes Inmuebles Estado (127 LISR)</div>
                            <div className=' ' >{numeral(calculo.calculos.isr5PorcientoEnajenacionBienesInmueblesEstado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div className=' ' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' ' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Subtotal Artículo 127</div>
                            <div className=' ' >{numeral(calculo.calculos.subtotalArticulo127).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >ISR Enajenación Bienes Inmuebles Federación (126 LISR)</div>
                            <div className=' ' >{numeral(calculo.calculos.totalISREnajenacionBienesInmueblesART126LISR).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización ISR Federación</div>
                            <div className=' ' >{numeral(calculo.calculos.actualizacionFederacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos ISR Federación</div>
                            <div className=' ' >{numeral(calculo.calculos.recargosFederacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Subtotal Artículo 126 LISR</div>
                            <div className=' ' >{numeral(calculo.calculos.subtotalArticulo126).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multa por Corrección</div>
                            <div className=' ' >{numeral(calculo.calculos.multaPorCorrecion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Cantidad a Cargo</div>
                            <div className=' ' >{numeral(calculo.calculos.cantidadACargo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Pagado con Anterioridad</div>
                            <div className=' ' >{numeral(calculo.calculos.montoPagadoConAnterioridad).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a Favor</div>
                            <div className=' ' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Cantidad por Pagar</div>
                            <div className=' ' >{numeral(calculo.calculos.cantidadPorPagar).format('$0,0.00')}</div>
                        </div >


                    </div>
                )
            }
            if (tipo === "emision") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Base gravable:</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className='font-bold' >&nbsp;&nbsp;Suelo y Subsuelo </div>
                            <div >{numeral(calculo.calculos.impuestoSuelo).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className='font-bold' >&nbsp;&nbsp;Agua </div>
                            <div >{numeral(calculo.calculos.impuestoAgua).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto Determinado</div>
                            <div className=' ' >{numeral(calculo.calculos.impuestoDeterminado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educación</div>
                            <div className=' ' >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div className=' ' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' ' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 1</div>
                            <div className=' ' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' ' >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 2</div>
                            <div className=' ' >{numeral(calculo.calculos.subtotal2).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' ' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div  >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div  >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                    Conceptos
                                    {calculo.conceptos.map(cp => {
                                        return (<div>
                                            {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                        </div>)
                                    })}
                                </div>*/}
                   
                    </div>
                )
            }
            if (tipo === "materiales") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className=' underline' >Base gravable:</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Grava </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-GRA"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Arena </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-ARE"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Arcilla y Limo </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-ARL"]: 0).format('$0,0.00')}</div>
                        </div>
                        { annio < 2024 &&
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Materiales en Greña </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-MEG"]: 0).format('$0,0.00')}</div>
                        </div>
                        }
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Roca </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-ROC"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Piedras y sustrato o caapa fértil </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-PSC"]: 0).format('$0,0.00')}</div>
                        </div>
                        { annio < 2024 &&
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div >Piedras de construcción y de adorno de mármol, de granito y ónix </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-PAC"]: 0).format('$0,0.00')}</div>
                        </div>
                        }
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Cantera </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-CAN"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Travertinos </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-TRA"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Agregados pétreos </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-AGP"]: 0).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Caliza </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-CLZ"]: 0).format('$0,0.00')}</div>
                        </div>
                        { annio < 2024 && 
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Otros </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-OTR"]: 0).format('$0,0.00')}</div>
                        </div>
                        }

                        {  annio >= 2024 && 
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        
                            <div >Andesita </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-AND"]: 0).format('$0,0.00')}</div>
                        </div>}
                        {  annio >= 2024 && 
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        
                            <div >Caolín </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-CAO"]: 0).format('$0,0.00')}</div>
                        </div>}
                        { annio >= 2024 && 
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        
                            <div >Riolita </div>
                            <div className=' underline' >{numeral(calculo?.calculos?.materiales !== null ?calculo?.calculos?.materiales["M-RIO"]: 0).format('$0,0.00')}</div>
                        </div>}

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto Causado</div>
                            <div className=' underline' >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Fomento educación</div>
                            <div className=' underline' >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div className=' underline' >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div className=' underline' >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 1</div>
                            <div className=' underline' >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div className=' underline' >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 2</div>
                            <div className=' underline' >{numeral(calculo.calculos.subtotal2).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' underline' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div className=' underline' >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div className=' underline' >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }
            if (tipo === "bienes") {
                return (
                    <div className='seccion2'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Base del impuesto</div>
                            <div >{numeral(calculo.baseImpuesto).format('$0,0.00')}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Impuesto Determinado</div>
                            <div className=' ' >{numeral(calculo.calculos.impuestoCausado).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div >Fomento educativo</div>
                            <div  >{numeral(calculo.calculos.fomentoEducativo).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Actualización</div>
                            <div  >{numeral(calculo.calculos.actualizacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Recargos</div>
                            <div  >{numeral(calculo.calculos.recargos).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 1</div>
                            <div  >{numeral(calculo.calculos.subtotal1).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Compensación</div>
                            <div  >{numeral(calculo.calculos.compensacion).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 2</div>
                            <div  >{numeral(calculo.calculos.subtotal2).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Pago provisional</div>
                            <div  >{numeral(calculo.calculos.pagoProvisional).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 3</div>
                            <div  >{numeral(calculo.calculos.subtotal3).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Retenciones</div>
                            <div  >{numeral(calculo.retenciones).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >SubTotal 4</div>
                            <div  >{numeral(calculo.calculos.subtotol4).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Multas</div>
                            <div className=' ' >{numeral(calculo.calculos.multas).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Saldo a favor</div>
                            <div  >{numeral(calculo.calculos.saldoAFavor).format('$0,0.00')}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >Monto Anterior Pagado</div>
                            <div  >{numeral(montoAnt).format('$0,0.00')}</div>
                        </div >
                        {/*<div>
                                Conceptos
                                {calculo.conceptos.map(cp => {
                                    return (<div>
                                        {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                    </div>)
                                })}
                            </div>*/}
                    </div>
                )
            }
            if (tipo === "rifas") {
                // console.log("resumen card", calculo);
                const rifaConcepto = calculo.concepto.find(concepto => concepto.descripcion === "RIFAS LOTERIAS Y SORTEOS");
                const isrlConcepto = calculo.concepto.find(concepto => concepto.descripcion === "ISRL FOMENTO EDUCATIVO");
                const ajusteConcepto = calculo.concepto.find(concepto => concepto.descripcion === "AJUSTE  POR  REDONDEO (ART. 25 C.F.E.)");
            
                return (
                    <div className='seccion2'>
                        {rifaConcepto && (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>RIFAS LOTERIAS Y SORTEOS</div>
                                <div className='justify-end text-end underline'>{numeral(rifaConcepto.monto).format('$0,0.00')}</div>
                            </div>
                        )}
                        {isrlConcepto && (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>ISRL FOMENTO EDUCATIVO</div>
                                <div className='justify-end text-end underline'>{numeral(isrlConcepto.monto).format('$0,0.00')}</div>
                            </div>
                        )}
                        {ajusteConcepto && (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>AJUSTE POR REDONDEO (ART. 25 C.F.E.)</div>
                                <div className='justify-end text-end underline'>{numeral(ajusteConcepto.monto).format('$0,0.00')}</div>
                            </div>
                        )}
                    </div>
                )
            }
            if (tipo === "licenciaAuto") {
               
                return (
                    <div className='seccion2'>
                        {calculo.concepto.map((valor,index)=>(
                        <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{valor.descripcion}</div>
                            <div className='justify-end text-end underline'>{numeral(valor.monto).format('$0,0.00')}</div>
                        </div>
                        ))}
                    </div>
                )
            }
            if (tipo === "actas") {
                
                return (
                    
                    <div className='seccion2'>
                        
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >{calculo?.concepto[0]?.descripcion}</div>
                            <div className='justify-end text-end underline' >{numeral(calculo.concepto[0].monto).format('$0,0.00')}</div>
                        </div >
                        
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >{calculo.concepto[1].descripcion}</div>
                            <div className='justify-end text-end underline' >{numeral(calculo.concepto[1].monto).format('$0,0.00')}</div>
                        </div >
                        {calculo.concepto[2] && calculo.concepto[2].descripcion === "AJUSTE  POR  REDONDEO (ART. 25 C.F.E.)" && (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{calculo.concepto[2].descripcion}</div>
                            <div className='justify-end text-end underline'>{numeral(calculo.concepto[2].monto).format('$0,0.00')}</div>
                        </div>
                         )}

                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >{calculo.concepto[2].descripcion}</div>
                            <div className='justify-end text-end underline' >{numeral(calculo.concepto[2].monto).format('$0,0.00')}</div>
                        </div > */}
                        
                        
                       
                        {/* {console.log("resumen card data", validateData.concepto)}; */}
                    </div>
                )
            }
            if (tipo === "licenciaAuto") {
               
                return (
                    <div className='seccion2'>
                        {calculo.concepto.map((valor,index)=>(
                        <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{valor.descripcion}</div>
                            <div className='justify-end text-end underline'>{numeral(valor.monto).format('$0,0.00')}</div>
                        </div>
                        ))}
                    </div>
                )
            }

        }

        if (tipo === "Predial") {
            return (
                <div className='seccion2'>
                    {calculo?.concepto.map((item, index) => (

                        <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >{item.descripcion}</div>
                            <div  >{numeral(item.monto).format('$0,0.00')}</div>
                        </div >
                    ))}

                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div  >Calle:</div>
                            <div className=' '>{data.calle}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Código Postal:</div>
                            <div className=' ' md='6'>{data.codigoPostal}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div  >Colonia:</div>
                            <div className=' '>{data.colonia}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Número Exterior:</div>
                            <div className=' ' md='6'>{data.numeroExterior}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Número Interior:</div>
                            <div className=' ' md='6'>{data.numeroInterior}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Estado:</div>
                            <div className=' ' md='6'>{data.estado}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Municipio:</div>
                            <div className=' ' md='6'>{data.municipio}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Localidad:</div>
                            <div className=' ' md='6'>{data.localidad}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Lote:</div>
                            <div className=' ' md='6'>{data.lote}</div>
                        </div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div md='6'>Manzana:</div>
                            <div className=' ' md='6'>{data.manzana}</div>
                        </div > */}

                    {/*                         <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div >Tarifa Residuos</div>
                        <div className=' underline' >{numeral(calculo.calculos.tarifaResiduos).format('$0,0.00')}</div>
                    </div > */}
                    {/*<div>
                            Conceptos
                            {calculo.conceptos.map(cp => {
                                return (<div>
                                    {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
                                </div>)
                            })}
                        </div>*/}
                </div>
            )


        }
    }
    return (
        <Box className='max-w-[420px]' style={{ padding: "1rem", borderRadius: "10px", boxShadow: "4px 4px 6px rgba(0,0,0,0.5)" }}>
            <Row className=' max-w-[520px] rounded-t-md'>
                <div className="seccion1" >
                    <span className='underline'>Resumen del importe a pagar</span>
                    {/* {process.env.REACT_APP_NODE_ENV !== 'production' ?

                        ligaPagoMit &&
                            
                            <ButtonPago idForm={idForm} calculo={calculo}/>
                        
                        : null
                    } */}
                    {tipo === "vehicular" || tipo==="Predial" || tipo==="licenciaAuto" || tipo=== "actas"?

                        <label>Verifica que los datos sean correctos</label>
                        :

                        <label>Verifica que la declaración sea correcta</label>
                    }
                </div>
                { tipo !== "vehicular" &&
                    <div className="seccion3">
                        <Col className='font-bold text-start underline'>Conceptos</Col>
                        <Col className='font-bold text-end underline'>Cantidades</Col>
                    </div>
                }
            </Row>
            <Row className='my-10 max-w-[420px]'>
                {renderContent()}
            </Row>
            <Row className=' max-w-[420px] rounded-b-md h-40'>
                {
                    calculo && (
                        <div className='seccion3'>
                            <Col className='text-lg text-start underline font-bold'>Total a pagar</Col>
                            {tipo === "Predial" ?

                                <Col className='text-lg text-end  font-bold'>{numeral(calculo.importe).format('$0,0.00')}</Col>
                                :
                                tipo === "vehicular" ?
                                    <Col className='text-lg text-end  font-bold'>{numeral(calculo?.estadoDeCuenta[0]?.importe).format('$0,0.00')}</Col>
                                    :
                                    tipo === "rifas" ?
                                        <Col className='text-lg text-end  font-bold'>{numeral(calculo.importe).format('$0,0.00')}</Col>
    
                                    :
                                    tipo === "licenciaAuto" ?
                                        <Col className='text-lg text-end  font-bold'>{numeral(calculo.importe).format('$0,0.00')}</Col>
    
                                    :
                                    tipo === "actas" ?
                                        <Col className='text-lg text-end  font-bold'>{numeral(calculo.importe).format('$0,0.00')}</Col>
    
                                    :
                                    
                                    tipo === "ISR" ?

                                        <Col className='text-lg text-end  font-bold'>{numeral(calculo.calculos.total).format('$0,0.00')}</Col>
                                        :
                                        <Col className='text-lg text-end  font-bold'>{numeral(calculo.calculos.total).format('$0,0.00')}</Col>
                            }
                        </div>
                    )
                }
                {tipo === "emision" && activeStep !== 1 &&
                    <Row className='flex justify-center self-center items-center w-full' >
                        <ButtonB className='w-[30%] ml-6 mt-4' variant='success' 
                        onClick={() => {
                        
                        calculoImp(data)}}>Calcular Impuesto
                        </ButtonB>
                        
                    </Row>}
                {/*<Row className='flex justify-center self-center items-center w-full' >
                    <ButtonB disabled={desableCalc} className='w-[30%] ml-6 mt-4' variant='success' onClick={() => calculoImp()}>Calcular Impuesto</ButtonB>
                </Row>*/}
            </Row>
        </Box>
    );
};

export default ResumenCard;