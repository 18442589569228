import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from 'date-fns'; // Para formatear el tiempo restante

const TemporizadorApp = ({ targetTimestamp }) => {
    const [timeRemaining, setTimeRemaining] = useState(targetTimestamp - Date.now());

    useEffect(() => {
      // Configura un intervalo para actualizar el tiempo restante
      const interval = setInterval(() => {
        const now = Date.now();
        const remainingTime = targetTimestamp - now;
        
        if (remainingTime <= 0) {
          setTimeRemaining(0); // Detiene el temporizador cuando llega a 0
          clearInterval(interval); // Limpia el intervalo
        } else {
          setTimeRemaining(remainingTime);
        }
      }, 1000); // Actualiza cada segundo
  
      // Limpia el intervalo cuando el componente se desmonta
      return () => clearInterval(interval);
    }, [targetTimestamp]);
  
    // Función para convertir el tiempo restante en minutos y segundos
    const formatTimeRemaining = (milliseconds) => {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
  
      return `${minutes} minutos y ${seconds} segundos`;
    };
  
    return (
      <div>
        {timeRemaining > 0 ? (
          <label>Tiempo restante: {formatTimeRemaining(timeRemaining)}</label>
        ) : (
          <label>¡El tiempo ha expirado!</label>
        )}
      </div>
    );
};

// Uso del componente con el valor timestamp 1727988034
const Temporizador = (props) => {
    const {tiempo} = props;
    console.log(tiempo)
  const targetTimestamp = tiempo * 1000; // Multiplicado por 1000 para convertirlo a milisegundos

  return <TemporizadorApp targetTimestamp={targetTimestamp} />;
};

export default Temporizador;
