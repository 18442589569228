import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { publishMessage } from "../../graphql/mutations";
import * as yup from "yup";
import { isMobile } from 'react-device-detect';
import { Button as ButtonB } from 'react-bootstrap';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Loader } from "@aws-amplify/ui-react";
import ResumenCard from "./ResumenCard";
import PantallaExitoImpuestos from "../PantallaExitoImpuestos";
import { View } from "@aws-amplify/ui-react";
import { toast, ToastContainer } from 'react-toastify';
import ModalActions from './ModalActions';
import { Backdrop, CircularProgress } from '@mui/material';
import imagenTarjeta from "../../assets/mensaje_tarjeta.png";
import { useNavigate } from "react-router-dom";
import { onMessageReceived } from "../../graphql/subscriptions";
import Box from "@mui/material/Box";
import { useTramiteDataGeneral } from "../../TramiteContext";
import Iframe from "react-iframe";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import FormPagos from "../FormPagos";
const steps = ["Información", "Formas de pago"];
const ENDPOINT_SEI =
  process.env.REACT_APP_SEI_API_ENDPOINT ||
  "https://bw5ulxlmwh.execute-api.us-east-1.amazonaws.com/master/servicios";
// const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT || 'https://19m6l8mzik.execute-api.us-east-1.amazonaws.com/dev/servicios';

const stripePromise = loadStripe("pk_test_51Nai2pLSERnegRqySICmK7lDr7jEw9k1joNLw4SlgTQETjwQmWQyRkAAkJeygs1AVc3wstfBqwo2feNvyCSXZXnt00abyHpkRR");
const client = generateClient();
export const FormLicenciaStripe = () => {
  const navigate = useNavigate();
  const [idForm, setIdForm] = useState("Not Assign");
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [metodoPago, setMetodoPago] = useState("pago1");
  const { data, setCalculo, setData, calculo } = useTramiteDataGeneral();
  const [calculoData, setCalculoData] = useState({});
  const [respuestaPago, setRespuestaPago] = useState({});
  const [respuestaRecibo, setRespuestaRecibo] = useState({})

  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [pantallaExito, setPantallaExito] = useState(false);
  const [pagoExitoModal, setPagoExitoModal] = useState(false);
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false);
  const [loadingPago, setLoadingPago] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [ligaPagoMit, setLigaPagoMit] = useState(null)
  const [continuarPago, setContinuarPago] = useState(false)

  useEffect(() => {
    generateID();
  }, []);
  const generateID = async () => {
    const user = await getCurrentUser();
    const id = `LicenciaAutomovilista_${user.username}_${Date.now()}`;
    setIdForm(id);
    return id;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const formatearFecha = (fecha) => {
    if (fecha) {
      const date = new Date(fecha);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return null;
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

    return `${day}/${month}/${year}`;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const validationSchema = yup.object().shape({
    nombreContribuyente: yup.string()
      .matches(/^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, 'El nombre no puede contener números ni caracteres especiales')
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
    initialValues: {
      nombreContribuyente: "",
    },
  });

  const calcularServicioSei = async (generarEstado = false) => {
    const data = formik.values;
    if (data.nombreContribuyente === "") {
      console.log("falta un elemento");
    } else {
      setLoading(true);
      const endpoint = `${ENDPOINT_SEI}/sei/servicios/calculate`;
      const authEndpoint = `${ENDPOINT_SEI}/sei/auth`;

      const requestData = {
        claveServicio: "SERV691",
        nombreContribuyente: data.nombreContribuyente, //Nombre del tramitante (Interno)
        listaParametrosServicios: null,
      };
      try {
        // Primera consulta para obtener la autenticación
        const authResponse = await fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });
        if (!authResponse.ok) {
          throw new Error(
            `Error en la primera consulta: ${authResponse.status}`
          );
        }
        const authData = await authResponse.json();
        setAuthToken(authData.token);
        // Segunda consulta utilizando el token de autenticación obtenido
        const validateResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
          body: JSON.stringify(requestData),
        });
        if (!validateResponse.ok) {
          const errorData = await validateResponse.json();
          setErrorMessage(errorData.error);
          setPagoFallidoModal(true);
          setLoading(false);
          throw new Error(
            `Error en la segunda consulta: ${validateResponse.status}`
          );
        }
        const validateData = await validateResponse.json();

        // Aquí puedes manejar la respuesta de la segunda consulta (validateData) 
        setCalculo(validateData);
        setCalculoData(validateData);
        setLoading(false);
      } catch (error) {
        console.error("Error en la consulta:", error.message);
      }
    }
  };


  const generarTransaccion = async () => {
    // console.log(idForm);
    const user = await fetchUserAttributes();
    try {
      const transaccionEndpoint = `${ENDPOINT_SEI}/sei/putEvent`;
      const conceptos = calculo.concepto?.map((cp) => {
        return {
          clave: cp.concepto,
          concepto: cp.descripcion,
          importe: cp.monto,
        };
      });
      const data = formik.values;
      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            validationRequested: true,
            data: {
              razonSocial: `${formik.values.nombreContribuyente}`,
              user:
                user.name !== undefined
                  ? user.name
                  : "Contribuyente",
              email: user.email,
              padron: {
                nombrePadron: "LICENCIA AUTOMOVILISTA",
              },
              estadoCuenta: {
                serie: calculo?.serie,
                identificador: calculo?.identificador,
                tramiteId: calculo?.tramiteId,
                loteId: calculo?.loteId,
                fechaVencimiento: calculo?.fechaVencimiento,
                total: calculo?.importe, // -- cambio
              },
              conceptos: conceptos,
            },
            authToken: authToken,
            userId: user.sub,
            type: "servicios",
          },
        }),
      });


      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        return;
      }

      const responseData = await response.json();
      // Manejar la respuesta según tus necesidades
      console.log("Respuesta de la transacción:", responseData);

      return responseData;
    } catch (error) {
      console.error("Error al realizar la transacción:", error.message);
      return error;
    }
  };
  const GenerarLigaStripe = () => {
    generarTransaccion();
    const url = `https://n5lh385qa9.execute-api.us-east-1.amazonaws.com/test/servicios/stripe/create-checkout-session`
    const params = {
      method: "POST",
      body: JSON.stringify({
        "tramite": "LICENCIA AUTOMOVILISTA",
        "importe": `${calculo?.importe}`,
        "referencia": `${calculo?.referencias[0].referencia}`
      }),
      headers: {
        "Content-Type": "application/json",
      }
    };
    return fetch(url, params).then(response => {
      return response.json();
    }).then(result => {
      console.log(result)
      if (result.url !== undefined) {
        window.location.href = `${result.url}`
      }
    }).catch(err => {
      console.log("error en el tramite", err)
    })
  }
  const GenerarLigaPago = async (tipoPago = null) => {
    const user = await fetchUserAttributes();
    const opcionPago = tipoPago ? tipoPago : metodoPago;
    const conceptos = calculo.concepto?.map((cp) => {
      return {
        clave: cp.concepto,
        concepto: cp.descripcion,
        importe: cp.monto,
      };
    });
    const otrasRef = calculo?.referencias?.map((referencia) => {
      const partes = referencia.recaudacion.split("-");
      const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
      const convenio = parteDespuesDelGuion;
      return {
        banco: referencia.recaudacion,
        convenio: convenio ? convenio : "",
        referencia: referencia.referencia,
      };
    });
    if (
      opcionPago === "pago1" ||
      opcionPago === "pago2" ||
      opcionPago === "pago3" ||
      opcionPago === "pago4"
    ) {
      generarTransaccion();
    }
    const variables =
      opcionPago === "pago1"
        ? {
          id: idForm,
          payload: {
            paymentMethod: "stripe_card",
            email: user.email,
            user:
              user.name !== undefined
                ? user.name
                : "Contribuyente",
            tramite:
              "LICENCIA AUTOMOVILISTA",
            reference: `${calculo?.referencias[0].referencia}`, //"202300011471739766260",
            monto: `${calculo?.importe}`, //"0.01",
            type: "servicios",
          },

        }
        : opcionPago === "pago2" ||
          opcionPago === "pago3" ||
          opcionPago === "pago4"
          ? {
            "id": idForm,
            "payload": {
              "paymentMethod": "spei",
              "data": {
                "user":
                  user.name !== undefined
                    ? user.name
                    : "Contribuyente",
                "loteId": `${calculo?.loteId}`,
                "total_pagar": calculo?.importe,
                "tramite": "LICENCIA AUTOMOVILISTA",
                "movimiento": "LICENCIA AUTOMOVILISTA",
                "fecha_generacion": getCurrentDate(),
                "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
                "contribuyente": `${formik.values.nombreContribuyente}`,
                "referencia": `${calculo?.referencias[0].referencia}`,
                "otras_referencias": otrasRef,
              },
              "email": user.email,
              "procedure": "referencia_pago_servicios",
            },
          }
          : opcionPago === "Recibo"
            ? {
              id: idForm,
              payload: {
                data: {
                  // "user": user.name !== undefined ? user.name : "Contribuyente",
                  num_transaccion: calculoData.loteId,
                  tramite:
                    "LICENCIA AUTOMOVILISTA",
                  movimiento: "Declaracion complementaria",
                  // "contribuyente": user.name !== undefined ? user.name : "Contribuyente",
                  fecha_pago: getCurrentDate(),
                  base_calculo: `${formik.values.valor}`,

                  total: `${calculoData.importe}`,
                  serie: `${calculoData.serie}`,
                  identificador: `${calculoData.identificador}`,
                  referencia: `${calculoData.referencias[0].referencia}`,
                  loteId: `${calculoData.loteId}`,
                  estatus: "PAGADO",
                },
                email: user.email,
                procedure: "recibo_pago_servicios",
                validationRequested: true,
                userId: user.sub,
                authToken,
              },
            }
            : {
              id: idForm,
              payload: {
                "paymentMethod": "spei",
                "data": {
                  "user": user.name !== undefined ? user.name : "Contribuyente",
                  "contribuyente": `${formik.values.nombreContribuyente}`,
                  "tramite": "LICENCIA AUTOMOVILISTA",
                  "movimiento": "LICENCIA AUTOMOVILISTA",
                  "fecha_generacion": getCurrentDate(),
                  "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
                  "otros_conceptos": conceptos,
                  "total_pagar": `${calculo?.importe}`,
                },
                "email": user.email,
                "procedure": "presupuesto_servicios",
              },
            };
    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    });
  };

  useEffect(() => {
    const subscription = client.graphql({
      query: onMessageReceived,
      variables: { id: idForm },
    }).subscribe({
      next: (event) => {
        const receivedMessage = event.data.onMessageReceived;
        const dataRec = JSON.parse(receivedMessage.payload);
        if (dataRec?.error) {
          setErrorMessage(dataRec?.error);
          setPagoFallidoModal(true);

          //alert("Hubo un error intentalo de nuevo")
          return;
        }
        console.log(dataRec)
        if (dataRec.status === "PAGO VALIDADO") {
          //activar loader
          console.log("Validar pago");
          setRespuestaPago(dataRec);
          setLoadingPago(true);
        }
        if (dataRec?.procedure === "paymentUrl") {
          if (dataRec.response.cd_response === "error") {
            //AlertsAction("error")
            setPagoExitoModal(false);
            setPagoFallidoModal(true);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.response.cd_response === "success") {
            //AlertsAction("success")
            // setPagoExitoModal(true);
            setPagoFallidoModal(false);
            setLigaPagoMit(dataRec.response);
            setLoadingPago(false);
            setLoading(false);
          }
        }
        if (
          dataRec.Procedure === "referencia_pago_servicios" ||
          dataRec.Procedure === "presupuesto_servicios" ||
          dataRec.Procedure === "recibo_pago_servicios"
        ) {
          if (
            dataRec.Procedure === "referencia_pago_servicios" ||
            dataRec.Procedure === "presupuesto_servicios"
          ) {
            window.open(dataRec.TXTBody);
            setLoadingPago(false);
          }
          if (dataRec.Procedure === "recibo_pago_servicios") {
            //activar vista de exito
            setPantallaExito(true);
            setRespuestaRecibo(dataRec);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.Procedure === "referencia_pago_servicios") {
            setLoadingPago(false);
            setLoading(false);
            setPagoExitoModal(true);
            setPagoFallidoModal(false);
            return;
          } /* else {
                          setCallFinal(true)
                        }*/
          return;
        }
      },
      error: (error) => {
        console.error("Subscription error", error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let values = value.toUpperCase(); // Convertir a mayúsculas
    values = values.replace(/[^A-Za-z\s]/g, '');

    formik.setFieldValue(name, values);
    formik.setFieldTouched(name, true, false);
  };
  
  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Licencia de Automovilista</View>
        {/* <View className="FormSubTitle">--Renovación Licencia--</View> */}
        {/* <View className="FormDescripcion">
        Consiste en renovar la licencia de conducir para vehículos automotores en servicio particular.
        </View> */}
      </div>
      <View className="ViewPrincipal">
        {pantallaExito ? (
          <PantallaExitoImpuestos
            pago={respuestaPago}
            recibo={respuestaRecibo}
            titulo={"Licencia de Automovilista"}
          />
        ) : ligaPagoMit ? (
          <View>
            <div className="w-full flex justify-end">
              <img
                width={"300px"}
                height={"150px"}
                src={imagenTarjeta}
                alt="Mensaje tarjeta"
              />
            </div>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{clientSecret :ligaPagoMit.clientSecret}}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
            {/*  <Iframe
              src={ligaPagoMit}
              height="850px"
              width={isMobile ? "99%" : "550px"}
              display="initial"
              styles={{ overflow: "hidden" }}
              position="relative"
            /> */}
          </View>
        ) : (
          <View className="FormContent">
            <Form onSubmit={formik.handleSubmit} noValidate className="ViewActa">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} className="mb-3">
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <></>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Box>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Nombre Completo*</Form.Label>
                            <div style={{ position: 'relative' }}>
                              <Form.Control
                                type="text"
                                name="nombreContribuyente"
                                onChange={handleInputChange}
                                value={formik.values.nombreContribuyente}
                                isValid={
                                  formik.touched.nombreContribuyente &&
                                  !formik.errors.nombreContribuyente
                                }
                                isInvalid={
                                  formik.touched.nombreContribuyente &&
                                  !!formik.errors.nombreContribuyente
                                }
                                disabled={loading}
                                onBlur={() => {
                                  calcularServicioSei(formik.values);
                                }}
                              />
                              {loading ? (

                                <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                  <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                </div>

                              ) : null}
                            </div>
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.nombreContribuyente}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                      </Box>
                    ) : (
                      activeStep === 1 && (
                        <FormPagos
                          metodoPago={metodoPago}
                          setMetodoPago={setMetodoPago}
                          generarPresupuesto={() => {
                            GenerarLigaPago("presupuesto");
                            /*   setTipoAccion(tipo.PRESUPUESTO) */
                          }}
                          continuarPago={setContinuarPago}
                        />
                      )
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    variant="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Anterior
                  </ButtonB>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    onClick={() => {
                      /*  setTipoAccion(tipo.PRESUPUESTO) */
                      setLoadingPago(true);
                      GenerarLigaPago("presupuesto");
                    }}
                    variant="secondary"
                    className="mr-3"
                  >
                    Descargar Presupuesto
                  </ButtonB>
                )}
                <ButtonB
                  onClick={() => {
                    if (activeStep === steps.length - 1) {
                      if (metodoPago === "pago1") {

                        GenerarLigaPago("pago1");
                        setLoadingPago(true);
                      } else {
                        GenerarLigaPago("pago2");
                        setLoadingPago(true);
                      }
                      return;
                    }
                    if (formik.values.nombreContribuyente === "") {
                      // Si los campos están vacíos, no continuar
                      return;
                    }
                    handleNext();
                  }}
                  variant="secondary"
                  disabled={
                    loading ||
                    !formik.isValid ||
                    formik.values.nombreContribuyente === "" ||
                    (activeStep === steps.length - 1 && continuarPago === false)
                  }
                >
                  {activeStep === steps.length - 1
                    ? metodoPago === "pago1"
                      ? "Continuar al pago"
                      : "Descargar referencia de pago"
                    : calculoData?.importe !== 0
                      ? "Siguiente"
                      : "Realizar pago"}
                </ButtonB>
              </Box>
            </Form>
          </View>
        )}
      </View>
      {!pantallaExito &&

        <ResumenCard tipo="licenciaAuto" ligaPagoMit={ligaPagoMit} idForm={idForm}/>
      }
      <ModalActions
        title={"Se ha generado la referencia de pago con éxito"}
        content={""}
        icon={"check"}
        show={pagoExitoModal}
        onHide={() => {
          setPagoExitoModal(false);
        }}
        buttonAction={() => {
          setPagoExitoModal(false);
        }}
      />
      <ModalActions
        title={"Hubo un problema"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        desc={errorMessage}
        show={pagoFallidoModal}
        onHide={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
        buttonAction={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
      />
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
