import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { View } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import Timeline from "./Timeline";
import Revisado from "../../assets/Revision.png";
import Bloqueado from "../../assets/Bloqueado.png";
import Inactivo from "../../assets/Inactivo.png";
import Rechazado from "../../assets/Rechazado.png";
import Aprobado from "../../assets/Aprobado.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-bootstrap/Modal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect } from "react";
import MaskedInput from 'react-text-mask';
import Swal from "sweetalert2";
import Iframe from "react-iframe";
import { ToastContainer, toast } from 'react-toastify';
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS;

export const FormEditPersonalidaFisica = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const [direcciones, setDirecciones] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editar, setEditar] = useState(true);
    const [helpView, setHelpView] = useState(false);
    const [viewDocuent, setViewDocument] = useState(false);
    const [infoDocuent, setInfoDocument] = useState("");
    const [show, setShow] = useState(false);
    const [inputKeys, setInputKeys] = useState({
        comprobanteDomicilio: null,
        identificacion: null
    });

    const validationSchema = yup.object().shape({
        comprobanteDomicilio: yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }

            return value.type === 'application/pdf';
        })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }

                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),

        identificacion: yup.mixed()
            .test('fileType', 'Solo se permiten archivos PDF', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }

                return value.type === 'application/pdf';
            })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }

                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
        
        curp: yup.string().required("Este campo es obligatorio").matches(/^[A-Z]{4}[0-9]{6}[HM]{1}[A-Z]{5}[0-9]{2}$/, "Formato no válido"),
        nombre: yup.string().required("Este campo es obligatorio"),
        primer_apellido: yup.string().required("Este campo es obligatorio"),
        segundo_apellido: yup.string().required("Este campo es obligatorio"),
        rfc: yup
            .string()
            .required("Este Campo es Obligatorio")
            .matches(
                /^([A-Z\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/,
                "RFC inválido"
            ),
        telefono: yup
            .string()
            .required("Este campo es obligatorio")
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Formato no valido"),
        estado: yup.string().required("Este campo es obligatorio"),
        municipio: yup.string().required("Este campo es obligatorio"),
        codigo_postal: yup.string().required("Este campo es obligatorio"),
        localidad: yup.string(),
        colonia: yup.string().required("Este campo es obligatorio"),
        calle: yup.string().required("Este campo es obligatorio"),
        numero_exterior: yup.string().required("Este campo es obligatorio"),
        numero_interior: yup.string(),
    });

    const handleClose = () => {
        navigate("/tramite/Historial-Propietarios");
        setShow(false);
    };


    const documentos = JSON.parse(data.attachments.S);
    const usuarioID = data.usuarioID.S;



    function buscarElemento(parametro) {
        for (let i = 0; i < documentos.length; i++) {
            if (documentos[i].name === parametro) {
                return documentos[i];
            }
        }
        // Si no se encuentra el elemento, puedes devolver null o cualquier otro valor que desees.
        return null;
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {


            const arraydeArchivos = [
                {
                    name: "comprobanteDomicilio",
                    file: values.comprobanteDomicilio,
                },
                {
                    name: "identificacion",
                    file: values.identificacion,
                }
            ];



            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                dynamoID: data.id.S,
                ticketType: 15,
                nombre:values.nombre,
                primer_apellido:values.primer_apellido,
                segundo_apellido:values.segundo_apellido,
                curp:values.curp,
                rfc: values.rfc,
                telefono: values.telefono.toString(),
                estado: values.estado,
                municipio: values.municipio,
                codigo_postal: values.codigo_postal.toString(),
                localidad: values.localidad,
                colonia: values.colonia,
                calle: values.calle,
                numero_exterior: values.numero_exterior.toString(),
                numero_interior: values.numero_interior === undefined ? "" : values.numero_interior.toString(),
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${ENDPOINT}/updateTicket`, requestOptions)
                .then(response => response.json())
                .then(result => {

                    subirFactura(arraydeArchivos);
                })
                .catch(error => {
                    console.log('error', error)
                    setLoading(false)
                });
        },
        initialValues: {
            curp: data.curp.S,
            nombre: data.nombre.S,
            primer_apellido: data.primer_apellido.S,
            segundo_apellido: data.segundo_apellido.S,
            rfc: data.rfc.S,
            telefono: data.telefono.S,
            estado: data.estado.S,
            municipio: data.municipio.S,
            codigo_postal: data.codigo_postal.S,
            localidad: data.localidad.S,
            colonia: data.colonia.S.toUpperCase(),
            calle: data.calle.S,
            numero_exterior: data.numero_exterior.S,
            numero_interior: data.numero_interior.S === undefined ? "" : data.numero_interior.S,
            comprobanteDomicilio: "",
            identificacion: "",
         
        },
    });
    const [touchedFields, setTouchedFields] = useState({});



    const handleBlur = (fieldName) => {
        setTouchedFields((prevTouched) => ({ ...prevTouched, [fieldName]: true }));
    };
    const subirFactura = async (values) => {

        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/pdf");
        const arrayArchivos = [];

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const file = values[key];

                if (file && file.file !== "") {
                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: file.file,
                        redirect: "follow",
                    };
                    await fetch(
                        `${ENDPOINT_DOCUMENTOS}/subir/?documentName=${file.name}&userId=${usuarioID}`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {

                            console.log(result);
                            const nuevoValor = {
                                name: file.name,
                                url: result.s3_url,
                                modified: true,
                            };
                            arrayArchivos.push(nuevoValor);
                        })
                        .catch((error) => console.log("error", error));
                } else {
                    /* Aqui adjunta los archivos ya existentes
                    for (var i = 0; i < documentos.length; i++) {
                      if (documentos[i].name === file.name) {
                        // Devolver el objeto si el valor coincide
                        arrayArchivos.push(documentos[i]);
                      }
                    }*/
                }
            }
        }
        ActualizaFacturadynamo(arrayArchivos);
    };

    const ActualizaFacturadynamo = (arrayArchivos) => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            paths: arrayArchivos,
            dynamoID: data.id.S,
            ticketType: 15,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/attachments`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setShow(true);

            })
            .catch((error) => console.log("error", error));
    };

    const handleDocumento = (url) => {
        const valor = buscarElemento(url);

        setInfoDocument(valor.url);
        setViewDocument(true);
    };

    const handleDesactivar = async (id, value) => {
        setLoading(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = JSON.stringify({
            "id": id,
            "ticketType": 15,
            "status": value
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        await fetch(`${ENDPOINT}/editTicketStatus`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.exists !== undefined && result.exists === true) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.message,
                    });
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: "success",
                        confirmButtonColor: "#607472",
                        confirmButtonText: "ok"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/tramite/Historial-Propietarios")
                        }
                    });
                }
                setLoading(false);
            })
            .catch((error) => console.log("error", error));
    };
    const [flag, setFlag] = useState(true);
    const handleObtenrDomicilio = async (data) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = {
            codigoPostal: data,
        };

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/zipcodeInfo`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setDirecciones(result);
                formik.setFieldValue("estado", result.estado[0].toUpperCase());
                formik.setFieldValue("municipio", result.municipio[0].toUpperCase());
                if (flag === false) {
                    formik.setFieldValue("calle", "");
                    formik.setFieldValue("numero_exterior", "");
                    formik.setFieldValue("numero_interior", "");
                    formik.setFieldValue("colonia", "");
                    formik.setFieldTouched("colonia", false, true);
                    formik.setFieldTouched("calle", false, true);
                    formik.setFieldTouched("numero_exterior", false, true);
                }
                if (result.localidad.length === 0) {
                    formik.setFieldValue("localidad", "SIN LOCALIDAD");
                } else {
                    formik.setFieldValue("localidad", result.localidad[0].toUpperCase());
                }
                if (result.municipio.length === 0) {
                    formik.setFieldValue("municipio", "Sin MUNICIPIO");
                } else {
                    formik.setFieldValue("municipio", result.municipio[0].toUpperCase());
                }
                if (result.localidad.length === 0 && result.municipio.length === 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "EL código postal no cuenta con municipio ni localidad, contacté a soporte",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate("/tramite/Historial-Propietarios")
                        }
                    });
                }
                setFlag(false);
            })
            .catch((error) => console.log("error", error));
    };
    const ValidarRFC = (value) => {
        const id = toast.loading("Verificando...")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ticketType": 15,
            "rfc": value,
            "usuarioID": usuarioID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${ENDPOINT}/checkRFC`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.rfcExist === true) {

                    toast.update(id, { render: result.message, type: "warning", isLoading: false, autoClose: 5000, closeOnClick: true, });

                } else {

                    toast.update(id, { render: result.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, });

                }
            })
            .catch(error => console.log('error', error));
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "telefono") {
            const numericValue = value.replace(/\D/g, "");
            if (numericValue.length <= 10 && "telefono") {
                // Actualizar el valor del campo usando el método setFieldValue de Formik
                formik.setFieldValue(name, numericValue);
            }
        } else {
            if (value.length <= 12 && name === "rfc") {
                formik.setFieldValue(name, value.toUpperCase());
                if (value.length === 12) {

                    ValidarRFC(value);
                }

            } else {
                formik.setFieldValue(name, value.toUpperCase())
            }
        }
    };
    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        formik.setFieldValue(name, numericValue);
        if (value.length === 5) {
            handleObtenrDomicilio(value);
        }
    };
    const handleInputChangeNumero = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        formik.setFieldValue(name, numericValue);

    };

    useEffect(() => {
        handleObtenrDomicilio(data.codigo_postal.S);
    }, [])
    return (
        <>
            <div className="HeaderTramite">
                <View className="FormTitle">Emplacamiento Exprés</View>
                <View className="FormSubTitle">Acreditación de Propietario</View>
                <View className="FormDescripcion">
                    En esta página podrá dar seguimiento de su expediente digital de Acreditación de Personalidad.
                </View>
            </div>
            <Row className="mb-0">
                <Form.Group as={Col} md="5" style={{ textAlign: "left" }}>
                    <button type="button" onClick={() => navigate("/tramite/Historial-Propietarios")}
                        className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                        <FaArrowLeft /> Volver al Inicio
                    </button>

                </Form.Group>
            </Row>
            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Form noValidate onSubmit={formik.handleSubmit} className="ViewActa">
                        <Row
                            className="mb-2"
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Form.Group as={Col}>
                                <Form.Label>Datos personales de Persona Física</Form.Label>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                style={{ display: "flex", justifyContent: "end" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Form.Label style={{ flex: "2" }}>
                                        {" "}
                                        <span style={{ display: "flex" }}>
                                            Estado:{" "}
                                            <img
                                                src={
                                                    data.status.S === "Revisión"
                                                        ? Revisado
                                                        : data.status.S === "Aprobado"
                                                            ? Aprobado
                                                            : data.status.S === "Rechazado"
                                                                ? Rechazado
                                                                : data.status.S === "Bloqueado"
                                                                    ? Bloqueado
                                                                    : data.status.S === "Inactivo"
                                                                        ? Inactivo
                                                                        : Revisado
                                                }
                                                alt="Status"
                                                width={24}
                                                height={24}
                                            />{" "}
                                            {data.status.S}{" "}
                                            <HelpOutlineIcon
                                                style={{
                                                    padding: "4px",
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                                className="cursor- cursor-pointer"
                                                onClick={() => setHelpView(true)}
                                            />
                                        </span>
                                    </Form.Label>
                                    {data.status.S === "Rechazado" || data.status.S === "Aprobado" || data.status.S === "Revisión" ? (
                                        <div >

                                            <Button style={{ margin: "10px" }} variant='warning' onClick={() => handleDesactivar(data.id.S, "Inactivo")}>Desactivar</Button>
                                            {data.status.S === "Rechazado" &&
                                                <Button style={{ margin: "10px" }} variant="secondary" onClick={() => setEditar(false)}>
                                                    Editar
                                                </Button>
                                            }
                                        </div>

                                    ) : data.status.S === "Inactivo" &&
                                    <Button style={{ margin: "10px" }} variant='warning' onClick={() => handleDesactivar(data.id.S, "Revisión")}>Activar</Button>


                                    }
                                </div>
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control
                          type="text"
                          name="nombre"
                          disabled={editar}
                          value={formik.values.nombre}
                          onChange={handleInputChange}
                          isValid={
                            formik.touched.nombre &&
                            !formik.errors.nombre
                          }
                          isInvalid={
                            formik.touched.nombre &&
                            !!formik.errors.nombre
                          }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.nombre}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>Primer Apellido*</Form.Label>
                        <Form.Control
                          type="text"
                          name="primer_apellido"
                          disabled={editar}
                          value={formik.values.primer_apellido}
                          onChange={handleInputChange}
                          isValid={
                            formik.touched.primer_apellido &&
                            !formik.errors.primer_apellido
                          }
                          isInvalid={
                            formik.touched.primer_apellido &&
                            !!formik.errors.primer_apellido
                          }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.primer_apellido}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>Segundo Apellido</Form.Label>
                        <Form.Control
                          type="text"
                          name="segundo_apellido"
                          disabled={editar}
                          value={formik.values.segundo_apellido}
                          onChange={handleInputChange}
                          isValid={
                            formik.touched.segundo_apellido &&
                            !formik.errors.segundo_apellido
                          }
                          isInvalid={
                            formik.touched.segundo_apellido &&
                            !!formik.errors.segundo_apellido
                          }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.segundo_apellido}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>RFC con Homoclave*</Form.Label>
                        <Form.Control
                          type="text"
                          name="rfc"
                          maxLength={13}
                          disabled={editar}
                          value={formik.values.rfc}
                          onChange={handleInputChange}
                          isValid={formik.touched.rfc && !formik.errors.rfc}
                          isInvalid={
                            formik.touched.rfc && !!formik.errors.rfc
                          }
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.rfc}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>CURP*</Form.Label>
                        <Form.Control
                          type="text"
                          name="curp"
                          disabled={editar}
                          value={formik.values.curp}
                          onChange={handleInputChange}
                          isValid={formik.touched.curp && !formik.errors.curp}
                          isInvalid={
                            formik.touched.curp && !!formik.errors.curp
                          }
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.curp}
                        </Form.Control.Feedback>
                      </Form.Group>

                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="position-relative">
                        <Form.Label>Teléfono*</Form.Label>
                        <MaskedInput
                  mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="(999) 999-9999"
                  value={formik.values.telefono}
                  onChange={(event) => formik.setFieldValue('telefono', event.target.value)}
                  disabled={editar}
                  className={`form-control ${formik.touched.telefono && formik.errors.telefono ? 'is-invalid' : formik.touched.telefono ? 'is-valid' : ''}`}
                />


                        <Form.Control.Feedback type="invalid" tooltip>
                          {formik.errors.telefono}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Comprobante de Domicilio (No mayor a 2 meses)*</Form.Label>

                                {
                                    editar === false ? (
                                        <Form.Control
                                            key={inputKeys.comprobanteDomicilio}
                                            type="file"
                                            name="comprobanteDomicilio"
                                            accept=".pdf"
                                            onInput={() => handleBlur("comprobanteDomicilio")}
                                            disabled={editar}
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    "comprobanteDomicilio",
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                            isValid={formik.touched.comprobanteDomicilio && !formik.errors.comprobanteDomicilio}
                                            isInvalid={touchedFields.comprobanteDomicilio && !!formik.errors.comprobanteDomicilio}
                                        />
                                    ) : data.attachments === undefined ||
                                        documentos[0] === undefined ? (
                                        <Form.Control
                                            readOnly
                                            plaintext
                                            defaultValue={"Sin Documento"}
                                        />
                                    ) : (
                                        <Form.Control
                                            readOnly
                                            accept=".pdf"
                                            plaintext
                                            style={{ textDecoration: "underline", color: "blue" }}
                                            onClick={() => handleDocumento("comprobanteDomicilio")}
                                            defaultValue={"Ver Documento"}
                                        />
                                    )

                                    /*    <a href={documentos[0].url} target="_blank" rel="noopener noreferrer">
                                                      Ver Documento
                                                      </a> */
                                }
                                <Form.Text muted>
                                    Archivo en formato PDF. Tamaño máximo 4 MB.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {touchedFields.comprobanteDomicilio && formik.errors.comprobanteDomicilio}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Identificación Oficial*</Form.Label>

                                {editar === false ? (
                                    <Form.Control
                                        key={inputKeys.identificacion}
                                        type="file"
                                        accept=".pdf"
                                        onInput={() => handleBlur("identificacion")}

                                        name="identificacion"
                                        onChange={(event) => {
                                            formik.setFieldValue(
                                                "identificacion",
                                                event.currentTarget.files[0]
                                            );
                                        }}
                                        disabled={editar}
                                        isValid={
                                            formik.touched.identificacion &&
                                            !formik.errors.identificacion
                                        }
                                        isInvalid={
                                            touchedFields.identificacion &&
                                            !!formik.errors.identificacion
                                        }
                                    />
                                ) : data.attachments === undefined ||
                                    documentos[1] === undefined ? (
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        defaultValue={"Sin Documento"}
                                    />
                                ) : (
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        accept=".pdf"
                                        style={{ textDecoration: "underline", color: "blue" }}
                                        onClick={() => handleDocumento("identificacion")}
                                        defaultValue={"Ver Documento"}
                                    />
                                )}
                                <Form.Text muted>
                                    Archivo en formato PDF. Tamaño máximo 4 MB.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {touchedFields.identificacion && formik.errors.identificacion}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Datos de domicilio de Persona Física</Form.Label>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Código Postal*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="codigo_postal"
                                    maxLength="5"
                                    disabled={editar}
                                    value={formik.values.codigo_postal}
                                    /*  onBlur={() =>
                                       handleObtenrDomicilio(formik.values.codigo_postal)
                                     }  */
                                    onChange={handleInputChangeCodigo}
                                    isValid={
                                        formik.touched.codigo_postal &&
                                        !formik.errors.codigo_postal
                                    }
                                    isInvalid={
                                        formik.touched.codigo_postal &&
                                        !!formik.errors.codigo_postal
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.codigo_postal}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.codigo_postal}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Estado*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="estado"
                                    readOnly
                                    disabled
                                    value={formik.values.estado}
                                    onChange={formik.handleChange}
                                    isValid={
                                        formik.touched.estado && !formik.errors.estado
                                    }
                                    isInvalid={
                                        formik.touched.estado && !!formik.errors.estado
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.estado}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Municipio*</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    name="municipio"
                                    value={formik.values.municipio}
                                    onChange={formik.handleChange}
                                    isValid={
                                        formik.touched.municipio &&
                                        !formik.errors.municipio
                                    }
                                    isInvalid={
                                        formik.touched.municipio &&
                                        !!formik.errors.municipio
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.municipio}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Localidad*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="localidad"
                                    disabled
                                    value={formik.values.localidad}
                                    onChange={formik.handleChange}
                                    isValid={
                                        formik.touched.localidad &&
                                        !formik.errors.localidad
                                    }
                                    isInvalid={
                                        formik.touched.localidad &&
                                        !!formik.errors.localidad
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.localidad}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Colonia*</Form.Label>
                                <Form.Select
                                    type="text"
                                    name="colonia"
                                    value={formik.values.colonia}
                                    onChange={formik.handleChange}
                                    disabled={editar}
                                    isValid={
                                        formik.touched.colonia && !formik.errors.colonia
                                    }
                                    isInvalid={
                                        formik.touched.colonia && !!formik.errors.colonia
                                    }
                                >
                                    <option value="">Seleccione</option>
                                    {direcciones !== null &&
                                        direcciones.colonias.map((data, index) => (
                                            <option key={index} value={data.toUpperCase()}>
                                                {data.toUpperCase()}
                                            </option>
                                        ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.colonia}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Calle*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="calle"
                                    disabled={editar}
                                    value={formik.values.calle}
                                    onChange={handleInputChange}
                                    isValid={
                                        formik.touched.calle && !formik.errors.calle
                                    }
                                    isInvalid={
                                        formik.touched.calle && !!formik.errors.calle
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.calle}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Número Exterior*</Form.Label>
                                <Form.Control
                                    disabled={editar}
                                    type="text"
                                    name="numero_exterior"
                                    value={formik.values.numero_exterior}
                                    onChange={handleInputChangeNumero}
                                    isValid={
                                        formik.touched.numero_exterior &&
                                        !formik.errors.numero_exterior
                                    }
                                    isInvalid={
                                        formik.touched.numero_exterior &&
                                        !!formik.errors.numero_exterior
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.numero_exterior}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="position-relative">
                                <Form.Label>Número Interior</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={editar}
                                    name="numero_interior"
                                    value={formik.values.numero_interior}
                                    onChange={handleInputChangeNumero}
                                    isValid={
                                        formik.touched.numero_interior &&
                                        !formik.errors.numero_interior
                                    }
                                    isInvalid={
                                        formik.touched.numero_interior &&
                                        !!formik.errors.numero_interior
                                    }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.numero_interior}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Row>
                        {editar === false &&
                            <Row className="mb-3">
                                <Form.Group as={Col} className="d-grid gap-2">

                                    <Button type="submit" variant="secondary">
                                        Actualizar Datos
                                    </Button>
                                </Form.Group>
                            </Row>
                        }
                        <Row className="mb-3">
                            <Form.Group as={Col} className="d-grid gap-2">
                                <Button
                                    onClick={() => navigate("/tramite/Historial-Propietarios")}
                                    variant="link"
                                >
                                    Regresar
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </View>
                <ToastContainer closeOnClick />
            </View>
            <View className="historial-personalidad">
                <h1>Comentarios</h1>
                <Timeline events={data?.feedback?.S} />
            </View>
            <Modal show={show} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Propietarios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    El contribuyente ha actualizado la información de su solicitud{" "}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={helpView}
                animation={false}
                onHide={() => setHelpView(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        El proceso de Acreditación de Personalidad puede transitar por los
                        siguientes estados:{" "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Captura</strong> – El expediente digital de la Acreditación
                        de la Personalidad ha sido Creado, pero aún no ha sido Asignado a un
                        Analista para su revisión <br></br>
                        <strong>Revisión</strong> - El expediente digital ha sido asignado a
                        un Supervisor y ha comenzado el proceso de Acreditación <br></br>
                        <strong>Aprobado</strong> – El expediente se considera completo y
                        correcto, por lo que dicha Personalidad puede ser utilizada para el
                        registro de Flotillas de Vehículos Foráneos <br></br>
                        <strong>Rechazado</strong> –El expediente se considera incompleto,
                        por lo que el Contribuyente debe de seguir la retroalimentación e
                        indicaciones del Analista o Supervisor <br></br>
                        <strong>Bloqueado</strong> – El analista ha llegado a la conclusión
                        de que la información sometida para el proceso de acreditación
                        cuenta con una serie de anomalías que justifican una acción de
                        Bloqueo, siendo de manera efectiva, una cancelación de dicha
                        Personalidad para el presente y futuros trámites. <br></br>
                        <strong>Inactivo</strong> – El Contribuyente ha marcado el trámite
                        de Acreditación de Personalidad como cancelado, finalizando las
                        actividades de Acreditación <br></br>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHelpView(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewDocuent}
                animation={false}
                onHide={() => setViewDocument(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Documentos </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Iframe
                        src={infoDocuent}
                        height="700px"
                        width="750px"
                        display="initial"
                        position="relative"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setViewDocument(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <h1>Esperando Respuesta</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
