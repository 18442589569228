import React from 'react';
import LogoDur from '../assets/logoicon.png'
import { View } from '@aws-amplify/ui-react';
import { Button } from 'react-bootstrap';
import numeral from 'numeral';

const PantallaExitoImpuestos = ({ pago, recibo, titulo, GenerarLigaPago }) => {
    const REACT_APP_HOME = process.env.REACT_APP_HOME;
    console.log("PANTA", pago, recibo);
    function transformarFecha(fechaString) {
        // Dividir la cadena de fecha en partes (día, mes, año)
        const partesFecha = fechaString.split('/').map(part => parseInt(part, 10));

        // Crear un objeto Date con las partes de la fecha
        const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
        console.log("FECHA", fecha);
        // Obtener el nombre del mes
        const nombreMes = obtenerNombreMes(fecha.getMonth());

        // Obtener el día del año
        const diaDelAnio = partesFecha[0];

        // Construir el resultado
        const resultado = `${diaDelAnio} de ${nombreMes} ${fecha.getFullYear()}`;

        return resultado;
    }

    function obtenerNombreMes(numeroMes) {
        const nombresMeses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        return nombresMeses[numeroMes];
    }

    return (
        <View className="FormContent shadow-lg h-full rounded-md p-4">
            <div className='h-40'>
                <div className='flex justify-between mt-3'>
                    <div className='w-full font-bold text-lg text-left justify-start'>{titulo}</div>
                    <div><img className='w-14 h-14 mr-6 mt-3' src={LogoDur} alt="logo" /></div>
                </div>
                <div className='flex flex-col justify-start'>
                    <div className='text-left'>{transformarFecha(pago?.data?.fecha_pago)}</div>
                    {pago?.data?.folio && <div className='text-left'>Folio:{pago?.data?.folio}</div>}
                    <div className='text-left'>Tu recibo de pago fue enviado al correo electrónico: {pago?.email}</div>

                </div>
            </div>
            <hr></hr>
            <div className='w-full justify-start mb-2'>
                <div className='font-bold text-lg text-left'>Detalle del trámite</div>
                <div className='w-full flex flex-col justify-start ml-2'>

                    {(titulo !== "Refrendo" || titulo !== "Replaqueo") && !recibo.ActaUrl ?
                        pago.data.tramite !== "LICENCIA AUTOMOVILISTA" &&
                        titulo === "Impuesto Predial" ? 
                        <div className="text-left">Clave Catastral: {pago?.data?.catastral}</div>
                        :
                        <div className="text-left">RFC: {pago?.data?.rfc}</div>
                         :
                        null
                    }
                    {titulo === "Impuesto Predial"? 
                    
                    <div className="text-left">Propietario: {pago?.data?.propietario}</div>
                    :

                        <div className="text-left">Nombre: {pago?.data?.contribuyente}</div>
                    }
                    <div className="text-left">Número de transacción: {pago?.data?.num_transaccion}</div>
                    {
                        <div className="text-left">Datos del pago : {pago?.data?.num_cc}  {pago?.data?.red}</div>
                    }

                </div>
            </div>
            <hr></hr>
            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
                <div>
                    <div className='text-left font-bold text-lg'>Clave</div>
                    <ul style={{ padding: "0" }}>
                        {pago?.data?.otros_conceptos.map((elemento, index) => (
                            <li key={index} style={{ display: "flex", justifyContent: "space-between" }}>{elemento.clave}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <div className='text-left font-bold text-lg'>Conceptos</div>
                        <div className='text-left justify-end font-bold text-lg'>Importe</div>
                    </div>
                    {pago?.data && <div className='text-left'>
                        <ul style={{ padding: "0" }}>
                            {pago?.data?.otros_conceptos.map((elemento, index) => (
                                <li key={index} style={{ display: "flex", justifyContent: "space-between" }}><div>{elemento.concepto}</div> <div>{numeral(elemento.importe).format('$0,0.00')}</div></li>
                            ))}
                        </ul>
                    </div>}

                </div>
            </div>
            <div className=' text-end font-bold text-lg w-100 mb-3'>Total a pagar: {numeral(pago?.data?.total_pagar).format('$0,0.00')}</div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "1rem" }}>
                <div className="d-grid gap-2" style={{ width: "100%" }}>
                    <Button onClick={() => {
                        window.open(recibo?.TXTBody)
                        
                    }}
                        style={{ borderRadius: "20px", backgroundColor: "#607471" }}
                        variant='secondary'
                    >
                        Descargar Recibo
                    </Button>
                </div>
                {titulo === 'ISR- Enajenación de bienes inmuebles' &&
                    <div className="d-grid gap-2" style={{ width: "100%" }}>
                        <Button onClick={() => {
                            GenerarLigaPago("Formato")
                        }}
                            style={{ borderRadius: "20px", backgroundColor: "#607471" }}
                            variant='secondary'
                        >
                            Formato Declaración
                        </Button>
                    </div>


                }
                {recibo.ActaUrl &&
                    <div className="d-grid gap-2" style={{ width: "100%" }}>
                        <Button onClick={() => {
                            window.open(recibo?.ActaUrl)
                        }}
                            style={{ borderRadius: "20px", backgroundColor: "#607471" }}
                            variant='secondary'
                        >
                            Copia Acta Online
                        </Button>
                    </div>

                }


                <div className="d-grid gap-2" style={{ width: "100%" }}>

                    <Button style={{ borderRadius: "20px", backgroundColor: "#607471" }} variant='secondary' onClick={() => { window.location.reload() }}>{recibo.ActaUrl || titulo === "Refrendo" || titulo === "Replaqueo" || titulo === "Licencia de Automovilista" || titulo === "Licencia de Conducir Chofer Particular"  || titulo === "Licencia de Conducir Chofer Público" || titulo === "Licencia de Motociclista" || titulo === "Impuesto Predial" || titulo === "Pago de Referencia Pregenerada" ? "Nuevo Tramite" : "Nueva Declaracion"}</Button>
                </div>
                <div className="d-grid gap-2" style={{ width: "100%" }}>

                    <Button style={{ borderRadius: "20px", backgroundColor: "#607471" }} variant='secondary' onClick={() => { window.open(REACT_APP_HOME, "_self") }}>Finalizar</Button>
                </div>

            </div>
        </View>
    );
};

export default PantallaExitoImpuestos;