import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI, ArrayOpciones } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
import Swal from "sweetalert2";
import { subYears } from "date-fns"
import { FormDatosContacto } from './FormDatosContacto';
import { Backdrop, CircularProgress } from '@mui/material';
export const FormDatosGenerales = (props) => {
    const { datos, setChangeData, changeData } = props;
    console.log(props)
    const [editar, setEditar] = useState(datos.datosGenerales !== null ? true : false);
    const [loadingSave, setLoadingSave] = useState(false);
    const schema = yup.object().shape({
        nombre: yup.string().required("Este valor es Obligatorio"),
        primerApellido: yup.string().required("Este valor es Obligatorio"),
        segundoApellido: yup.string(),
        sexo: yup.number().required("Este Campo es Obligatorio"),
        estadoCivil: yup.string().required("Este Campo es Obligatorio"),
        nacionalidad: yup.string().required("Este Campo es Obligatorio"),
        estadoNacimiento: yup.string().required("Este valor es Obligatorio"),
        curp: yup.string().required("Este Campo es Obligatorio").matches(/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}(?:[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[HM]{1}(AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "Formato no válido"),
        fechaNacimiento: yup.date().required("Este valor es Obligatorio")
            .test(
                "mayor-de-18",
                "Debes tener al menos 18 años",
                function (value) {
                    return subYears(new Date(), 18) >= new Date(value);
                }
            ),
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "curp" || name === "rfc") {
            values = values.replace(/[^A-Z0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-ZÁÉÍÓÚÑÜ\s'.,-]/g, '');

            setFieldValue(name, values);
        }
    };
    const handleDateChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let formattedValue = value;

        formattedValue = formattedValue.replace(/[^\d]/g, "");

        if (formattedValue.length > 2) {
            formattedValue = formattedValue.substring(0, 2) + '/' + formattedValue.substring(2);
        }
        if (formattedValue.length > 5) {
            formattedValue = formattedValue.substring(0, 5) + '/' + formattedValue.substring(5, 9);
        }

        setFieldValue(name, formattedValue);
    };
    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            nombre: datos.nombre,
                            primerApellido: datos.primerApellido,
                            segundoApellido: datos.segundoApellido === null ? "" : datos.segundoApellido,
                            sexo: datos.datosGenerales !== null ? datos.datosGenerales.sexo : "",
                            estadoCivil: datos.datosGenerales !== null ? datos.datosGenerales.estadoCivil : "",
                            nacionalidad: datos.datosGenerales !== null ? datos.datosGenerales.nacionalidad : "",
                            estadoNacimiento: datos.datosGenerales !== null ? datos.datosGenerales.estadoNacimiento : "",
                            curp: datos.curp,
                            fechaNacimiento: datos.datosGenerales !== null ? datos.datosGenerales.fechaNacimiento : "",
                        }}
                        onSubmit={async values => {
                            console.log(values);
                            if (parseInt(values.nacionalidad) === 426 && parseInt(values.estadoNacimiento) === 320) {
                                Swal.fire({
                                    text: "Por el momento este trámite en línea no se encuentra disponible para tu estado, por favor acude al módulo de atención más cercano. ",
                                    icon: "error",
                                    confirmButtonColor: "#607472",
                                    confirmButtonText: "Aceptar"
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                    }
                                });
                            } else {
                                setLoadingSave(true)
                                const user = await getCurrentUser();
                                const data = {
                                    "id": datos.id,
                                    "procedure": datos.procedure,
                                    "type": datos.type,
                                    "usuarioID": user.username,
                                    "curp": values.curp,
                                    "datosGenerales": {
                                        "nombre": values.nombre,
                                        "primerApellido": values.primerApellido,
                                        "segundoApellido": values.segundoApellido,
                                        "curp": values.curp,
                                        "sexo": parseInt(values.sexo),
                                        "estadoCivil": parseInt(values.estadoCivil),
                                        "nacionalidad": parseInt(values.nacionalidad),
                                        "estadoNacimiento": parseInt(values.estadoNacimiento),
                                        "fechaNacimiento": values.fechaNacimiento,
                                        "isComplete": true,
                                    },

                                }
                                CrearIteamAPI(data).then(result => {

                                    if (result.ok) {
                                        toast.success("Datos Guardados Correctamente", {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                        setChangeData(!changeData)
                                        setEditar(true);
                                    } else {
                                        toast.error(result.message, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });

                                    }
                                    setLoadingSave(false)
                                }).catch(error => {
                                    setLoadingSave(false)
                                    console.log(error)
                                })
                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue, handleBlur, resetForm }) => {

                            return (

                                <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Nombre (s)*</Form.Label>
                                            <Form.Control
                                                name='nombre'
                                                type='text'
                                                placeholder='Nombre (s)'
                                                value={values.nombre}
                                                disabled
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                isValid={touched.nombre && !errors.nombre}
                                                isInvalid={touched.nombre && !!errors.nombre}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.nombre}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative' md="6">
                                            <Form.Label>Primer Apellido*</Form.Label>
                                            <Form.Control
                                                name='primerApellido'
                                                type='text'
                                                placeholder='Primer Apellido'
                                                disabled
                                                value={values.primerApellido}
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                isValid={touched.primerApellido && !errors.primerApellido}
                                                isInvalid={touched.primerApellido && !!errors.primerApellido}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.primerApellido}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className='position-relative' md="6">
                                            <Form.Label>Segundo Apellido</Form.Label>
                                            <Form.Control
                                                name='segundoApellido'
                                                type='text'
                                                placeholder='Segundo Apellido'
                                                value={values.segundoApellido}
                                                disabled
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                isValid={touched.segundoApellido && !errors.segundoApellido}
                                                isInvalid={touched.segundoApellido && !!errors.segundoApellido}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.segundoApellido}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Sexo*</Form.Label>
                                            <OpcionesSelect
                                                id={7}
                                                setFieldValue={setFieldValue}
                                                campo={values.sexo}
                                                name={"sexo"}
                                                isDisabled={editar}
                                                value={values.sexo}
                                                onBlur={handleBlur}
                                                touched={touched.sexo}
                                                errors={errors.sexo}
                                            />
                                            {/*  <Form.Select
                                                name='sexo'
                                                type='number'
                                                value={values.sexo}
                                                disabled={editar}
                                                onChange={handleChange}
                                                isValid={touched.sexo && !errors.sexo}
                                                isInvalid={touched.sexo && !!errors.sexo}
                                            >

                                                {datos.datosGenerales === null ?

                                                    <option value="">SELECCIONA</option>
                                                    : null}
                                                    
                                                <OpcionesSelect id={7} setFieldValue={setFieldValue} campo={values.sexo} name={"sexo"}/>

                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.sexo}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Estado Civil*</Form.Label>
                                            <OpcionesSelect
                                                id={5}
                                                setFieldValue={setFieldValue}
                                                campo={values.estadoCivil}
                                                name={"estadoCivil"}
                                                isDisabled={editar}
                                                value={values.estadoCivil}
                                                onBlur={handleBlur}
                                                touched={touched.estadoCivil}
                                                errors={errors.estadoCivil}
                                            />
                                            {/*  <Form.Select
                                                name='estadoCivil'
                                                type='text'
                                                value={values.estadoCivil}
                                                onChange={handleChange}
                                                disabled={editar}
                                                isValid={touched.estadoCivil && !errors.estadoCivil}
                                                isInvalid={touched.estadoCivil && !!errors.estadoCivil}
                                            >
                                                {datos.datosGenerales === null ?

                                                    <option value="">SELECCIONA</option>
                                                    : null}
                                                <OpcionesSelect id={5} setFieldValue={setFieldValue} campo={values.estadoCivil} name={"estadoCivil"}/>


                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.estadoCivil}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Nacionalidad*</Form.Label>

                                            <OpcionesSelect
                                                id={20}
                                                setFieldValue={setFieldValue}
                                                campo={values.nacionalidad}
                                                name={"nacionalidad"}
                                                isDisabled={editar}
                                                value={values.nacionalidad}
                                                onBlur={handleBlur}
                                                touched={touched.nacionalidad}
                                                errors={errors.nacionalidad}
                                            />
                                            {/*  <Form.Select
                                                name='nacionalidad'
                                                type='text'
                                                disabled={editar}
                                                value={values.nacionalidad}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    const { name, value } = e.target;
                                                    if (value === "426") {
                                                        setFieldValue("estadoNacimiento", "")
                                                    } else {
                                                        setFieldValue("estadoNacimiento", 344)

                                                    }
                                                }}
                                                isValid={touched.nacionalidad && !errors.nacionalidad}
                                                isInvalid={touched.nacionalidad && !!errors.nacionalidad}
                                            >
                                                {datos.datosGenerales === null ?

                                                    <option value="">SELECCIONA</option>
                                                    : null}
                                                <OpcionesSelect id={20} setFieldValue={setFieldValue} campo={values.nacionalidad} name={"nacionalidad"}/>


                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.nacionalidad}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Estado de Nacimiento*</Form.Label>

                                            <OpcionesSelect
                                                id={19}
                                                setFieldValue={setFieldValue}
                                                campo={values.estadoNacimiento}
                                                name={"estadoNacimiento"}
                                                isDisabled={values.nacionalidad !== 426 ? true : editar}
                                                value={values.estadoNacimiento}
                                                onBlur={handleBlur}
                                                touched={touched.estadoNacimiento}
                                                errors={errors.estadoNacimiento}
                                            />
                                            {/*   <Form.Select
                                                name='estadoNacimiento'
                                                type='text'
                                                value={values.estadoNacimiento}
                                                onChange={handleChange}
                                                disabled={values.nacionalidad !== "426" ? true : false}
                                                isValid={touched.estadoNacimiento && !errors.estadoNacimiento}
                                                isInvalid={touched.estadoNacimiento && !!errors.estadoNacimiento}
                                            >

                                                {datos.datosGenerales === null ?

                                                    <option value="">SELECCIONA</option>
                                                    : null}

                                                <OpcionesSelect id={19} setFieldValue={setFieldValue} campo={values.estadoNacimiento} name={"estadoNacimiento"} nueva={datos.type}/>


                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.estadoNacimiento}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Fecha de Nacimiento*</Form.Label>

                                            <Form.Control
                                                name='fechaNacimiento'
                                                type='date'
                                                placeholder='DD/MM/AAAA'
                                                value={values.fechaNacimiento}
                                                maxLength={8}
                                                disabled={editar}
                                                onChange={handleChange}
                                                isValid={touched.fechaNacimiento && !errors.fechaNacimiento}
                                                isInvalid={touched.fechaNacimiento && !!errors.fechaNacimiento}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.fechaNacimiento}</Form.Control.Feedback>


                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>CURP</Form.Label>
                                            <Form.Control
                                                name='curp'
                                                type='text'
                                                placeholder='CURP'
                                                disabled
                                                value={values.curp}
                                                maxLength={18}
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                isValid={touched.curp && !errors.curp}
                                                isInvalid={touched.curp && !!errors.curp}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.curp}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                        <>
                                            {datos.type === 233 || datos.type === 234 ? null :

                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='d-grid'>
                                                        {editar === false && datos.datosGenerales !== null ?

                                                            <Button variant='secondary' onClick={() => {
                                                                setEditar(true)
                                                                resetForm()
                                                            }} >CANCELAR</Button>
                                                            :
                                                            <Button variant='secondary' disabled={datos.datosGenerales !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group as={Col} className='d-grid'>

                                                        <Button variant='secondary' disabled={editar} type='submit'>GUARDAR</Button>
                                                    </Form.Group>
                                                </Row>
                                            }
                                        </>
                                        : null}

                                </Form>
                            )
                        }}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
