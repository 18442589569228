import React, { useState, useEffect } from "react";
import { View } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FormPagos } from "./FormPagos";
import { ToastContainer, toast } from 'react-toastify';
import { ResumenFlotillas } from "./ResumenFlotillas";
import MaskedInput from 'react-text-mask';

const steps = ["Datos de Entrega", "Método de Pago"];
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;

export const FormDireccion = () => {
    const location = useLocation();
    const datos = location.state;
    const [activeStep, setActiveStep] = useState(0);
    const [direcciones, setDirecciones] = useState(null);


    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState("");

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    /* 
        const [selectedOption, setSelectedOption] = useState(null);
    
        const handleRadioChange = (event) => {
            setSelectedOption(event.target.value);
        }; */

    /*   const isButtonDisabled = !selectedOption; */








    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { user } = useAuthenticator((context) => [context.user]);
    const validationSchema = yup.object().shape({
        apellidos: yup.string().required("Este campo es obligatorio"),
        nombre: yup
            .string()
            .required("Este Campo es Obligatorio"),
        telefono: yup
            .string()
            .required("Este campo es obligatorio")
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Formato no valido"),
        estado: yup.string().required("Este campo es obligatorio"),
        municipio: yup.string().required("Este campo es obligatorio"),
        codigo_postal: yup.string().required("Este campo es obligatorio"),
        localidad: yup.string(),
        colonia: yup.string().required("Este campo es obligatorio"),
        calle: yup.string().required("Este campo es obligatorio"),
        numero_exterior: yup.string().required("Este campo es obligatorio"),
        numero_interior: yup.string(),
        entreCalle1: yup.string().required("Este campo es obligatorio"),
        entreCalle2: yup.string().required("Este campo es obligatorio"),
        datosReferencia: yup.string().required("Este campo es obligatorio"),
        indicaciones: yup.string().required("Este campo es obligatorio")
    });
    const SubirDomicilio = (info) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "flotillaID": datos.id.S,
            "usuarioID": datos.usuarioID.S,
            "municipio": info.municipio.toUpperCase(),
            "codigo_postal": info.codigo_postal,
            "localidad": info.localidad.toUpperCase(),
            "colonia": info.colonia.toUpperCase(),
            "calle": info.calle.toUpperCase(),
            "estado": info.estado.toUpperCase(),
            "telefono": info.telefono,
            "numero_exterior": info.numero_exterior,
            "numero_interior": info.numero_interior === "" ? "Sin numero" : info.numero_interior,
            "datosReferencia": info.datosReferencia.toUpperCase(),
            "indicacionesEntrega": info.indicaciones.toUpperCase(),
            "entreCalle1": info.entreCalle1.toUpperCase(),
            "entreCalle2": info.entreCalle2.toUpperCase(),
            "nombreDestinatario": `${info.nombre.toUpperCase()} ${info.apellidos.toUpperCase()}`
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/createDireccion`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                handleNext();
            })
            .catch((error) => console.log("error", error));
    }
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            SubirDomicilio(values);


        },
        initialValues: {
            nombre: "",
            apellidos: "",
            telefono: "",
            estado: "",
            municipio: "",
            codigo_postal: "",
            localidad: "",
            colonia: "",
            calle: "",
            numero_exterior: "",
            numero_interior: "",
            usuarioID: user.username,
            entreCalle1: "",
            entreCalle2: "",
            datosReferencia: "",
            indicaciones: ""
        },
    });

    const handleObtenrDomicilio = async (data) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = {
            codigoPostal: data,
        };

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/zipcodeInfo`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setDirecciones(result);
                formik.setFieldValue("estado", result.estado[0]);
                formik.setFieldValue("municipio", result.municipio[0]);
                formik.setFieldValue("calle", "");
                formik.setFieldValue("numero_exterior", "");
                formik.setFieldValue("numero_interior", "");


                if (result.localidad.length === 0) {
                    formik.setFieldValue("localidad", "Sin Localidad");
                } else {
                    formik.setFieldValue("localidad", result.localidad[0]);
                }
                if (result.municipio.length === 0) {
                    formik.setFieldValue("municipio", "Sin Municipio");
                } else {
                    formik.setFieldValue("municipio", result.municipio[0]);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "telefono") {
            const numericValue = value.replace(/\D/g, "");
            if (numericValue.length <= 10 && "telefono") {
                // Actualizar el valor del campo usando el método setFieldValue de Formik
                formik.setFieldValue(name, numericValue);
            }
        } else {
        }
    };
    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        formik.setFieldValue(name, numericValue);
        if (value.length === 5) {
            handleObtenrDomicilio(value);
        }
    };
    const handleInputChangeNumero = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        formik.setFieldValue(name, numericValue);

    };
    return (
        <>
            <div className="HeaderTramite">
                <View className="FormTitle">Emplacamiento Exprés</View>
                <View className="FormSubTitle">Dirección y Método de Pago</View>
                <View className="FormDescripcion"></View>
            </div>
            <Row className="mb-0">
                <Form.Group as={Col} md="5" style={{ textAlign: "left" }}>
                    <button type="button" onClick={() => navigate("/tramite/Historial-Flotillas")}
                        className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                        <FaArrowLeft /> Volver al Inicio
                    </button>

                </Form.Group>
            </Row>
            <View className="ViewPrincipal">
                <View className="FormContent">
                    <Box sx={{ width: "100%" }} className="ViewActa">
                        <Stepper activeStep={activeStep} className="mb-3">
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <></>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 ? (
                                    <Box>
                                        <Form
                                            onSubmit={formik.handleSubmit}
                                            noValidate

                                        >
                                            {/*    <Row className="mb-3">
                                          <Form.Label>Seleccione una opción de facturación</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            type="radio"
                                                            label="Flotilla"
                                                            name="radioGroup"
                                                            value="Flotilla"
                                                            checked={selectedOption === 'Flotilla'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            label="Vehículo"
                                                            name="radioGroup"
                                                            value="Vehiculo"
                                                            checked={selectedOption === 'Vehiculo'}
                                                            onChange={handleRadioChange}
                                                        />
                                                    </Form.Group>
                                           
                                            </Row> */}
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        Datos de Entrega
                                                    </Form.Label>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Nombre*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nombre"
                                                        value={formik.values.nombre.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.nombre &&
                                                            !formik.errors.nombre
                                                        }
                                                        disabled={show}
                                                        isInvalid={
                                                            formik.touched.nombre &&
                                                            !!formik.errors.nombre
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.nombre}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Apellido (s)*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="apellidos"
                                                        value={formik.values.apellidos.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.apellidos && !formik.errors.apellidos}
                                                        disabled={show}
                                                        isInvalid={
                                                            formik.touched.apellidos && !!formik.errors.apellidos
                                                        }
                                                    />

                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.apellidos}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Teléfono de Contacto*</Form.Label>

                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        placeholder="(999) 999-9999"
                                                        value={formik.values.telefono}
                                                        onChange={(event) => formik.setFieldValue('telefono', event.target.value)}
                                                        disabled={show}
                                                        className={`form-control ${formik.touched.telefono && formik.errors.telefono ? 'is-invalid' : formik.touched.telefono ? 'is-valid' : ''}`}
                                                    />


                                                    {/*  <Form.Control
                                                        type="text"
                                                        name="telefono"
                                                        value={formik.values.telefono}
                                                        onChange={handleInputChange}
                                                        isValid={
                                                            formik.touched.telefono && !formik.errors.telefono
                                                        }
                                                        isInvalid={
                                                            formik.touched.telefono &&
                                                            !!formik.errors.telefono
                                                        }
                                                    /> */}

                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.telefono}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        Dirección de Envio
                                                    </Form.Label>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Código Postal*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="codigo_postal"
                                                        maxLength="5"
                                                        disabled={show}
                                                        value={formik.values.codigo_postal}
                                                        /*  onBlur={() =>
                                                           handleObtenrDomicilio(formik.values.codigo_postal)
                                                         }  */
                                                        onChange={handleInputChangeCodigo}
                                                        isValid={
                                                            formik.touched.codigo_postal &&
                                                            !formik.errors.codigo_postal
                                                        }
                                                        isInvalid={
                                                            formik.touched.codigo_postal &&
                                                            !!formik.errors.codigo_postal
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.codigo_postal}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Estado*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="estado"
                                                        readOnly
                                                        disabled
                                                        value={formik.values.estado.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.estado && !formik.errors.estado
                                                        }
                                                        isInvalid={
                                                            formik.touched.estado && !!formik.errors.estado
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.estado}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Municipio*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        name="municipio"
                                                        value={formik.values.municipio.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.municipio &&
                                                            !formik.errors.municipio
                                                        }
                                                        isInvalid={
                                                            formik.touched.municipio &&
                                                            !!formik.errors.municipio
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.municipio}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Localidad*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="localidad"
                                                        disabled
                                                        value={formik.values.localidad.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.localidad &&
                                                            !formik.errors.localidad
                                                        }

                                                        isInvalid={
                                                            formik.touched.localidad &&
                                                            !!formik.errors.localidad
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.localidad}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Colonia*</Form.Label>
                                                    <Form.Select
                                                        type="text"
                                                        name="colonia"
                                                        value={formik.values.colonia}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.colonia && !formik.errors.colonia
                                                        }
                                                        disabled={show}
                                                        isInvalid={
                                                            formik.touched.colonia && !!formik.errors.colonia
                                                        }
                                                    >
                                                        <option value="">Seleccione</option>
                                                        {direcciones !== null &&
                                                            direcciones.colonias.map((data, index) => (
                                                                <option key={index} value={data.toUpperCase()}>
                                                                    {data.toUpperCase()}
                                                                </option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.colonia}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Calle*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="calle"
                                                        value={formik.values.calle.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.calle && !formik.errors.calle
                                                        }
                                                        isInvalid={
                                                            formik.touched.calle && !!formik.errors.calle
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.calle}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Número Exterior*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="numero_exterior"
                                                        value={formik.values.numero_exterior}
                                                        onChange={handleInputChangeNumero}
                                                        isValid={
                                                            formik.touched.numero_exterior &&
                                                            !formik.errors.numero_exterior
                                                        }
                                                        isInvalid={
                                                            formik.touched.numero_exterior &&
                                                            !!formik.errors.numero_exterior
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.numero_exterior}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Número Interior</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="numero_interior"
                                                        value={formik.values.numero_interior}
                                                        onChange={handleInputChangeNumero}
                                                        isValid={
                                                            formik.touched.numero_interior &&
                                                            !formik.errors.numero_interior
                                                        }
                                                        isInvalid={
                                                            formik.touched.numero_interior &&
                                                            !!formik.errors.numero_interior
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.numero_interior}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Entre calle 1*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="entreCalle1"
                                                        value={formik.values.entreCalle1.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.entreCalle1 &&
                                                            !formik.errors.entreCalle1
                                                        }
                                                        isInvalid={
                                                            formik.touched.entreCalle1 &&
                                                            !!formik.errors.entreCalle1
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.entreCalle1}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Entre calle 2*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="entreCalle2"
                                                        value={formik.values.entreCalle2.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.entreCalle2 &&
                                                            !formik.errors.entreCalle2
                                                        }
                                                        isInvalid={
                                                            formik.touched.entreCalle2 &&
                                                            !!formik.errors.entreCalle2
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.entreCalle2}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Datos de Referencia*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="datosReferencia"
                                                        value={formik.values.datosReferencia.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.datosReferencia &&
                                                            !formik.errors.datosReferencia
                                                        }
                                                        isInvalid={
                                                            formik.touched.datosReferencia &&
                                                            !!formik.errors.datosReferencia
                                                        }
                                                        disabled={show}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.datosReferencia}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="position-relative">
                                                    <Form.Label>Indicaciones de entrega*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="indicaciones"
                                                        value={formik.values.indicaciones.toUpperCase()}
                                                        onChange={formik.handleChange}
                                                        isValid={
                                                            formik.touched.indicaciones &&
                                                            !formik.errors.indicaciones
                                                        }
                                                        disabled={show}
                                                        isInvalid={
                                                            formik.touched.indicaciones &&
                                                            !!formik.errors.indicaciones
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.indicaciones}
                                                    </Form.Control.Feedback>

                                                </Form.Group>

                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="d-grid gap-2">
                                                    <Button type="submit" variant="secondary" disabled={show}>
                                                        Seleccionar Método de Pago
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="d-grid gap-2">
                                                    <Button
                                                        variant="link"
                                                        onClick={() =>
                                                            navigate("/tramite/Historial-Flotillas")
                                                        }
                                                    >
                                                        Regresar
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </Box>
                                ) : (
                                    <>
                                        <FormPagos handleBack={handleBack} data={data} datosTicket={datos} setData={setData} setShow={setShow} />
                                    </>
                                )}
                            </React.Fragment>
                        )}
                    </Box>
                    <ToastContainer closeOnClick />
                </View>
            </View>
            {activeStep === 1 &&
                <ResumenFlotillas data={data} />
            }



        </>

    )
}
