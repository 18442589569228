import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Col, Button, Row, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import Iframe from "react-iframe";
import { ConstructionOutlined } from '@mui/icons-material';
import { Backdrop, CircularProgress } from '@mui/material';
export const FormDocumentos = (props) => {
    const { datos, setChangeData, changeData } = props;
    const [archivos, setArchivos] = useState([])
    const [editar, setEditar] = useState(datos.documentos !== null ? true : false);
    const [viewDocuent, setViewDocument] = useState(false);
    const [infoDocuent, setInfoDocument] = useState("");
    const [tocado, setTocado] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": datos.procedureType
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/fileConfig/listActiveFiles", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                setArchivos(result.items)
            })
            .catch((error) => console.error(error));
    }, [])

    const fileValidationSchema = (allowedFileTypes, size) =>
        Yup.mixed()
            .test('es-requerido', 'Este documento es obligatorio', function (value) {
                if (datos.documentos === null) {
                    return !!value; // Requiere que haya un archivo cargado si 'datos.documentos' es null
                }
                return true; // Si 'datos.documentos' no es null, no es requerido
            })
            .test("fileSize", "El tamaño del archivo no debe exceder 10 MB", value => {
                if (!value) return true; // Si no hay archivo, no se aplica la validación de tamaño
                return value.size <= size * 1024 * 1024; // Devuelve true si el tamaño es válido
            })

            .test(
                "fileType",
                `Solo se permiten archivos de tipo: ${allowedFileTypes.join(", ").toLowerCase()}`,
                value => {
                    // Si no hay archivo, se permite, no se aplica la validación de tipo
                    if (!value) return true; // Devuelve true si no hay archivo cargado

                    // Obtiene la extensión del archivo
                    const fileExtension = value.name.split('.').pop().toLowerCase();
                    const lowerCaseAllowedFileTypes = allowedFileTypes.map(type => type.toLowerCase());

                    // Valida si la extensión está permitida
                    return lowerCaseAllowedFileTypes.includes(fileExtension);
                }
            )
        ;

    // Esquema de validación general basado en los nombres únicos
    const validationSchema = () =>
        Yup.object().shape(
            archivos.reduce((acc, archivo) => {
                // Usa el fileType de cada objeto para su validación
                if (archivo.active === 1) {
                    acc[archivo.formID] = fileValidationSchema(archivo.fileType, archivo.size);
                }
                return acc;
            }, {})
        );

        const buscarCodeNamePorFormID = (array, formID) => {
            const item = array.find(item => item.formID === formID);
            return item ? item.codeName : null;
        };
        
    const handleSubirArchivos = async (values) => {
        setLoadingSave(true)
        var myHeaders = new Headers();
        const arrayArchivos = [];
        console.log(values)
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const nameArc = buscarCodeNamePorFormID(archivos, parseInt(key))
                console.log(nameArc)
                const file = values[key];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: file,
                    redirect: "follow",
                };
                await fetch(
                    `https://h1xthh0vqj.execute-api.us-east-1.amazonaws.com/master/${nameArc}-${datos.id}.${fileExtension}`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {

                        console.log(result);
                        const nuevoValor = { name: key, url: result.s3_url };
                        arrayArchivos.push(nuevoValor);
                    })
                    .catch((error) => {
                        console.log("error", error)
                        setLoadingSave(false)
                    });
            }
        }
        const objetoTransformado = arrayArchivos.reduce((acc, archivo) => {
            acc[archivo.name] = archivo.url;
            return acc;
        }, {});
        console.log(arrayArchivos)
        SubirDocumento(objetoTransformado);
    };


    function updateObject(newItem, existingObject) {
        // Verifica que existingObject no sea undefined
        if (!existingObject) {
            console.error("El objeto existente no está definido.");
            return {};
        }

        // Desestructura el nuevo item para obtener name y url
        const name = Object.keys(newItem)[0]; // Obtiene la clave (name)
        const url = newItem[name]; // Obtiene la URL correspondiente

        // Actualiza el objeto existente si el name coincide con alguna clave
        if (existingObject.hasOwnProperty(name)) {
            existingObject[name] = url; // Actualiza la URL
        }

        // Devuelve el objeto actualizado
        return existingObject;
    }

    const SubirDocumento = async (arrayArchivos) => {
        console.log(arrayArchivos)
        let updatedObject = {};
        if (datos.documentos !== null) {
            console.log("opcion1")
            updatedObject = updateObject(arrayArchivos, datos.documentos);
        } else {
            updatedObject = arrayArchivos;
        }
        console.log(updatedObject)
        const user = await getCurrentUser();
        const data = {
            "id": datos.id,
            "procedure": datos.procedure,
            "type": datos.type,
            "usuarioID": user.username,
            "documentos": updatedObject,
        }
        CrearIteamAPI(data).then(result => {

            if (result.ok) {
                toast.success("Datos Guardados Correctamente", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setChangeData(!changeData)
                setEditar(true)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
            setLoadingSave(false)
        }).catch(error => {
            setLoadingSave(false)
            console.log(error)
        })
    }
    function buscarDocumento(clave) {
        if (datos.documentos.hasOwnProperty(clave)) {
            return datos.documentos[clave];
        } else {
            return null; // O puedes devolver un mensaje indicando que no existe.
        }
    }
    const handleDocumento = (url) => {
        const valor = buscarDocumento(url);

        setInfoDocument(valor);
        setViewDocument(true);
    };

    return (
        <>
            <Formik
                initialValues={archivos?.reduce((acc, archivo) => {
                    acc[archivo.formID] = null; // Inicializa cada campo de archivo como null
                    return acc;
                }, {})}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (JSON.stringify(values) === '{}') {
                        // El objeto está vacío
                        toast.error("Sin Documentos para Actualizar", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {

                        handleSubirArchivos(values);
                    }
                }}
            >
                {({ setFieldValue, errors, touched, setFieldError, handleSubmit, setFieldTouched, resetForm }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row className='mb-3'>
                            <BootstrapForm.Label style={{ textDecoration: "underline" }}>Adjuntar Documentación</BootstrapForm.Label>
                        </Row>
                        {archivos !== null && archivos.map((archivo) => (
                            <div key={archivo.id} className="mb-3">
                                <BootstrapForm.Group className='position-relative' as={Col} name={archivo.codeName}>
                                    <BootstrapForm.Label>{archivo.documentName}</BootstrapForm.Label>
                                    {datos.documentos === null || !editar ?
                                        <>
                                            {datos.documentos !== null &&

                                                <BootstrapForm.Control
                                                    readOnly
                                                    accept=".pdf"
                                                    plaintext
                                                    style={{ textDecoration: "underline", color: "blue" }}
                                                    onClick={() => handleDocumento(archivo.formID)}
                                                    defaultValue={"Ver Documento"}
                                                />
                                            }
                                            <BootstrapForm.Control
                                                type="file"

                                                disabled={editar}
                                                onChange={(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setFieldValue(archivo.formID, file);
                                                    /* 
                                                                                                        // Validaciones personalizadas
                                                                                                        if (file) {
                                                                                                            if (file.size > archivo.size * 1024 * 1024) {
                                                                                                                setFieldError(archivo.codeName, "El tamaño del archivo no debe exceder 10 MB");
                                                                                                            } else if (file.type !== "application/pdf") {
                                                                                                                setFieldError(archivo.codeName, "Solo se permiten archivos PDF");
                                                                                                            }
                                                                                                        } */
                                                }}
                                                isInvalid={tocado && !!errors[archivo.formID]} // Cambia esta línea
                                                accept={archivo.fileType.map(type => `.${type}`).join(',')}

                                            />
                                            <BootstrapForm.Control.Feedback type='invalid' tooltip>{errors[archivo.formID]}</BootstrapForm.Control.Feedback>
                                        </>
                                        :
                                        <BootstrapForm.Control
                                            readOnly
                                            accept=".pdf"
                                            plaintext
                                            style={{ textDecoration: "underline", color: "blue" }}
                                            onClick={() => handleDocumento(archivo.formID)}
                                            defaultValue={"Ver Documento"}
                                        />
                                    }
                                </BootstrapForm.Group>
                                {/*  <ErrorMessage name={archivo.name} component="div" className="text-danger" /> */}
                            </div>
                        ))}
                        {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                            <>
                                <Row className='mb-3'>
                                    <BootstrapForm.Group as={Col} className='d-grid'>
                                        {editar === false && datos.documentos !== null ?

                                            <Button variant='secondary' onClick={() => {
                                                setEditar(true)
                                                resetForm()
                                            }} >CANCELAR</Button>
                                            :
                                            <Button variant='secondary' disabled={datos.documentos !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                        }
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group as={Col} className='d-grid'>

                                        <Button variant='secondary' disabled={editar} type='submit' onClick={() => {
                                            setTocado(true)
                                        }}>GUARDAR</Button>
                                    </BootstrapForm.Group>
                                </Row>
                            </>
                            : null
                        }
                    </Form>
                )}
            </Formik>
            <ToastContainer />
            <Modal
                show={viewDocuent}
                animation={false}
                onHide={() => setViewDocument(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Documentos </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Iframe
                        src={infoDocuent}
                        height="700px"
                        width="750px"
                        display="initial"
                        position="relative"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setViewDocument(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

