import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Button as ButtonB } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Button,  
    Text,
    Loader,
    View,
} from "@aws-amplify/ui-react";
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import '../../App.css'
import { VerificarRFC, Complementarias, ObtenerToken, CalcularImpuesto } from "../../api/Impuestos"
import { publishMessage } from '../../graphql/mutations';
import { onMessageReceived } from '../../graphql/subscriptions';

import ResumenCard from './ResumenCard';
import NameFieldValid from './NameFieldValid';
import DeclaracionesAnt from './DeclaracionesAnt';
import Swal from 'sweetalert2'
import { toast, ToastContainer } from 'react-toastify';

import Iframe from 'react-iframe';
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png'
import { Backdrop, CircularProgress } from '@mui/material';
import ModalActions from './ModalActions';

import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { useTramiteDataGeneral } from '../../TramiteContext';


const steps = ['Información', 'Formas de pago'];
const tipo = { 
    PRESUPUESTO: 'presupuesto',
    RECIBO: 'Recibo',
    PRE: 'precalculo',
    REFERENCIA: 'pago2',
    PAGO: 'pago1'
  }
  const client = generateClient();
export const FormEmisionCon = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const { data, updateData, setCalculo, setDeclaracion, calculo } = useTramiteDataGeneral()
    const [loadingPago, setLoadingPago] = useState(false);
    const [loadingRFC, setLoadingRFC] = useState(false);
    const [idForm, setIdForm] = useState('Not Assign');
    const [recalc, setRecalc] = useState(false)
    const [recalcRFC, setRecalcRFC] = useState(false)
    const [pagoExitoModal, setPagoExitoModal] = useState(false)
    const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
    const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false)
    const REACT_APP_HOME = process.env.REACT_APP_HOME;
    const [errorMessage, setErrorMessage] = useState('')
    const [tipoAccion, setTipoAccion] = useState(tipo.PRE)
    const [isLoading, setIsLoading] = useState(false);
    const [infoRFC, setInfoRFC] = useState({});
    const [authToken, setAuthToken] = useState('')
    const [taskToken, setTaskToken] = useState('')
    const [token, setToken] = useState("");
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [declaracionesImp, setDeclaracionesImp] = useState([]);
    const [response, setResponse] = useState("");
    const [calculoData, setCalculoData] = useState({})
    const [descuento, showDescuento] = useState(false)
    const [descuentoValor, setDescuentoValor] = useState('')
    const [candadoFechaAnual, setCandadoFechaAnual] = useState(false)
    const [loading, setLoading] = useState(false);
    //alerts
    const [reDecs, setReDecs] = useState(false)
    const [ExitoLiga, setExitoLiga] = useState(false)
    const [FalloLiga, setFalloLiga] = useState(false)
    const [info, setInfo] = useState("");

    const [payloadDeclaraciones, setPayloadDeclaraciones] = useState(null)
    const [payloadCalculo, setPayloadCalculo] = useState(null)
    const [payloadPago, setPayloadPago] = useState(null)
    const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT 
    const [ligaPagoMit, setLigaPagoMit] = useState(null)
    const [callFinal, setCallFinal] = useState(null)
    const [advertenciaPago, setAdvertenciaPago] = useState(null)
    const [continuarPago, setContinuarPago] = useState(false)
    const [pantallaExito, setPantallaExito] = useState(false)
    const [respuestaPago, setRespuestaPago] = useState({});
    const [respuestaRecibo, setRespuestaRecibo] = useState({})
    const [presupuestoGenerado, setPresupuestoGenerado] = useState(false);


    useEffect(() => {
        generateID()
      }, [])
      const generateID = async () => {
        const user = await getCurrentUser();
        const id = `InscEmisionCon_${user.username}_${Date.now()}`
        setIdForm(id)
        return id
      }

      const validationSchema = yup.object().shape({
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        anno: yup.string().required("Este Campo es Obligatorio").matches(/^(19|20)\d{2}$/, 'El año debe estar en el formato YYYY'),
        periodo: yup.string().required("Este Campo es Obligatorio"),
        /*  MesFinal: yup.string().required("Este Campo es Obligatorio"), */
      saldoAFavor: yup.number().typeError("El valor debe ser un numero")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los numeros deben ser positivos'),
      compensacion: yup.number().typeError("El valor debe ser un numero")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los numeros deben ser positivos'),
 
      multas: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
        metrosCuadradosSuelo: yup.number().typeError("El valor debe ser un número")
        .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
          if (value !== undefined) {
            return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
          }
          return true;
        })
        .min(0, 'Los números deben ser positivos'),
        
        SBen: yup.number().typeError("El valor debe ser un número")
        .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
          if (value !== undefined) {
            return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
          }
          return true;
        })
        .min(0, 'Los números deben ser positivos'),
        STol: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SEti: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SInd: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SXil: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBenz: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SDib: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBena: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBenb: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SNiq: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SArs: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBenk: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBar: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SBer: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SCad: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SCro: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SMer: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SPla: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SPlo: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SSel: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        STal: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        SVan: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        metrosCubicosAgua: yup.number().typeError("El valor debe ser un numero")
        .min(0, 'Los numeros deben ser positivos').required("Este Campo es Obligatorio")
        .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
          if (value !== undefined) {
            return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
          }
          return true;
        }),
        ASst: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        AGrac: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ADbo: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ANit: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        AFos: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        AArs: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ACad: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ACia: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ACob: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ACro: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        AMer: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        ANiq: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        APlo: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),
        AZin: yup.string().matches(/^\d+\.\d{6}$/, 'El número debe ser positivo y tener exactamente 6 decimales'),

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        onSubmit: values => {
        },
        initialValues: {
            RFC: '',
            periodo: '',
            saldoAFavor: '',
            compensacion: '',
            multas: '',
            metrosCuadradosSuelo: '',
            SBen: "",
            STol: "",
            SEti: "",
            SInd: "",
            SXil: "",
            SBenz: "",
            SDib: "",
            SBena: "",
            SBenb: "",
            SNiq: "",
            SArs: "",
            SBenk:"",
            SBar: "",
            SBer: "",
            SCad: "",
            SCro: "",
            SMer: "",
            SPla: "",
            SPlo: "",
            SSel: "",
            STal: "",
            SVan: "",
            metrosCubicosAgua: '',
            ASst: "",
            AGrac: "",
            ADbo: "",
            ANit: "",
            AFos: "",
            AArs: "",
            ACad: "",
            ACia: "",
            ACob: "",
            ACro: "",
            AMer: "",
            ANiq: "",
            APlo: "",
            AZin: "",
        }

    });

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)
    
        return `${day}/${month}/${year}`;
      }

      const resetFormManual = async () => {

        formik.resetForm();
        setReDecs(false)
        setRecalc(false)
        setRecalcRFC(false)
        setCalculo(null)
        setCalculoData(null)
        setDeclaracion(null)
        setDeclaracionesImp([])
        setInfoRFC(null)
        return await generateID();
      }
      const resetFormAnno = (anno) => {
        formik.resetForm();
        // setReDecs(false)
        // setRecalc(false)
         setCalculo(null)
        setCalculoData(null)
        setDeclaracion(null)
        setDeclaracionesImp([]) 
        formik.setFieldValue('RFC', infoRFC.rfc)
        formik.setFieldValue('anno', anno)
        formik.setFieldValue('periodo', "")
      }
    
      const resetFormPeriodo = (per, anno) => {
        formik.resetForm();
        // setReDecs(false)
        // setRecalc(false)
        setCalculo(null)
        setCalculoData(null)
        setDeclaracion(null) 
        setDeclaracionesImp([])
        formik.setFieldValue('RFC', infoRFC.rfc)
        formik.setFieldValue('periodo', per)
        formik.setFieldValue('anno', anno)
      }

      useEffect(() => {
        if (infoRFC?.periodicidad?.tipoPeriodo !== "ANUAL") {
          return
        }
        const fechaActual = new Date();
        // Definir las fechas límite (1 de enero al 17 de febrero)
    const fechaLimiteInicio = new Date(fechaActual.getFullYear(), 0, 1); // 1 de enero
    let diaComparar = 17
    if (new Date(fechaActual.getFullYear(), 1, 17).getDay === 6) {
      diaComparar = 19
    }
    if (new Date(fechaActual.getFullYear(), 1, 17).getDay === 0) {
      diaComparar = 18
    }
    const fechaLimiteFin = new Date(fechaActual.getFullYear(), 1, diaComparar);   // 17 de febrero

    if (fechaActual >= fechaLimiteInicio && fechaActual <= fechaLimiteFin) {
      console.log("dentro del rango");
    } else {
      console.log("Fuera del rango de fechas permitido");
      setCandadoFechaAnual(true)
    }
  }, [infoRFC])

  useEffect(() => {
    if (declaracionesImp && declaracionesImp.length >= 4) {
      setDecLimiteAlcanzado(true)
      setReDecs(false);
    } else {
      setDecLimiteAlcanzado(false);
    }
  }, [declaracionesImp])

  function calcularSumaTotal() {
    // Verificar si declaracionesImp es un arreglo no vacío
    if (Array.isArray(declaracionesImp) && declaracionesImp.length > 0) {
      // Sumar los valores del campo "total"
      const sumaTotal = declaracionesImp.reduce((acumulador, declaracion) => {
        // Convertir el campo "total" a número antes de sumarlo
        const total = parseFloat(declaracion.total) || 0;
        return acumulador + total;
      }, 0);

      return sumaTotal;
    } else {
      // Si el arreglo está vacío o no es un arreglo, retornar 0
      return 0;
    }
  }

  const validarRFCendpoint = async (RFC) => {

    setLoadingRFC(true)

    if (recalcRFC) {
      const rest = await resetFormManual();
      formik.setFieldValue('RFC', RFC)
    }
    
    const authEndpoint = `${SEI_API_ENDPOINT}/sei/auth`;
    const validateEndpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/validate`;
    const requestData = {
        rfc: RFC,
        clavePadron: "IECSA"
      };
  
      try {
        // Primera consulta para obtener la autenticación
        const authResponse = await fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({})
        });
  
        if (!authResponse.ok) {
          throw new Error(`Error en la primera consulta: ${authResponse.status}`);
        }
  
        const authData = await authResponse.json();
        console.log("DATA consulta", authData);
        setAuthToken(authData.token)
        // Segunda consulta utilizando el token de autenticación obtenido
        const validateResponse = await fetch(validateEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authData.token}`
          },
          body: JSON.stringify(requestData)
        });
  
        if (!validateResponse.ok) {
          const errorData = await validateResponse.json();
          setErrorMessage(errorData.error)
          setPagoFallidoModal(true)
          setRecalcRFC(false)
          throw new Error(`Error en la segunda consulta: ${validateResponse.status}`);
        }
  
        const validateData = await validateResponse.json();
  
        // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
       
        setInfoRFC(validateData.response)
        setPayloadDeclaraciones(validateData.payload)
        setRecalcRFC(true)
        setLoadingRFC(false)
  
        if (validateData.response.periodicidad.tipoPeriodo === "ANUAL") {
          declaracionesEndpoint("", 1, 12, validateData.response, null, authData.token)
        }
      } catch (error) {
        console.error("Error en las consultas:", error.message);
        setLoadingRFC(false)
      }
  
    }

    const declaracionesEndpoint = async (mes, start = null, end = null, rfcData = null, task = null, authts = null) => {
        console.log(mes)
        let anno = formik.values.anno
        let taskT = task ? task : taskToken
        let authT = authts ? authts : authToken
        const info = rfcData ? rfcData : infoRFC
        let perio = end ? end : mes
        if (start && end) {
          formik.setFieldValue("periodo", end)
        }
        const data = formik.values
      
        let mesinicio = 0;
        let mesfin = 0;
        if (info.periodicidad.tipoPeriodo === "MENSUAL") {
          mesinicio = mes
          mesfin = mes
        }
        if (info.periodicidad.tipoPeriodo === "TRIMESTRAL") {
          mesinicio = Number(mes) - 2
          mesfin = mes
        }
        if (info.periodicidad.tipoPeriodo === "ANUAL" || (start && end)) {
          mesinicio = 1
          mesfin = 12
          const an = new Date().getFullYear()
          formik.setFieldValue("anno", an)
          anno = an
        }
        const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/complementarias`;
        setLoading(true)
        const requestData = {
            padron: {
              id: info.padronesDTO.id,
              nombrePadron: info.padronesDTO.nombre,
              clave: "IECSA"
            },
            periodicidad: {
              id: info.periodicidad.id,
              tipoPeriodo: info.periodicidad.tipoPeriodo,
              valor: info.periodicidad.valor
            },
            mdCaracteristicaId: `${info.mdCaracteristicasImpuestosId}`, // Reemplaza "{{mdCaracteristicaId}}" con el valor real
            rfc: `${info.rfc}`, // Reemplaza "{{rfc}}" con el valor real
            annio: anno,
            mesInicio: validateField(Number(mesinicio), false),
            mesFin: validateField(Number(mesfin), false)
          };
          try {
              const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${authT}`
                },
                body: JSON.stringify(requestData)
              });
              setLoading(false)
              if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.error)
                setPagoFallidoModal(true)
                setLoading(false)
                throw new Error(`Error en la consulta: ${response.status}`);
              }

              const responseData = await response.json();
  
        responseData.response.declaracionesImpPortal.sort(function (a, b) {
          return a.tipoDeclaracion - b.tipoDeclaracion;
      });
        // Aquí puedes manejar la respuesta de la consulta (responseData)
        console.log("Respuesta de la consulta:", responseData);
        setDeclaracion(responseData.response.declaracionesImpPortal)
        setDeclaracionesImp(responseData.response.declaracionesImpPortal)
        setPayloadCalculo(responseData.payload)
        setReDecs(true)
        if (responseData.response.descuentosImpPortal.length > 0) {
          if (responseData.response.descuentosImpPortal[0].referenciaEdoCTA !== null) {
            setDescuentoValor(responseData.response.descuentosImpPortal[0].referenciaEdoCTA)
            showDescuento(true)
          }
        }
        setLoading(false)
        setLoadingPago(false)
      } catch (error) {
        setLoadingPago(false)
        setErrorMessage('')
        setPagoFallidoModal(true)
        console.error("Error en la consulta:", error.message);
      }
    }

    const calcularServicioSei = async (generarEstado = false) => {
        const data = formik.values
        if(data.emision === "" || data.base === ""){
          console.log("falta un elemento")
        }else{
          setLoading(true)
    
          let mesinicio = 0;
          let mesfin = 0;
          if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = data.periodo
            mesfin = data.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(data.periodo) - 2
            mesfin = data.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
            mesinicio = 1
            mesfin = 12
          }
          const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/calculate`;
          const requestData = {
            padron: {
              id: infoRFC.padronesDTO.id,
              nombrePadron: infoRFC.padronesDTO.nombre,
              clave: "IECSA"
            },
            generaEstadoCuenta: generarEstado,
            mdCaracteristicaId: infoRFC.mdCaracteristicasImpuestosId,
            declaraciones: {
              rfc: infoRFC.rfc,
              annio: validateField(data.anno, false),
              mesInicio: validateField(Number(mesinicio), false),
              mesFin: validateField(Number(mesfin), false),
              compensacion: validateField(Number(data.compensacion)),
              saldoAFavor: validateField(Number(data.saldoAFavor)),
              multas: validateField(Number(data.multas)),
              
              // saldoFavor: validateField(Number(data.base)),
              metrosCuadradosSuelo: validateField(Number(data.metrosCuadradosSuelo)),
              metrosCubicosAgua: validateField(Number(data.metrosCubicosAgua)),
              SBen: validateField(Number(data.SBen)),
              STol: validateField(Number(data.STol)),
              SEti: validateField(Number(data.SEti)),
              SInd: validateField(Number(data.SInd)),
              SXil: validateField(Number(data.SXil)),
              SBenz: validateField(Number(data.SBenz)),
              SDib: validateField(Number(data.SDib)),
              SBena: validateField(Number(data.SBena)),
              SBenb: validateField(Number(data.SBenb)),
              SNiq: validateField(Number(data.SNiq)),
              SArs: validateField(Number(data.SArs)),
              SBenk: validateField(Number(data.SBenk)),
              SBar: validateField(Number(data.SBar)),
              SBer: validateField(Number(data.SBer)),
              SCad: validateField(Number(data.SCad)),
              SCro: validateField(Number(data.SCro)),
              SMer: validateField(Number(data.SMer)),
              SPla: validateField(Number(data.SPla)),
              SPlo: validateField(Number(data.SPlo)),
              SSel: validateField(Number(data.SSel)),
              STal: validateField(Number(data.STal)),
              SVan: validateField(Number(data.SVan)),
              ASst: validateField(Number(data.ASst)),
              AGrac: validateField(Number(data.AGrac)),
              ADbo: validateField(Number(data.ADbo)),
              ANit: validateField(Number(data.ANit)),
              AFos: validateField(Number(data.AFos)),
              AArs: validateField(Number(data.AArs)),
              ACad: validateField(Number(data.ACad)),
              ACia: validateField(Number(data.ACia)),
              ACob: validateField(Number(data.ACob)),
              ACro: validateField(Number(data.ACro)),
              AMer: validateField(Number(data.AMer)),
              ANiq: validateField(Number(data.ANiq)),
              APlo: validateField(Number(data.APlo)),
              AZin: validateField(Number(data.AZin)),
            }
          };
          console.log(requestData);
          try {
            const response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify(requestData)
          });
          if (!response.ok) {
            setLoading(false)
            const errorData = await response.json();
            setErrorMessage(errorData.error)
            setPagoFallidoModal(true)
            throw new Error(`Error en la consulta: ${response.status}`);
          }
          const responseData = await response.json();
          
          // Manejar la respuesta según tus necesidades
          setCalculo({ ...responseData.response, baseImpuesto: formik.values.base, otrosIncentivos: formik.values.incentivos })
          setCalculoData(responseData.response)
          // setData(data.impuestoSuelo)
          setPayloadPago(responseData.payload)
          setRecalc(true)
          setLoading(false)
          setPresupuestoGenerado(true);
          if (!generarEstado) {
            setLoading(false)
          }
        } catch (error) {
          console.error("Error en la consulta:", error.message);
        }
      }
      };

      const generarTransaccion = async (tipoP) => {
        console.log("TRANS", tipoP);
        setLoadingPago(true)
        const user = await fetchUserAttributes();
        try {
          const transaccionEndpoint = `${SEI_API_ENDPOINT}/sei/putEvent`;
          const conceptos = calculoData.conceptos?.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
          })
          const data = formik.values
          let mesinicio = 0;
          let mesfin = 0;
          let anno = formik.values.anno
    
          if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = data.periodo
            mesfin = data.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(data.periodo) - 2
            mesfin = data.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
            mesinicio = 1
            mesfin = 12
            const an = new Date().getFullYear()
            formik.setFieldValue("anno", an)
            anno = an
          }
          let tipoDec = ""
          if (declaracionesImp.length > 0 && declaracionesImp[0].id === null) {
            tipoDec = "Normal"
          } else {
            // Obtener la longitud del arreglo
            const longitudArreglo = declaracionesImp.length;
            tipoDec = "Complementaria " + longitudArreglo
          }
          const response = await fetch(transaccionEndpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify({
                id: idForm,
                payload: {
                  data: {
                    rfc: infoRFC.rfc,
                    email: user.email,
                    clavePadron: "IECSA",//infoRFC.padronesDTO.id,
                    padron: {
                      nombrePadron: infoRFC.padronesDTO.nombre
                    },
                    tipoDeclaracion: tipoDec,
                    user:user.name !== undefined ? user.name : "Contribuyente" ,
                    estadoCuenta: {
                      serie: calculo.estadoCuenta.serie,
                      identificador: calculo.estadoCuenta.identificador,
                      loteId: calculo.estadoCuenta.loteId,
                      tramiteId: calculo.estadoCuenta.tramiteId,
                      fechaVencimiento: calculo.estadoCuenta.fechaVencimiento,
                      total: calculo.calculos.total
                    },
                    serie: calculo.estadoCuenta.serie,
                    periodicidad: {
                      tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                    },
                    annio: validateField(anno, false),
                    mesInicio: validateField(Number(mesinicio), false),
                    mesFin: validateField(Number(mesfin), false),
                    conceptos: conceptos,
                    razonSocial: infoRFC.razonSocial,
                    tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                  },
                  authToken: authToken,
                  userId: user.sub,
                  type: "declaraciones",
                  "validationRequested": true,
                }
              })
            });
      
            if (!response.ok) {
              const errorData = await response.json();
              // Manejar el error según tus necesidades
              console.error("Error en la transacción:", errorData.error);
              setLoadingPago(false)
              return;
            }
            
            const responseData = await response.json();
            // Manejar la respuesta según tus necesidades
            console.log("Respuesta de la transacción:", responseData);
            if (tipoP === tipo.PAGO || tipoP === tipo.RECIBO || tipoP === tipo.REFERENCIA) {
              console.log("RESP");
              const resp = await GenerarLigaPago(tipoP);
              //setLoading(false)
              return responseData
            }
            //setLoading(false)
            return responseData
          } catch (error) {
            setLoadingPago(false);
            console.error("Error al realizar la transacción:", error.message);
            return error
          }
        };
      
        const llamarPago = async (tipoP) => {
          if (!infoRFC) {
            return
          }
          procesoPago(tipoP)
        }
      
        const procesoPago = async (tipoP) => {
         
          console.log("LLAMANDO A PAGO", infoRFC, tipoP);
          if (tipoP === tipo.PAGO || tipoP === tipo.REFERENCIA) {
            const resp = await generarTransaccion(tipoP);
            return
          }
          GenerarLigaPago(tipoP)
        }
    
        useEffect(() => {
            if (tipoAccion === tipo.PAGO || tipoAccion === tipo.RECIBO || tipoAccion === tipo.REFERENCIA || tipoAccion === tipo.PRESUPUESTO) {
              
              setTimeout(async () => {
                await llamarPago(tipoAccion);
              }, 2000);
            }
          }, [calculo])
    
          const validateField = (value, setString = true) => {
            return value === "" || value === 0 ? null : setString ? `${value}` : Number(value)
          }
    
          const GenerarLigaPago = async (tipoPago = null) => {
            setLoadingPago(true);
            const user = await fetchUserAttributes();
          // metodoPago : pago1 : card - pago2 : - pago3 :
          // prepara conceptos con lo de calculo
          const opcionPago = tipoPago ? tipoPago : metodoPago
          const conceptos = calculoData?.conceptos?.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
          })
          //preparar otras referencias
          const otrasRef = calculoData?.estadoCuenta?.referencias?.map(referencia => {
            const partes = referencia.recaudacion.split('-');
            const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
            const convenio = parteDespuesDelGuion
            return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
          })
          let mesinicio = 0;
          let mesfin = 0;
          if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = formik.values.periodo
            mesfin = formik.values.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(formik.values.periodo) - 2
            mesfin = formik.values.periodo
          }
          if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
            mesinicio = 1
            mesfin = 12
          }
          let tipoDec = ""
          if (declaracionesImp.length > 0 && declaracionesImp[0].id === null) {
            tipoDec = "Normal"
          } else {
            // Obtener la longitud del arreglo
            const longitudArreglo = declaracionesImp.length;
            tipoDec = "Complementaria " + longitudArreglo
          }
          const variables = opcionPago === "pago1" ?
            {
              "id": idForm,
              "payload": {
                "paymentMethod": "card",
                "user":user.name !== undefined ? user.name : "Contribuyente" ,
                "email": user.email,
                "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                "reference": `${calculoData.estadoCuenta?.referencias[0].referencia}`,//"202300011471739766260",
                "monto": `${calculoData.calculos?.total}`,//"0.01",
                authToken,
                taskToken,
                type: "declaraciones",
              }
            }
            :
            opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4" ?
            {
              "id": idForm,
              "payload": {
    
                "paymentMethod": "spei",
                "data": {
                  "user":user.name !== undefined ? user.name : "Contribuyente" ,
                  "loteId": `${calculoData.estadoCuenta?.loteId}`,
                  "total_pagar": calculoData.calculos?.total,
                  "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                  "movimiento": "IMPUESTO SOBRE EMISION DE CONTAMINANTES",
                  "rfc": infoRFC.rfc,
                  "fecha_generacion": getCurrentDate(),
                  "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                  "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                  "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                  "otras_referencias": otrasRef,
                  "periodo":infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` :`${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
    
                },
                "email": user.email,
                "procedure": "referencia_pago"
              }
            }
            : opcionPago === "Recibo" ?
              {
                "id": idForm,
                "payload": {
                  "data": {
                    user: user.name !== undefined ? user.name : "Contribuyente",
                    email: user.email,
                    clavePadron: "IECSA",//infoRFC.padronesDTO.id,
                    padron: {
                      nombrePadron: infoRFC.padronesDTO.nombre
                    },
                    tipoDeclaracion: tipoDec,
                    estadoCuenta: {
                      serie: calculo.estadoCuenta.serie,
                      identificador: calculo.estadoCuenta.identificador,
                      loteId: calculo.estadoCuenta.loteId,
                      tramiteId: calculo.estadoCuenta.tramiteId,
                      fechaVencimiento: calculo.estadoCuenta.fechaVencimiento
                    },
                    serie: calculo.estadoCuenta.serie,
                    periodicidad: {
                      tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                    },
                    annio: validateField(formik.values.anno, false),
                    mesInicio: validateField(Number(mesinicio), false),
                    mesFin: validateField(Number(mesfin), false),
                    conceptos: conceptos,
                    razonSocial: infoRFC.razonSocial,
                    tipoPeriodo: infoRFC.periodicidad.tipoPeriodo,
                    "docData": {
                      "user": user.name !== undefined ? user.name : "Contribuyente",
                      "loteId": `${calculoData.estadoCuenta?.loteId}`,
                      "identificador": `${calculoData.estadoCuenta?.identificador}`,
                      "serie": `${calculoData.estadoCuenta?.serie}`,
                      "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                      "fecha_generacion": getCurrentDate(),
                      "fecha_pago": getCurrentDate(),
                      "estatus": "PAGADO",
                      //"num_transaccion": calculoData.estadoCuenta?.loteId,
                      "rfc": infoRFC.rfc,
                      "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                      "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                      "movimiento": "IMPUESTO SOBRE EMISION DE CONTAMINANTES",
                      "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                      "tramiteId": calculoData.estadoCuenta?.tramiteId,
                      // "impuestoSuelo": formik.values.impuestoSuelo,
                      // "metrosCubicosAgua" : formik.values.metrosCubicosAgua,
                      "saldo_favor":`${calculoData.calculos?.saldoAFavor !== undefined ? calculoData.calculos?.saldoAFavor : "0"}`,
                      "instrumento_pago": "referencia bancaria",
                      "base_impuesto": `${formik.values.base}`,//"5%",
                      "periodo":infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` :`${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
                      "tipo_declaracion": tipoDec,//infoRFC.periodicidad.tipoPeriodo,
                      "otros_conceptos": conceptos,
                      "total": `${calculoData.calculos?.total}`,
    
                    }
                  },
                  "email": user.email,
                  "procedure": "recibo_pago",
                  "validationRequested": true,
                  "recalculate": false,
                  "type": "declaraciones",
                  userId: user.sub,
                  authToken,
                  taskToken
    
                },
              }
              :
              {
                "id": idForm,
                "payload": {
                  "paymentMethod": "spei",
                  "data": {
                    "user":user.name !== undefined ? user.name : "Contribuyente" ,
                    "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                    "fecha_generacion": getCurrentDate(),
                    "rfc": infoRFC.rfc,
                    "fecha_vigencia": `${formatearFecha(calculoData?.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                    "movimiento": "IMPUESTO SOBRE EMISION DE CONTAMINANTES",
                    "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                    // "impuestoSuelo": formik.values.impuestoSuelo,
                    // "metrosCubicosAgua" : formik.values.metrosCubicosAgua,
                    // "saldoAFavor" : formik.values.saldoAFavor,
                    "instrumento_pago": "referencia bancaria",
                    "suelo_subsuelo": calculoData?.calculos?.impuestoSuelo,
                    "agua": calculoData?.calculos?.impuestoAgua,
                    /* "base_impuesto": `${formik.values.base}` *///"5%",
                    "periodo":infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` :`${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
                    "tipo_declaracion": tipoDec,
                    "otros_conceptos": conceptos,
                    "total_pagar": `${calculoData?.calculos?.total}`,
                    "monto_anterior": calcularSumaTotal(),
/*                     "otros_incentivos": `${calculo?.otrosIncentivos}`,
 */                  },
                  "email": user.email,
                  "procedure": "presupuesto_contaminantes"
    
                },
              };
        const Data = await client.graphql({
          query: publishMessage,
          variables: {
            ...variables,
            payload: JSON.stringify(variables.payload),
          },
        });
       
      }
      const formatearFecha = (fecha) => {
        if (fecha) {
          const date = new Date(fecha);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return null;
      };
      
    const handleNext = async () => {
      await generateID(); 
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      };
    
      let nombresMeses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];

      const [message, setMessage] = useState(null);
      useEffect(() => {
        const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
          .subscribe({
            next: (event) => {
              const receivedMessage = event.data.onMessageReceived;
              //parse json on payload response. - authToken - taskToken --- rfc
              const dataRec = JSON.parse(receivedMessage.payload)
              setMessage(receivedMessage);
              console.log("DATA rec", dataRec);
              if (dataRec.taskToken) {
                setTaskToken(dataRec.taskToken)
              }
              if (dataRec?.error) {
                setErrorMessage(dataRec?.error)
                setPagoFallidoModal(true)
                if (dataRec.step === "validarRfc") {
                  setRecalcRFC(false)
                  setLoading(false)
                }
                //alert("Hubo un error intentalo de nuevo")
                return
              }
              if (dataRec.status === "PAGO VALIDADO") {
                //activar loader
                // console.log("Validar pago");
                setRespuestaPago(dataRec)
                // setLoadingPago(true)
                
              }
              if (dataRec?.procedure === "paymentUrl") {
                if (dataRec.response.cd_response === "error") {
                  //AlertsAction("error")
                  console.log("REP2");
                  setPagoExitoModal(false)
                  setPagoFallidoModal(true)
                  setLoadingPago(false);
                  setLoading(false)
                  return
                }
                if (dataRec.response.cd_response === "success") {
                  //AlertsAction("success")
                  //setPagoExitoModal(true)
                  setPagoFallidoModal(false)
                  setLigaPagoMit(dataRec.response.nb_url)
                  setLoadingPago(false);
                  setLoading(false);
                }
              }
              if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_contaminantes" || dataRec.Procedure === "recibo_pago") {
                if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_contaminantes") {
                  window.open(dataRec.TXTBody);
                  setLoadingPago(false)
                }
                if (dataRec.Procedure === "recibo_pago") {
                  //activar vista de exito
                  setPantallaExito(true)
                  setRespuestaRecibo(dataRec)
                  setLoadingPago(false)
                  setLoading(false)
                  return
                }
                if (dataRec.Procedure === "referencia_pago") {
                  setLoadingPago(false)
                  setLoading(false)
                  setPagoExitoModal(true)
                  setPagoFallidoModal(false)
                  return
                }
                return
              }
            },
            error: (error) => {
              console.error('Subscription error', error);
            },
          });

          return () => {
            subscription.unsubscribe();
          };
        }, [idForm]);

        const handleBlur = field => {
            formik.setFieldTouched(field, true);
          };
          function validarCadena(cadena) {
            const expresionRegular = /^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
        
            return expresionRegular.test(cadena);
          }
          const handleRFCChange = (e) => {
            const { name, value } = e.target;
        
            if (name === "RFC") {
              formik.setFieldValue(name, value.toUpperCase());
              if (value.length === 12 && name === "RFC") {
                if (validarCadena(value)) {
        
                  validarRFCendpoint(value)
                  formik.values.RFC = value
                } else {
                  formik.values.RFC = value
                  formik.setFieldError(name, "RFC no valido");
        
        
                }
              } else if (value.length === 13 && name === "RFC") {
                if (validarCadena(value)) {
                  formik.values.RFC = value
                  validarRFCendpoint(value)
                } else {
                  formik.values.RFC = value
                  formik.setFieldTouched(name, true);
        
        
                }
        
              }
            }
        
            /* onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }} */
        
          };
        
          const validateRFC = (value) => {
            // Asegúrate de que la longitud no supere 13 caracteres
            if (value.length > 13) {
              return value.slice(0, 13); // Truncar la cadena a 13 caracteres
            }
            return value.trim().toUpperCase();
          };

          const generarOpcionesMeses = () => {
            const annoSeleccionado = Number(formik.values.anno) || 2023
            const fechaActual = new Date();
            const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript
        
            // Verificar si el año seleccionado es igual al año actual
            if (annoSeleccionado === fechaActual.getFullYear()) {
              // Si es igual, solo regresar opciones para los meses antes del mes actual
              const opciones = Array.from({ length: mesActual }, (_, index) => (
                <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
              ));
        
              return opciones;
            } else {
              // Si es diferente, regresar todas las opciones
              const opciones = Array.from({ length: 12 }, (_, index) => (
                <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
              ));
        
              return opciones;
            }
          }
          const generarOpcionesTrimestres = () => {
            const annoSeleccionado = Number(formik.values.anno) || 2023
            const fechaActual = new Date();
            const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript
            const trimestres = [3, 6, 9, 12];
        
            // Filtrar trimestres disponibles que sean antes del mes actual
            const trimestresDisponibles = trimestres.filter(trimestre => trimestre < mesActual);
        
            // Verificar si el año seleccionado es igual al año actual
            if (annoSeleccionado === fechaActual.getFullYear()) {
              // Si es igual, solo regresar opciones para los trimestres disponibles
              const opciones = trimestresDisponibles.map(trimestre => (
                <option key={trimestre} value={trimestre}>
                  {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
                </option>
              ));
        
              return opciones;
            } else {
              // Si es diferente, regresar todas las opciones
              const opciones = trimestres.map(trimestre => (
                <option key={trimestre} value={trimestre}>
                  {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
                </option>
              ));
              return opciones;
            }
          }
        
          function obtenerNombreMes(mes) {
            const nombresMeses = [
              "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
              "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ];
            return nombresMeses[mes - 1];
          }
          const [band, setBand]= useState(true);
          const handleChangeSelect = (event) => {
            const { name, value } = event.target;
            if (name === "anno") {
              formik.setFieldValue(name, value)
              formik.values.anno = value;
              resetFormAnno(formik.values.anno); handleBlur('anno')
            } else {
              formik.setFieldValue(name, value)
              formik.values.periodo = value;
              
              formik.setFieldTouched(name, true, false); // Tercer argumento a `false` evita la validación hasta que se haga un nuevo cambio
        
              handleBlur('periodo');
              resetFormPeriodo(formik.values.periodo, formik.values.anno);
              declaracionesEndpoint(value);
              
            }
        
            // Puedes realizar otras acciones aquí según tus necesidades */
          };
  

  

    let valuesAgua = [
        formik.values.ASst,
        formik.values.AGrac,
        formik.values.ADbo,
        formik.values.ANit,
        formik.values.AFos,
        formik.values.AArs,
        formik.values.ACad,
        formik.values.ACia,
        formik.values.ACob,
        formik.values.ACro,
        formik.values.AMer,
        formik.values.ANiq,
        formik.values.APlo,
        formik.values.AZin
    ];

    let valuesSuelo = [
        formik.values.SBen,
        formik.values.STol,
        formik.values.SEti,
        formik.values.SInd,
        formik.values.SXil,
        formik.values.SBenz,
        formik.values.SDib,
        formik.values.SBena,
        formik.values.SBenb,
        formik.values.SNiq,
        formik.values.SArs,
        formik.values.SBenk,
        formik.values.SBar,
        formik.values.SBer,
        formik.values.SCad,
        formik.values.SCro,
        formik.values.SMer,
        formik.values.SPla,
        formik.values.SPlo,
        formik.values.SSel,
        formik.values.STal,
        formik.values.SVan
    ];


    useEffect(() => {
        ObtenerToken().then(result => {
            if (result.ok === true) {
                setToken(result.message.token)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);

    const handleBlurRFC = (rfc) => {
        const padron = "IECSA";
        VerificarRFC(rfc, padron, token).then(result => {

            if (result.ok === true) {
                console.log(result.message)
                setResponse(result.message.response)
                setInfoRFC(result.message.response)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(err => {
            console.log(err)
        })
    }
  
    useEffect(() => {
      setPresupuestoGenerado(false);
    }, [formik.values]);

      

    // const handleCalcular = (values) => {
    //     console.log(values);
    //     let mesinicio = 0;
    //     let mesfin = 0;
    //     if (response.periodicidad.tipoPeriodo === "MENSUAL") {
    //         mesinicio = values.periodo
    //         mesfin = values.periodo
    //     }
    //     if (response.periodicidad.tipoPeriodo === "TRIMESTRAL") {
    //         mesinicio = Number(values.periodo) - 2
    //         mesfin = values.periodo
    //     }
    //     if (response.periodicidad.tipoPeriodo === "ANUAL") {
    //         mesinicio = 1
    //         mesfin = 12
    //     }
    //     const data = {
    //         "padron": {
    //             "id": response.padronesDTO.id,
    //             "nombrePadron": response.padronesDTO.nombre,
    //             "clave": "IECSA"
    //         },
    //         "generaEstadoCuenta": true, //Bandera booleana para generar estado de cuenta(Interno) 
    //         "mdCaracteristicaId": response.mdCaracteristicasImpuestosId, //Bandera booleana para generar estado de cuenta(Interno) 
    //         "declaraciones": { //Datos correspondientes a la declaración 
    //             "rfc": response.rfc, //RFC del contribuyente (Interno) 
    //             "annio": parseInt(values.annio), //Año del periodo declarado (Usuario) 
    //             "mesInicio": parseInt(mesinicio), //Mes inicio del periodo declarado (Usuario) 
    //             "mesFin": parseInt(mesfin), //Mes fin del periodo declarado (Usuario) 
    //             "compensacion": values.Compensacion, //Importe por compensación (Usuario) 
    //             "saldoAFavor": "100.00", //Importe por saldo a favor (Usuario) 
    //             "multas": values.Multas, //Importe por multas (Usuario) 
    //             "metrosCuadradosSuelo": VerificarNull(values.metrosCuadradosSuelo), //Superficie de suelo contaminada(Usuario) 
    //             "metrosCubicosAgua": VerificarNull(values.metrosCubicosAgua), //Volumen de agua contaminada(Usuario) 
    //             "SBen": VerificarNull(values.SBen), //Cantidad de Benceno en el suelo contaminado(Usuario) 
    //             "STol": VerificarNull(values.STol), //Cantidad de Tolueno en el suelo contaminado(Usuario) 
    //             "SEti": VerificarNull(values.SEti), //Cantidad de Etilbenceno en el suelo contaminado(Usuario) 
    //             "SInd": VerificarNull(values.SInd), //Cantidad de Indeno (1,2,3-cd) pireno en el suelo contaminado(Usuario) 
    //             "SXil": VerificarNull(values.SXil), //Cantidad de Xilenos suma de isómeros en el suelo contaminado(Usuario) 
    //             "SBenz": VerificarNull(values.SBenz), //Cantidad de Benzo[a] ireno en el suelo contaminado(Usuario) 
    //             "SDib": VerificarNull(values.SDib), //Cantidad de Dibenzo[ah]antraceno en el suelo contaminado(Usuario) 
    //             "SBena": VerificarNull(values.SBena), //Cantidad de Benzo [a] antraceno en el suelo contaminado(Usuario) 
    //             "SBenb": VerificarNull(values.SBenb), //Cantidad de Benzo [b] fluoranteno en el suelo contaminado(Usuario) 
    //             "SNiq": VerificarNull(values.SNiq), //Cantidad de Niquel en el suelo contaminado(Usuario) 
    //             "SArs": VerificarNull(values.SArs), //Cantidad de Arsénico en el suelo contaminado(Usuario) 
    //             "SBenk": VerificarNull(values.SBenk), //Cantidad de Benzo [k] fluoranteno en el suelo contaminado(Usuario) 
    //             "SBar": VerificarNull(values.SBar), //Cantidad de Bario en el suelo contaminado(Usuario) 
    //             "SBer": VerificarNull(values.SBer), //Cantidad de Berilio en el suelo contaminado(Usuario) 
    //             "SCad": VerificarNull(values.SCad), //Cantidad de Cadmio en el suelo contaminado(Usuario) 
    //             "SCro": VerificarNull(values.SCro), //Cantidad de Cromo Hexavalente en el suelo contaminado(Usuario) 
    //             "SMer": VerificarNull(values.SMer), //Cantidad de Mercurio en el suelo contaminado(Usuario) 
    //             "SPla": VerificarNull(values.SPla), //Cantidad de Plata en el suelo contaminado(Usuario) 
    //             "SPlo": VerificarNull(values.SPlo), //Cantidad de Plomo en el suelo contaminado(Usuario) 
    //             "SSel": VerificarNull(values.SSel), //Cantidad de Selenio en el suelo contaminado(Usuario) 
    //             "STal": VerificarNull(values.STal), //Cantidad de Talio en el suelo contaminado(Usuario) 
    //             "SVan": VerificarNull(values.SVan), //Cantidad de Vanadio en el suelo contaminado(Usuario) 
    //             "ASst": VerificarNull(values.ASst), //Cantidad de Sólidos Suspendidos Totales en el agua contaminada(Usuario) 
    //             "AGrac": VerificarNull(values.AGrac), //Cantidad de Grasas Aceites en el agua contaminada(Usuario) 
    //             "ADbo": VerificarNull(values.ADbo), //Cantidad de Demanda Bioquímica de Oxígeno 5 en el agua contaminada(Usuario) 
    //             "ANit": VerificarNull(values.ANit), //Cantidad de Nitrogeno Total en el agua contaminada(Usuario) 
    //             "AFos": VerificarNull(values.AFos), //Cantidad de 25 Fósforo Total en el agua contaminada(Usuario) 
    //             "AArs": VerificarNull(values.AArs), //Cantidad de Arsénico en el agua contaminada(Usuario) 
    //             "ACad": VerificarNull(values.ACad), //Cantidad de Cadmio en el agua contaminada(Usuario) 
    //             "ACia": VerificarNull(values.ACia), //Cantidad de Cianuro en el agua contaminada(Usuario) 
    //             "ACob": VerificarNull(values.ACob), //Cantidad de Cobre en el agua contaminada(Usuario) 
    //             "ACro": VerificarNull(values.ACro), //Cantidad de Cromo en el agua contaminada(Usuario) 
    //             "AMer": VerificarNull(values.AMer), //Cantidad de Mercurio en el agua contaminada(Usuario) 
    //             "ANiq": VerificarNull(values.ANiq), //Cantidad de Níquel en el agua contaminada(Usuario) 
    //             "APlo": VerificarNull(values.APlo), //Cantidad de Plomo en el agua contaminada(Usuario) 
    //             "AZin": VerificarNull(values.AZin) //Cantidad de Zinc en el agua contaminada(Usuario) 
    //         }
    //     };
       

    

    const renderPeriodos = (tipo) => {
        if (tipo === "MENSUAL") {
          return (
            <Row className="mb-3">
              {loading ? (
    
                <div >
                  <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                </div>
    
              ) : null}
              <Form.Group
                as={Col}
                controlId="validationFormik102"
                className="position-relative"
              >
                <Form.Label> Año*</Form.Label>
                <Form.Select
                  type="number"
                  name="anno"
                  value={formik.values.anno}
                  onChange={handleChangeSelect}
                  isValid={
                    formik.touched.anno && !formik.errors.anno
                  }
                  isInvalid={
                    !!formik.errors.anno && formik.touched.anno
                  }
                  disabled={!recalcRFC || loading}
                /*  onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }} */
                >
                  <option key="default" value="">
                    Seleccione...
                  </option>
                  {Array(2).fill(undefined).map((_, index) => (
                    <option key={index} value={new Date().getFullYear() - index}>
                      {new Date().getFullYear() - index}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.anno}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="validationFormik102"
                className="position-relative"
              >
                <Form.Label> Periodo*</Form.Label>
                <Form.Select
                  name="periodo"
                  value={formik.values.periodo}
                  onChange={handleChangeSelect}
                  isValid={
                    formik.touched.periodo &&
                    !formik.errors.periodo
                  }
                  isInvalid={
                    !!formik.errors.periodo && formik.touched.periodo
                  }
                  disabled={!recalcRFC || formik.values.anno === '' || loading}
                >
                  <option>Seleccione...</option>
                  {generarOpcionesMeses()}
                </Form.Select>
    
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.periodo}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          )
        }

        if (tipo === "TRIMESTRAL") {
            return (
              <Row className="mb-3">
                {loading ? (
      
                  <div >
                    <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                  </div>
      
                ) : null}
                <Form.Group
                  as={Col}
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label> Año*</Form.Label>
                  <Form.Select
                    type="number"
                    name="anno"
                    value={formik.values.anno}
                    onChange={handleChangeSelect}
                    isValid={
                      formik.touched.anno && !formik.errors.anno
                    }
                    isInvalid={
                      !!formik.errors.anno && formik.touched.anno
                    }
                    disabled={!recalcRFC || loading}
                  /*  onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }} */
                  >
                    <option key="default" value="">
                      Seleccione...
                    </option>
                    {Array(2).fill(undefined).map((_, index) => (
                      <option key={index} value={new Date().getFullYear() - index}>
                        {new Date().getFullYear() - index}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {formik.errors.anno}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label> Periodo*</Form.Label>
                  <Form.Select
                    name="periodo"
                    value={formik.values.periodo}
                    onChange={handleChangeSelect}
                    isValid={
                      formik.touched.periodo &&
                      !formik.errors.periodo
                    }
                    isInvalid={
                      !!formik.errors.periodo && formik.touched.periodo
                    }
                    disabled={!recalcRFC || formik.values.anno === '' || loading}
      /*               onBlur={() => { resetFormPeriodo(formik.values.periodo, formik.values.anno); declaracionesEndpoint(); handleBlur('periodo'); }}
       */            >
                    <option>Seleccione...</option>
                    {generarOpcionesTrimestres()}
                  </Form.Select>
      
                  <Form.Control.Feedback type="invalid" tooltip>
                    {formik.errors.periodo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            )
          }
      
          if (tipo === "ANUAL") {
            return (
              <Row className="mb-3">
                {loading ? (
      
                  <div >
                    <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                  </div>
      
                ) : null}
                <Form.Group
                  as={Col}
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label> Año*</Form.Label>
                  <Form.Select
                    type="number"
                    name="anno"
                    value={formik.values.anno}
                    onChange={handleChangeSelect}
                    isValid={
                      formik.touched.anno && !formik.errors.anno
                    }
                    isInvalid={
                      !!formik.errors.anno && formik.touched.anno
                    }
                    disabled={recalcRFC || recalc || loading}
                  /*  onBlur={() => { handleBlur('anno') }} */
                  >
                    <option key="default" value="">
                      Seleccione...
                    </option>
                    <option key="anual" value={new Date().getFullYear()}>
                      {new Date().getFullYear()}
                    </option>
      
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {formik.errors.anno}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            )
          }
      
          return (<div className='w-full flex justify-center text-sm text-gray-300'>No hay periodos correspondientes</div>)
        }


        const handleInputChange = (e) => {
          const {name,value} = e.target;
          let values = e.target.value;
       
       
          values = values.replace(/[^\d.]/g, '');
          const decimalParts = value.split('.');
          if (decimalParts.length > 1) {
            values = decimalParts[0] + '.' + decimalParts[1].slice(0, 6);
          }
         
            formik.setFieldValue(name, values);
        };   

   

    return (
      <>
        <div className="HeaderTramite">
          <View className="FormTitle">
            Impuestos ecologicos - Emisión de contaminantes al suelo, subsuelo y
            agua
          </View>
          <View className="FormDescripcion">
            Este trámite consiste en el pago del impuesto estatal que se genera
            a los sujetos obligados por la emisión de contaminantes al suelo,
            subsuelo y agua.
          </View>
        </div>
        <View className="ViewPrincipal">
          {pantallaExito ? (
            <PantallaExitoImpuestos
              pago={respuestaPago}
              recibo={respuestaRecibo}
              titulo={"Impuesto sobre nómina"}
            />
          ) : ligaPagoMit ? (
            <View>
              <div className="w-full flex justify-end">
                <img
                  width={"300px"}
                  height={"150px"}
                  src={imagenTarjeta}
                  alt="Mensaje tarjeta"
                />
              </div>
              <Iframe
                src={ligaPagoMit}
                height="850px"
                width={isMobile ? "99%" : "550px"}
                display="initial"
                styles={{ overflow: "hidden" }}
                position="relative"
              />
            </View>
          ) : (
            <View className="FormContent">
              <Form
                onSubmit={formik.handleSubmit}
                noValidate
                className="ViewActa"
              >
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};

                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        <label>
                          Descripcion: Pulsa el boton para generar tu orden de
                          pago.
                        </label>
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        style={{ justifyContent: "space-between" }}
                      >
                        <Button
                          variant="secondary"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Anterior
                        </Button>

                        <Button type="submit" variant="secondary">
                          Enviar
                        </Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStep === 0 ? (
                        <Box>
                          <Col>
                            <Row className="mb-3 mt-3">
                              <Form.Group
                                as={Col}
                                controlId="validationFormikRFC"
                                className="position-relative"
                              >
                                <Form.Label>RFC con Homoclave*</Form.Label>
                                <div style={{ position: "relative" }}>
                                  <Form.Control
                                    type="text"
                                    name="RFC"
                                    maxLength={13}
                                    disabled={loading}
                                    value={formik.values.RFC}
                                    onChange={handleRFCChange}
                                    /* onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }} */
                                    isValid={
                                      formik.touched.RFC && !formik.errors.RFC
                                    }
                                    isInvalid={
                                      !!formik.errors.RFC && formik.touched.RFC
                                    }
                                  />
                                  {loadingRFC ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                    >
                                      <Loader
                                        size="large"
                                        emptyColor="lightgray"
                                        filledColor="gray"
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.RFC}
                                </Form.Control.Feedback>

                                {infoRFC?.rfc?.length === 13 &&
                                  infoRFC?.nombre && (
                                    <NameFieldValid
                                      nombre={infoRFC?.nombre}
                                      domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `}
                                    />
                                  )}
                                {infoRFC?.rfc?.length === 12 &&
                                  infoRFC?.razonSocial && (
                                    <NameFieldValid
                                      nombre={infoRFC?.razonSocial}
                                      domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `}
                                    />
                                  )}
                              </Form.Group>
                            </Row>
                            {renderPeriodos(infoRFC?.periodicidad?.tipoPeriodo)}
                            {/****DECLARACIONES RENDER */}
                            <DeclaracionesAnt className="" tipo="emision" />
                            <Row className="mb-3 mt-3">
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                }}
                              >
                                Suelo y Subsuelo
                              </p>
                              <Form.Group
                                as={Col}
                                controlId="validationFormik102"
                                className="position-relative"
                              >
                                <Form.Label> Metros Cuadrados Suelo</Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder="Cantidad"
                                  name="metrosCuadradosSuelo"
                                  value={formik.values.metrosCuadradosSuelo}
                                  onChange={handleInputChange}
                                  isValid={
                                    formik.touched.metrosCuadradosSuelo &&
                                    !formik.errors.metrosCuadradosSuelo
                                  }
                                  isInvalid={
                                    !!formik.errors.metrosCuadradosSuelo &&
                                    formik.touched.metrosCuadradosSuelo
                                  }
                                  disabled={!reDecs }
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                {formik.errors.metrosCuadradosSuelo}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            {formik.values.metrosCuadradosSuelo > "" && (
                              <>
                                 <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>BENCENO</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBen"
                                  value={formik.values.SBen}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBen && !formik.errors.SBen
                                  }
                                  isInvalid={
                                    !!formik.errors.SBen && formik.touched.SBen
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBen}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>TOLUENO</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="STol"
                                  value={formik.values.STol}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.STol && !formik.errors.STol
                                  }
                                  isInvalid={
                                    !!formik.errors.STol && formik.touched.STol
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.STol}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                controlId="validationFormikRFC"
                                className="position-relative"
                              >
                                <Form.Label>Etilbenceno</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SEti"
                                  value={formik.values.SEti}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SEti && !formik.errors.SEti
                                  }
                                  isInvalid={
                                    !!formik.errors.SEti && formik.touched.SEti
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SEti}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                controlId="validationFormikRFC"
                                className="position-relative"
                              >
                                <Form.Label>Indeno (1 ,2,3-cd) pireno</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SInd"
                                  value={formik.values.SInd}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SInd && !formik.errors.SInd
                                  }
                                  isInvalid={
                                    !!formik.errors.SInd && formik.touched.SInd
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SInd}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                controlId="validationFormikRFC"
                                className="position-relative"
                              >
                                <Form.Label>
                                Xilenos (suma de isómeros)
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SXil"
                                  value={formik.values.SXil}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SXil && !formik.errors.SXil
                                  }
                                  isInvalid={
                                    !!formik.errors.SXil && formik.touched.SXil
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SXil}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Benzo[a]pireno</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBenz"
                                  value={formik.values.SBenz}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBenz && !formik.errors.SBenz
                                  }
                                  isInvalid={
                                    !!formik.errors.SBenz &&
                                    formik.touched.SBenz
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBenz}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Dibenzo[a,h ]antraceno
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SDib"
                                  value={formik.values.SDib}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SDib && !formik.errors.SDib
                                  }
                                  isInvalid={
                                    !!formik.errors.SDib && formik.touched.SDib
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SDib}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Benzo[a]antraceno
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBena"
                                  value={formik.values.SBena}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBena && !formik.errors.SBena
                                  }
                                  isInvalid={
                                    !!formik.errors.SBena &&
                                    formik.touched.SBena
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBena}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Benzo[b]fluoranteno
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBenb"
                                  value={formik.values.SBenb}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBenb && !formik.errors.SBenb
                                  }
                                  isInvalid={
                                    !!formik.errors.SBenb &&
                                    formik.touched.SBenb
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBenb}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Níquel</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SNiq"
                                  value={formik.values.SNiq}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SNiq && !formik.errors.SNiq
                                  }
                                  isInvalid={
                                    !!formik.errors.SNiq && formik.touched.SNiq
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SNiq}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Arsénico</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SArs"
                                  value={formik.values.SArs}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SArs && !formik.errors.SArs
                                  }
                                  isInvalid={
                                    !!formik.errors.SArs && formik.touched.SArs
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SArs}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Benzo[k]fluoranteno
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBenk"
                                  value={formik.values.SBenk}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBenk && !formik.errors.SBenk
                                  }
                                  isInvalid={
                                    !!formik.errors.SBenk &&
                                    formik.touched.SBenk
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBenk}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Bario </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBar"
                                  value={formik.values.SBar}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBar && !formik.errors.SBar
                                  }
                                  isInvalid={
                                    !!formik.errors.SBar && formik.touched.SBar
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBar}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Berilio </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SBer"
                                  value={formik.values.SBer}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SBer && !formik.errors.SBer
                                  }
                                  isInvalid={
                                    !!formik.errors.SBer && formik.touched.SBer
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SBer}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Cadmio </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SCad"
                                  value={formik.values.SCad}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SCad && !formik.errors.SCad
                                  }
                                  isInvalid={
                                    !!formik.errors.SCad && formik.touched.SCad
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SCad}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                  Cromo Hexavalente 
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SCro"
                                  value={formik.values.SCro}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SCro && !formik.errors.SCro
                                  }
                                  isInvalid={
                                    !!formik.errors.SCro && formik.touched.SCro
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SCro}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Mercurio </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SMer"
                                  value={formik.values.SMer}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SMer && !formik.errors.SMer
                                  }
                                  isInvalid={
                                    !!formik.errors.SMer && formik.touched.SMer
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SMer}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Plata</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SPla"
                                  value={formik.values.SPla}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SPla && !formik.errors.SPla
                                  }
                                  isInvalid={
                                    !!formik.errors.SPla && formik.touched.SPla
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SPla}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Plomo </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SPlo"
                                  value={formik.values.SPlo}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SPlo && !formik.errors.SPlo
                                  }
                                  isInvalid={
                                    !!formik.errors.SPlo && formik.touched.SPlo
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SPlo}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Selenio</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SSel"
                                  value={formik.values.SSel}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SSel && !formik.errors.SSel
                                  }
                                  isInvalid={
                                    !!formik.errors.SSel && formik.touched.SSel
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SSel}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Talio </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="STal"
                                  value={formik.values.STal}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.STal && !formik.errors.STal
                                  }
                                  isInvalid={
                                    !!formik.errors.STal && formik.touched.STal
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.STal}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Vanadio</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="SVan"
                                  value={formik.values.SVan}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.SVan && !formik.errors.SVan
                                  }
                                  isInvalid={
                                    !!formik.errors.SVan && formik.touched.SVan
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.SVan}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                               
                              </>
                            )}

                            <Row className="mb-3 mt-3">
                              <p
                                style={{
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                }}
                              >
                                Agua
                              </p>
                              <Form.Group
                                as={Col}
                                controlId="validationFormik102"
                                className="position-relative"
                              >
                                <Form.Label> Metros Cúbicos de agua</Form.Label>
                                <Form.Control
                                   type="text"
                                   placeholder="Cantidad"
                                   name="metrosCubicosAgua"
                                   value={formik.values.metrosCubicosAgua}
                                   onChange={handleInputChange}
                                  isValid={
                                    formik.touched.metrosCubicosAgua &&
                                    !formik.errors.metrosCubicosAgua
                                  }
                                  isInvalid={
                                    !!formik.errors.metrosCubicosAgua &&
                                    formik.touched.metrosCubicosAgua
                                  }
                                  disabled={!reDecs }
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.metrosCubicosAgua}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            {formik.values.metrosCubicosAgua > "" && (
                              <>
                            
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Sólidos Suspendidos Totales
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ASst"
                                  value={formik.values.ASst}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ASst && !formik.errors.ASst
                                  }
                                  isInvalid={
                                    !!formik.errors.ASst && formik.touched.ASst
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ASst}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Grasas y Aceites</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="AGrac"
                                  value={formik.values.AGrac}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.AGrac && !formik.errors.AGrac
                                  }
                                  isInvalid={
                                    !!formik.errors.AGrac &&
                                    formik.touched.AGrac
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.AGrac}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                Demanda Bioquímica de Oxígeno 5
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ADbo"
                                  value={formik.values.ADbo}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ADbo && !formik.errors.ADbo
                                  }
                                  isInvalid={
                                    !!formik.errors.ADbo && formik.touched.ADbo
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ADbo}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Nitrógeno Total</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ANit"
                                  value={formik.values.ANit}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ANit && !formik.errors.ANit
                                  }
                                  isInvalid={
                                    !!formik.errors.ANit && formik.touched.ANit
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ANit}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>
                                25 Fósforo Total
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="AFos"
                                  value={formik.values.AFos}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.AFos && !formik.errors.AFos
                                  }
                                  isInvalid={
                                    !!formik.errors.AFos && formik.touched.AFos
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.AFos}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Arsénico </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="AArs"
                                  value={formik.values.AArs}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.AArs && !formik.errors.AArs
                                  }
                                  isInvalid={
                                    !!formik.errors.AArs && formik.touched.AArs
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.AArs}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Cadmio</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ACad"
                                  value={formik.values.ACad}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ACad && !formik.errors.ACad
                                  }
                                  isInvalid={
                                    !!formik.errors.ACad && formik.touched.ACad
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ACad}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Cianuro</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ACia"
                                  value={formik.values.ACia}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ACia && !formik.errors.ACia
                                  }
                                  isInvalid={
                                    !!formik.errors.ACia && formik.touched.ACia
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ACia}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Cobre</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ACob"
                                  value={formik.values.ACob}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ACob && !formik.errors.ACob
                                  }
                                  isInvalid={
                                    !!formik.errors.ACob && formik.touched.ACob
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ACob}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Cromo</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ACro"
                                  value={formik.values.ACro}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ACro && !formik.errors.ACro
                                  }
                                  isInvalid={
                                    !!formik.errors.ACro && formik.touched.ACro
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ACro}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Mercurio</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="AMer"
                                  value={formik.values.AMer}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.AMer && !formik.errors.AMer
                                  }
                                  isInvalid={
                                    !!formik.errors.AMer && formik.touched.AMer
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.AMer}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Níquel</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="ANiq"
                                  value={formik.values.ANiq}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.ANiq && !formik.errors.ANiq
                                  }
                                  isInvalid={
                                    !!formik.errors.ANiq && formik.touched.ANiq
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.ANiq}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Plomo</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="APlo"
                                  value={formik.values.APlo}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.APlo && !formik.errors.APlo
                                  }
                                  isInvalid={
                                    !!formik.errors.APlo && formik.touched.APlo
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.APlo}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >
                                <Form.Label>Zinc</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cantidad"
                                  name="AZin"
                                  value={formik.values.AZin}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.AZin && !formik.errors.AZin
                                  }
                                  isInvalid={
                                    !!formik.errors.AZin && formik.touched.AZin
                                  }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.AZin}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                          
                               
                              </>
                            )}
                            
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                controlId="validationFormik102"
                                className="position-relative"
                              >
                                <Form.Label>Compensación</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="compensacion"
                                  value={formik.values.compensacion}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.compensacion &&
                                    !formik.errors.compensacion
                                  }
                                  isInvalid={
                                    !!formik.errors.compensacion &&
                                    formik.touched.compensacion
                                  }
                                  disabled={!reDecs }
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.compensacion}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                controlId="validationFormik102"
                                className="position-relative"
                              >
                                <Form.Label> Multas</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="multas"
                                  value={formik.values.multas}
                                  onChange={formik.handleChange}
                                  isValid={
                                    formik.touched.multas &&
                                    !formik.errors.multas
                                  }
                                  isInvalid={
                                    !!formik.errors.multas &&
                                    formik.touched.multas
                                  }
                                  disabled={!reDecs }
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                  {formik.errors.multas}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                          </Col>
                        </Box>
                      ) : (
                        activeStep === 1 && (
                          <FormPagos
                            metodoPago={metodoPago}
                            
                            setMetodoPago={setMetodoPago}
                            generarPresupuesto={() => {
                              //GenerarLigaPago("presupuesto")
                              setTipoAccion(tipo.PRESUPUESTO);
                              calcularServicioSei();
                            }}
                            continuarPago={setContinuarPago}
                          />

                        )
                      )}
                    </React.Fragment>
                  )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep !== 0 && (
                    <ButtonB
                      variant="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Anterior
                    </ButtonB>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === steps.length - 1 && (
                    <ButtonB
                      onClick={() => {  
                        setTipoAccion(tipo.PRESUPUESTO);
                        setLoadingPago(true);
                        calcularServicioSei(true);
                        
                      }}
                      variant="secondary"
                      className="mr-3 ml-3"
                    >
                      Descargar Presupuesto
                    </ButtonB>
                  )}
                  <ButtonB  onClick={async () => {
                      if (activeStep === steps.length - 1) {
                        /*setTipoAccion(tipo.PRESUPUESTO)
                        calcularServicioSei(true);
                        return;*/
                        if (metodoPago === "pago1") {
                          setTipoAccion(tipo.PAGO);
                          setLoadingPago(true);
                          //generarTransaccion(tipo.PAGO)
                        } else {
                          setTipoAccion(tipo.REFERENCIA);
                          setLoadingPago(true);
                          //generarTransaccion(tipo.REFERENCIA)
                        }

                        calcularServicioSei(true);
                        return;
                      }
                      if (calculoData?.calculos?.total === 0) {
                        setAdvertenciaPago(true);
                        return;
                      }
                      if (presupuestoGenerado) {
                        await handleNext();
                        return;
                      }
                      
                    }}
                    variant="secondary"
                    disabled={
                     ( formik.values.metrosCuadradosSuelo === ''
                      && formik.values.metrosCubicosAgua === '' )
                      || (activeStep === steps.length - 1 && continuarPago === false)
                      || !presupuestoGenerado
                    }
                  >
                    {activeStep === steps.length - 1
                      ? metodoPago === "pago1"
                        ? "Continuar al pago"
                        : "Descargar referencia de pago"
                      : calculoData?.calculos?.total !== 0
                      ? "Siguiente"
                      : "Realizar pago"}
                  </ButtonB>
                </Box>
              </Form>
            </View>
          )}
        </View>
        {!pantallaExito && (
          <ResumenCard
            tipo="emision"
            ligaPagoMit={ligaPagoMit} idForm={idForm}
            activeStep = {activeStep}
            calculoImp={calcularServicioSei}
            desableCalc={!(formik.isValid === true && reDecs === true)}
            montoAnt={calcularSumaTotal()}
          />
        )}
        <ModalActions
          title={"Se ha generado la referencia de pago con éxito"}
          content={""}
          icon={"check"}
          show={pagoExitoModal}
          onHide={() => {
            setPagoExitoModal(false);
          }}
          buttonAction={() => {
            setPagoExitoModal(false);
          }}
        />
        <ModalActions
          title={"Hubo un problema"}
          content={"por favor, vuelve a intentarlo"}
          icon={"error"}
          desc={errorMessage}
          show={pagoFallidoModal}
          onHide={() => {
            setPagoFallidoModal(false);
            setErrorMessage("");
          }}
          buttonAction={() => {
            setPagoFallidoModal(false);
            setErrorMessage("");
          }}
        />
        <ModalActions
          title={"Advertencia"}
          tipo="descuento"
          content={
            <div style={{ textAlign: "left" }}>
              <div>
                <label>
                  Actualmente cuentas con un descuento para el periodo:
                  <br />
                </label>
                <label style={{ margin: "1rem 0 1rem 4rem" }}>
                  {nombresMeses[Number(formik.values.periodo) - 1]} del año{" "}
                  {formik.values.anno}, con el número de referencia:{" "}
                  {descuentoValor}.
                </label>
              </div>
              <div>
                Queremos recordarte que, si decides continuar con nuestros
                servicios, perderás este beneficio. Sin embargo, si deseas
                conservar tu descuento, te invitamos amablemente a realizar el
                pago por medio de:
                <ol style={{ margin: "1rem 0 1rem 2rem", listStyle: "unset" }}>
                  <li>Transferencia (SPEI)</li>
                  <li>Instituciones bancarias o Tiendas de conveniencia</li>
                </ol>
              </div>
              ¡Estamos aquí para ayudarte en lo que necesites!
            </div>
          }
          icon={"error"}
          show={descuento}
          onHide={() => {
            showDescuento(false);
          }}
          buttonAction={() => {
            showDescuento(false);
          }}
          buttonActionCancel={() => {
            showDescuento(false);
          }}
        />
        <ModalActions
          title={"Advertencia"}
          content={
            "Alcanzaste el limite de declaraciones permitidas en el periodo seleccionado."
          }
          icon={"error"}
          show={decLimiteAlcanzado}
          onHide={() => {
            setDecLimiteAlcanzado(false);
          }}
          buttonAction={() => {
            setDecLimiteAlcanzado(false);
          }}
        />
        <ModalActions
          title={"Advertencia"}
          content={
            "Solo se permite declarar de manera anual del 1 de enero al 17 de febrero del año en curso. Si no realizó la declaración dentro de la fecha, deberá de solicitar cambiar su régimen de pago en una oficina administrativa."
          }
          icon={"error"}
          show={candadoFechaAnual}
          onHide={() => {
            setCandadoFechaAnual(false);
          }}
          buttonAction={() => {
            setCandadoFechaAnual(false);
          }}
        />
        <ModalActions
          title={"Declaración sin saldo a pagar"}
          content={
            "No hay importe a pagar en esta declaración, puedes dar clic en Regresar para realizar alguna modificación, o clic en Confirmar para enviar la declaración actual."
          }
          icon={"error"}
          show={advertenciaPago}
          onHide={() => {
            setAdvertenciaPago(false);
          }}
          buttonAction={() => {
            setTipoAccion(tipo.RECIBO);
            calcularServicioSei(true);
            setAdvertenciaPago(false);
            setLoadingPago(true);
          }}
          buttonActionCancel={() => {
            setAdvertenciaPago(false);
          }}
          buttonText="Confirmar"
          cancelText="Regresar"
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingPago}
        >
          <h1>Esperando Respuesta</h1>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
}