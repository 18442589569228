import React, { useRef, useState, useEffect, useCallback } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useTheme } from '@aws-amplify/ui-react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";
import { isMobile } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import { Flex, Heading, Text } from '@aws-amplify/ui-react';
import OpcionesSelect from './OpcionesSelect';
import { Backdrop, CircularProgress } from '@mui/material';
const NAMEBUCKET = process.env.REACT_APP_NAMEBUCKETFACE;
const dictionary = {
    // use default strings for english
    en: null,
    es: {
        photosensitivyWarningHeadingText: 'Advertencia de fotosensibilidad',
        photosensitivyWarningBodyText:
            'Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.',
        goodFitCaptionText: 'Buen ajuste',
        tooFarCaptionText: 'Demasiado lejos',
        hintCenterFaceText: 'Centra tu cara',
        startScreenBeginCheckText: 'Comenzar a verificar',
    },
};
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif"
];

export const FormDatosIdentidad = (props) => {
    const navigate = useNavigate();
    const { datos, setChangeData, changeData } = props;
    const { tokens } = useTheme();
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = React.useState(false);
    const [loadingFirma, setLoadingFirma] = React.useState(false);
    const [editar, setEditar] = useState(datos.identidad !== null ? false : true);
    const [resultFace, setResultFace] = React.useState("");
    const [createLivenessApiData, setCreateLivenessApiData] = React.useState(null);
    const [language, setLanguage] = React.useState('es');
    const signaturePad = useRef(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const schema = yup.object().shape({
        /* fotografia: yup
            .mixed()
            .required("Este campo es requerido")
            .test('fileType', 'Solo se permiten imágenes', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                return SUPPORTED_FORMATS.includes(value.type);
            })
            .test('fileSize', 'El archivo no debe ser mayor a 10 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }

                const maxSize = 10 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }), */
        firma: yup.mixed().required("Este campo es requerido"),
    });
    const SubirImagen = (fileImage) => {
        console.log(fileImage)
        if (fileImage !== undefined) {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");

            const file = fileImage;

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow"
            };

            fetch(`https://41lvubsljg.execute-api.us-east-1.amazonaws.com/master/${createLivenessApiData}.jpg`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result)
                    if (result.s3_url !== undefined) {
                        CompararImagenes()
                    }
                })
                .catch((error) => console.error(error));
        }
    }

    const CompararImagenes = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "source_key": `face-liveness-sessions/${createLivenessApiData}/reference.jpg`,
            "target_key": `${createLivenessApiData}.jpg`
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://8daoedz1lj.execute-api.us-east-1.amazonaws.com/master/compareFaces", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.number_of_matches === 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Las rostros son diferentes, suba otra imagen",

                    });
                } else {
                    Swal.fire({
                        title: "Validado",
                        text: "Validacion correcta puede continuar con el tramite",
                        icon: "success"
                    });
                    setLoadingFirma(true)
                }

            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        const signature = signaturePad.current;
        if (datos.identidad === null) {

            if (!loadingFirma) {
                signature.off(); // Desactiva eventos

            } else {
                signature.on(); // Reactiva eventos
            }
        }
    }, [loadingFirma]);


    const handleSave = async (setFieldValue) => {
        const signature = signaturePad.current;
        if (signaturePad.current) {
            const dataUrl = signaturePad.current.toDataURL(); // Obtén la firma como data URL
            // Convierte la cadena base64 en un Blob
            const base64Response = await fetch(dataUrl);
            const blob = await base64Response.blob();
            const formData = new FormData();
            formData.append('file', blob, 'Firma.jpg');
            const fileValue = formData.get('file');
            console.log(fileValue)
            setFieldValue("firma", fileValue)
            signature.off();
            toast.success("Firma Guardada Correctamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const theme = {
        name: 'Face Liveness Example Theme',
        tokens: {
            colors: {
                background: {
                    primary: {
                        value: tokens.colors.neutral['90'].value,
                    },
                    secondary: {
                        value: tokens.colors.neutral['100'].value,
                    },
                },
                font: {
                    primary: {
                        value: tokens.colors.white.value,
                    },
                },
                brand: {
                    primary: {
                        '10': tokens.colors.teal['100'],
                        '80': tokens.colors.teal['40'],
                        '90': tokens.colors.teal['20'],
                        '100': tokens.colors.teal['10'],
                    },
                },
            },
        },
    };

    const fetchCreateLiveness = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://8daoedz1lj.execute-api.us-east-1.amazonaws.com/master/createSession", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                setCreateLivenessApiData(result.SessionId);
                setLoading(true);

            })
            .catch((error) => console.error(error));


    };



    const handleAnalysisComplete = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "sessionId": createLivenessApiData
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://8daoedz1lj.execute-api.us-east-1.amazonaws.com/master/sessionResult", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (parseInt(result.Confidence) > 70) {
                    setResultFace(result)
                    setLoading(false);
                    setLoadingFirma(true)
                } else {
                    Swal.fire({
                        text: " Error al realizar el reconocimiento",
                        icon: "error",
                        confirmButtonColor: "#607472",
                        confirmButtonText: "Intentarlo de nuevo",
                        showDenyButton: true,
                        denyButtonText: "Cancelar"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            fetchCreateLiveness()
                        } else if (result.isDenied) {
                            setLoading(false);
                        }
                    });
                }
            })
            .catch((error) => console.error(error));

    };

    const handleSubirArchivos = async (values) => {
        setLoadingSave(true)
        var myHeaders = new Headers();
        const arrayArchivos = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const file = values[key];

                var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: file,
                    redirect: "follow",
                };
                await fetch(
                    `https://h1xthh0vqj.execute-api.us-east-1.amazonaws.com/master/${key}-${datos.id}.jpg`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {

                        console.log(result);
                        const nuevoValor = { name: key, url: result.s3_url };
                        arrayArchivos.push(nuevoValor);
                    })
                    .catch((error) => {console.log("error", error)
                        setLoadingSave(false)
                    });
            }
        }
        const objetoTransformado = arrayArchivos.reduce((acc, archivo) => {
            acc[archivo.name] = archivo.url;
            return acc;
        }, {});
        console.log(arrayArchivos)
        SubirDocumento(objetoTransformado);
    };
    const SubirDocumento = async (arrayArchivos) => {
        console.log(arrayArchivos)
     
        const user = await getCurrentUser();
        const data = {
            "id": datos.id,
            "procedure": datos.procedure,
            "type": datos.type,
            "usuarioID": user.username,
            "identidad": {
                "fotografia": resultFace !== "" ? `${NAMEBUCKET}.s3.amazonaws.com/face-liveness-sessions/${createLivenessApiData}/reference.jpg` : datos.identidad.fotografia,
                "firma": arrayArchivos.firma
            },
            "verificacionIdentidad": resultFace
        }
        console.log(data);
        CrearIteamAPI(data).then(result => {
            
            if (result.ok) {
                toast.success("Datos Guardados Correctamente", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setChangeData(!changeData)
                setLoadingFirma(false);
                setEditar(false)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
            setLoadingSave(false)
        }).catch(error => {
            setLoadingSave(false)
            console.log(error)
        })
    }
    const CustomError = useCallback(() => {
        return (
            <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
            >
                <Flex
                    backgroundColor="white"
                    direction="column"
                    justifyContent="center"
                    padding="32px"
                >
                    <Heading color="black">{error?.state}</Heading>
                    <Text>{error?.error.message}</Text>
                    <Button onClick={() => fetchCreateLiveness()}>Reintentar</Button>
                </Flex>
            </Flex>
        );
    }, [error]);
    const handleError = (error) => {
        console.error("Error en la verificación de Liveness:", error);
        Swal.fire({
            text: " Ocurrió un error durante la verificación. Por favor, inténtalo de nuevo.",
            icon: "error",
            confirmButtonColor: "#607472",
            confirmButtonText: "Intentarlo de nuevo",
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                fetchCreateLiveness()
            } else if (result.isDenied) {
                setLoading(false);
            }
        });
    };
    return (
        <>


            <View className="ViewPrincipal">

                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            firma: datos.identidad !== null ? datos.identidad.firma : ""
                        }}
                        onSubmit={values => {
                            console.log(values)

                            if (datos.identidad === null) {
                                handleSubirArchivos(values);
                            } else if (datos.identidad !== null && datos.identidad.firma !== values.firma) {
                                handleSubirArchivos(values);
                            } else if (datos.identidad !== null && resultFace !== "") {
                                SubirDocumento(values);
                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>


                                <Row className='mb-3'>
                                    <Form.Label style={{ textAlign: "left" }}>Reconocimiento Facial*</Form.Label>
                                    {datos.identidad === null || editar ?

                                        loading === false ?
                                            <Button disabled={!editar} onClick={() => { fetchCreateLiveness() }} variant='light' style={{ backgroundColor: '#B0C7B8', color: "black" }}>INICIAR</Button>
                                            :
                                            <div style={{ width: isMobile ? "100%" : "60%", margin: "auto" }}>

                                                <FaceLivenessDetector
                                                    sessionId={createLivenessApiData}
                                                    region="us-east-1"
                                                    style={{ width: "80%" }}
                                                    key={createLivenessApiData}
                                                    onAnalysisComplete={handleAnalysisComplete}
                                                    onError={setError}
                                                    displayText={dictionary[language]}
                                                    components={{
                                                        ErrorView: CustomError,
                                                    }}
                                                />

                                            </div>
                                        :
                                        <div className='Fotografia' style={{ textAlign: "center" }}>

                                            <img src={datos.identidad.fotografia} alt='Fortografia' style={{ margin: "auto" }} />
                                        </div>
                                    }
                                </Row>
                                {/*                                 <Row className='mb-3'>
                                    <Form.Group as={Col} className="position-relative ">
                                        <Form.Label>Fotografía Facial*</Form.Label>
                                        <Form.Control
                                            required
                                            type="file"
                                            name="fotografia"
                                            accept=".jpg"
                                            disabled={loadingFirma || datos.identidad !== null ? true : false}
                                            onChange={(event) => {
                                                SubirImagen(
                                                    event.currentTarget.files[0]
                                                )
                                                setFieldValue(
                                                    "fotografia",
                                                    event.currentTarget.files[0]
                                                );
                                                

                                            }}
                                            isValid={touched.fotografia && !errors.fotografia}
                                            isInvalid={touched.fotografia && !!errors.fotografia}
                                        />

                                        <Form.Control.Feedback type="invalid" tooltip>

                                            {errors.fotografia}
                                        </Form.Control.Feedback>
                                        <Form.Text muted>
                                            Tamaño máximo 10 MB.
                                        </Form.Text>
                                    </Form.Group>
                                </Row> */}

                                <Form.Label style={{ textAlign: "left" }}>Firma*</Form.Label>
                                <div className='Firma'>
                                    {datos.identidad === null || editar ?
                                        <>
                                            <SignaturePad ref={signaturePad} />
                                        </>
                                        :
                                        <Row className='mb-3'>
                                            <div style={{ maxWidth: "100%", overflow: "hidden" , textAlign:"center"}}>


                                                <img src={datos.identidad.firma} alt='Firma' style={{ maxWidth: "100%", height: "auto", display: "block", margin:"auto" }} />
                                            </div>
                                        </Row>

                                    }

                                </div>
                                {editar === true &&

                                    <Row className='mt-3'>
                                        <Button onClick={() => signaturePad.current.clear()} disabled={!loadingFirma} variant='light' style={{ backgroundColor: '#B0C7B8', color: "black" }} className=' mb-1'>LIMPIAR FIRMA</Button>
                                        <Button onClick={() => handleSave(setFieldValue)} variant='light' disabled={!loadingFirma} style={{ backgroundColor: '#B0C7B8', color: "black" }} className=' mb-1'>GUARDAR FIRMA</Button>
                                    </Row>
                                }

                                {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                    <>
                                        <Row className='mb-3 mt-3'>
                                            <Form.Group as={Col} className='d-grid'>
                                                {editar === true && datos.identidad !== null ?

                                                    <Button variant='secondary' onClick={() => setEditar(false)} >CANCELAR</Button>
                                                    :
                                                    <Button variant='secondary' disabled={datos.identidad !== null ? false : true} onClick={() => {
                                                        setEditar(true)
                                                        setLoadingFirma(true)
                                                    }} >EDITAR</Button>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} className='d-grid'>

                                                <Button variant='secondary' disabled={!editar} type='submit' onClick={() => {
                                                    if (values.firma === "") {
                                                        toast.error("Favor de llenar todos lo campos", {
                                                            position: "top-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                            theme: "light",
                                                        });

                                                    }
                                                }}>GUARDAR</Button>
                                            </Form.Group>
                                        </Row>
                                    </>
                                    : null
                                }
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
