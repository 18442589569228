import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react'; // Importa el hook de autenticación de AWS Amplify
import { Authenticator, Heading, translations } from '@aws-amplify/ui-react';
import { UserProvider } from './UserContext';
import { I18n } from '@aws-amplify/core';
import logoDgo from './assets/DurGobEst.png'
import { TramiteDataGeneralProvider } from './TramiteContext';
import { Auth } from 'aws-amplify';
const dict = {
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
  },
  es: {
    'Sign In': 'Iniciar Sesion',
    'Sign Up': 'Regístrate',
    'Create Account': 'Crear Cuenta',
    "Password": "Contraseña",
    'Confirm Password': 'Confirmar Contraseña',
    'Enter your Password': 'Ingrese su Contraseña',
    'Please confirm your Password': 'Confirme su contraseña',
    'Enter your Email': 'Ingrese su correo',
    'Email': 'Correo',
    'Sign in': 'Iniciar Sesion',
    'Forgot your password?': '¿Olvidó su contraseña?',
    'Reset Password': 'Resetear Contraseña',
    'Enter your phone number': 'Ingrese su número de teléfono',
    'Send code': 'Enviar Código',
    'Back to Sign In': 'Regresar al inicio',
    'Code': 'Código',
    'Code *': 'Código',
    'New Password': 'Nueva Contraseña',
    'Submit': 'Cambiar',
    'Resend Code': 'Reenviar Código',
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage('es');


const formFields = {
  signIn: {
    username: {
      label: 'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
      type: "tel"
    },
    password: {
      label: "Contraseña",
      placeholder: "Ingrese su contraseña",


    }

  },

  forgotPassword: {
    username: {
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
     
    }
  },
  signUp: {
    name: {
      order: 1,
      label: "Nombre",
      placeholder: "Escriba su Nombre"
    },
    phone_number: {
      label: 'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
    },
  },

}

const components = {
  SignIn: {
    Header() {
      /*       const { tokens } = useTheme();
       */
      return (
        <Heading

          level={3}
          style={{ margin: "auto", textAlign: "center", padding: "10px" }}
        >
          <img src={logoDgo} alt='logoDgo' width={350} height={69} />

          <br />Portal de Pagos del Estado de Durango
        </Heading>
      );
    },
  },

}
const PrivateRoute = () => {

  return (

    <Authenticator className='AuthForm' formFields={formFields} components={components}
      loginMechanisms={["phone_number"]}
    >
      <UserProvider>
        <TramiteDataGeneralProvider>
          <Outlet />

        </TramiteDataGeneralProvider>
      </UserProvider>
    </Authenticator>
  )
    ;
};

export default PrivateRoute;
