import React, { useState } from 'react';
import logoDurango from "../assets/DurGobEst.png"
import iconoUsuario from "../assets/iconoUsuario.png"
import lupa from "../assets/Lupa.png"
import logo from "../assets/logoicon.png";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet";
//import ProfilesPage from './Profiles'; 
import { GrUserManager } from 'react-icons/gr';
import { Icon } from "@aws-amplify/ui-react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { signOut  as SIGNOUT} from 'aws-amplify/auth';
import { CloseButton } from 'react-bootstrap';
import { Menu, MenuItem, MenuButton } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import AlgoliaSearch from "./algoliaSearch";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Header.css';

 
const Header = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [visible, setVisible] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const handleCloseMenu = () => setVisibleM(false);
  const navigate = useNavigate();
  const REACT_APP_HOME = process.env.REACT_APP_HOME;
  // const VAR_HOME = "https://dev.pagos.durango.gob.mx/";
  const REACT_APP_TRAMITES = process.env.REACT_APP_TRAMITES;
  const REACT_APP_METODOS  = process.env.REACT_APP_METODOS ;
  const REACT_APP_PREGUNTAS = process.env.REACT_APP_PREGUNTAS;
  const [isSearchVisible, setSearchVisible] = useState(false); 

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };
  

  async function signOut() {
    try {
      await SIGNOUT();
      window.location.href = "/"
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <>
      <Helmet>
      <script
      src='//fw-cdn.com/10891393/3657310.js'
      chat='true'>
      </script>
      </Helmet>
      <Navbar expand={"md"} className=" mb-0">
        <Container fluid>
          <Navbar.Toggle
            className='bg-transparent'
            aria-controls={`offcanvasNavbar-expand-md}`} style={{border:'unset', margin:'0 10px'}}/>
          <Navbar.Brand href={REACT_APP_HOME} id="logo-durango">
            <img className='bg-transparent' src={logoDurango} alt="Logo-Durango" placeholder='transparent'/>{" "}
          </Navbar.Brand>

          <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          className="bg-[#D4E0E0]"
          >
            <Offcanvas.Header closeButton className='headerMenu'>
              <Offcanvas.Title  id={`offcanvasNavbarLabel-expand-md`}>
                <div>
                  <img
                    src={logoDurango}
                    alt="Logo-Durango"
                    className="logo-durango"
                  />
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className='bg-[#D4E0E0]'>
              <Nav className="justify-content-end flex-grow-1 ">
                <Nav.Link
                  href={REACT_APP_HOME}
                  id="NavLink"
                  rel="noopener noreferrer"
                >
                  Inicio
                </Nav.Link>
             {/*    <Nav.Link
                  href={REACT_APP_TRAMITES}
                  rel="noopener noreferrer"
                  id="NavLink"
                >
                  Trámites y Servicios
                </Nav.Link> */}
                <Nav.Link
                  href={REACT_APP_METODOS}
                  rel="noopener noreferrer"
                  id="NavLink"
                >
                  Métodos de Pago
                </Nav.Link>
                <Nav.Link
                  href={REACT_APP_PREGUNTAS}
                  rel="noopener noreferrer"
                  id="NavLink"
                >
                  Ayuda
                </Nav.Link>
                <Nav.Link
                  href="/tramites-servicios"
                  rel="noopener noreferrer"
                  id="NavLink"
                >
                  Historial de Pagos y Trámites
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>

          </Navbar.Offcanvas>
          {/* <button id="icono-buscador"  onClick={toggleSearch}>
            <img 
              src={lupa}
              placeholder="transparent"
              className='bg-transparent'
              alt="Durango"
            />{" "}
          </button> */}
          <Menu
            menuAlign="end"
            trigger={
              <MenuButton
                
                color="white"
                backgroundColor="#607472"
                width="110px"
                height="40px"
                variation="link"
                borderRadius="20px"
                padding="4px8px"
                fontSize="16px"
                margin="0 1rem"
                id="usuario-navbar"
              >
                Usuario
              </MenuButton>
      
            }
          >
            
            {/*             <MenuItem className='MenuItemUser' onClick={() => setVisible(true)}>Perfiles</MenuItem>
             */}{" "}
             {/* <MenuItem
              className="MenuItemUser"
              onClick={() => navigate("/tramites-servicios")}
            >
              Historial de Pagos y Trámites
            </MenuItem>
             <MenuItem
              className="MenuItemUser"
              onClick={() => navigate("/Configuracion")}
            >
              Configuración
            </MenuItem> */}
            <MenuItem
              className="MenuItemUser"
              onClick={() => {
                user ? signOut() : navigate("/login");
              }}
            >
              Cerrar Sesión
            </MenuItem>
          </Menu>

          {/* botón usuario para versión movil */}
          <Menu
            menuAlign="end"
            trigger={
            <MenuButton id="logo-usuario">
              <img src={iconoUsuario} alt="Logo-usuario" placeholder='transparent' width={31}/> 
            </MenuButton>             
            }>
             {/* <MenuItem
              className="MenuItemUser"
              onClick={() => navigate("/tramites-servicios")}
            >
             Historial de Pagos y Trámites
            </MenuItem> */}
             {/*<MenuItem
              className="MenuItemUser"
              onClick={() => navigate("/Configuracion")}
            >
              Configuración
            </MenuItem> */}
            <MenuItem
              className="MenuItemUser"
              onClick={() => {
                user ? signOut() : navigate("/login");
              }}
            >
              Cerrar Sesión
            </MenuItem>
          </Menu>
          

          {/*  <NavDropdown
                   title={<span><Icon as={GrUserManager} /></span>}
                    id={`offcanvasNavbarDropdown-expand-md`}
                    drop='down'
                    style={{textAlign:"center"}}
                    
                  >
                    <NavDropdown.Item onClick={() => setVisible(true)}>Perfiles</NavDropdown.Item>
                    <NavDropdown.Item  onClick={() => window.location.href = "/CreateForm"}>
                      Formulario
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => { user ? signOut() : window.location.href = "/login" }}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown> */}
          {/* <ProfilesPage visible={visible} setVisible={setVisible} /> */}
        </Container>
      </Navbar>
      {/* {isSearchVisible && (
        <AlgoliaSearch list={true} toggleSearch={toggleSearch} />
      )} */}
    </>
  );
}

export default Header;

export const Head = () => (
  <>
    <link rel="icon" href={logo} />
  </>
);