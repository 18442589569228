import { useState, useEffect } from 'react';
import { View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, TablePagination } from '@mui/material';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FaArrowLeft } from "react-icons/fa";
import { Backdrop, CircularProgress } from '@mui/material';
import { TfiReload } from "react-icons/tfi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { getCurrentUser } from 'aws-amplify/auth';
import { useTramiteDataGeneral } from '../../TramiteContext';
import ObtenerDescripciones from './ObtenerDescripciones';
import { FaMotorcycle } from "react-icons/fa6";
/* const datosTramite = {
  "items": [
      {
          "TIPO": "PARTICULAR",
          "CATEGORIA": "LICENCIAS",
          "TIPO_SERVICIO": "PARTICULAR",
          "VIGENCIA": 3,
          "LEYENDA": "AUTOMOVILISTA",
          "ID": 1,
          "LETRA": "A",
          "SUBLEYENDA": "AUTO-CAMIONETA",
          "DESCRIPCION": "LICENCIA AUTOMOVILISTA"
      },
      {
          "TIPO": "PARTICULAR",
          "CATEGORIA": "LICENCIAS",
          "TIPO_SERVICIO": "PARTICULAR",
          "VIGENCIA": 3,
          "LEYENDA": "MOTOCICLISTA",
          "ID": 2,
          "LETRA": "D",
          "SUBLEYENDA": "",
          "DESCRIPCION": "LICENCIA MOTOCICLISTA"
      }
  ]
} */
export const HistorialLicencias = () => {
  const navigate = useNavigate();
  const [datos, setDatos] = useState("");
  const [loading, setLoading]= useState(false);
  const { saveJsonToLocalStorage } = useTramiteDataGeneral();
  const [datosTramite,setDatosTramite]= useState(null)
  const [reload,setReload]= useState(true);
  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (event) => {
    setSelectedOption(event.target.value); // Actualiza el estado con la opción seleccionada
  };
  const handleEditar=(data)=>{
    saveJsonToLocalStorage("DatosLicencias", data)
    navigate("/tramite/DPC-Licencias/DatosGenerales")
  }
  useEffect(()=>{
    const ObtenerDatosSelect = async ()=> {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
          "categoria": "LICENCIAS"
      });
  
      const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
      };
  
      try {
          const response = await fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/catalog/queryCategoryItems", requestOptions);
          const result = await response.json();
          console.log(result)
          if (Array.isArray(result.items)) {
              setDatosTramite( result);
          } else {
              console.error("result.items is not an array:", result.items);
              return [];
          }
      } catch (error) {
          console.error(error);
          return [];
      }
    };
    ObtenerDatosSelect()

  },[])
  function buscarPorValor( valorBuscado) {
    // Encuentra el objeto en el array que coincide con el valorBuscado
    if(datosTramite !== null){

      const objetoEncontrado = datosTramite?.items.find(item => item.ID === valorBuscado);
      
      // Devuelve el valor específico del campoADevolver si el objeto fue encontrado
      return objetoEncontrado ? objetoEncontrado.DESCRIPCION : null;
    }
    
  }
  useEffect(() => {
    const obtenerData = async () => {
      setLoading(true)
      const user = await getCurrentUser();
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "usuarioID": user.username
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/procedures/list", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.items.sort(function (a, b) {
            return new Date(b.creationDate) - new Date(a.creationDate)
        });;
          console.log(result)
          setLoading(false)
          setDatos(result.items)
        })
        .catch((error) => {
          setLoading(false)
          
          console.error(error)});
    }
    obtenerData()
  }, [reload])

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filters, setFilters] = useState({
    ticketID:"",
    estatus: '',
    type: '',
    procedure: '',
    nombre: '',
    primerApellido: '',
    segundoApellido: '',
    // Agrega más campos según tus necesidades
  });

  if (!datos || !datos) {
    // Manejo de casos donde info o info.Items es undefined
    return null; // o algún otro manejo de error que prefieras
  }

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
    setCurrentPage(1);
  };
  const filteredData = datos.filter(item => {
    return (
      (item?.estatus.includes(filters?.estatus) || !filters.estatus) &&
      (
        (item?.nombre?.toUpperCase().includes(filters?.nombre) ||
         item?.primerApellido?.toUpperCase().includes(filters?.nombre) ||
         item?.segundoApellido?.toUpperCase().includes(filters?.nombre))
        || !filters.nombre
      ) &&
      (item?.type.toString().includes(filters?.type?.toString()) || !filters.type) &&
      (item?.procedure.toString().includes(filters?.procedure?.toString()) || !filters.procedure) &&
      (item?.ticketID?.toString().includes(filters?.ticketID?.toString()) || !filters.ticketID)
    );
  });
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const RenderTable = (items) => {

 
    const handleChange = (event, value) => {
      setCurrentPage(value);
    };
   /*  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }; */

    return (
      <View className="ViewPrincipal">
        <View className="ViewContent">
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>

            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-center">No.</TableCell>
                    <TableCell className="text-center">ID de Registro</TableCell>
                    <TableCell className="text-center">Nombre</TableCell>
                    <TableCell className="text-center">Tipo</TableCell>
                    <TableCell align="center">Tipo de Trámite</TableCell>
                    <TableCell align="center">Estatus</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderRows(currentItems, indexOfFirstItem)}</TableBody>
              </Table>


            </TableContainer>
            {filteredData.length > itemsPerPage && (
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChange}
              />
            </Stack>
          )}
          </Paper>
        </View>
      </View>
    );
  };
  const renderRows = (items, indexOfFirstItem) => {
    console.log(items)
    return items.map((data, index) => {

      return (
        <TableRow key={data.id}>
          <TableCell className="text-center">{indexOfFirstItem+ index+1}</TableCell>
          <TableCell className="text-center">{data?.ticketID === undefined ? "": data.ticketID}</TableCell>
          <TableCell className="text-center">{`${data.nombre} ${data.primerApellido} ${data.segundoApellido === null ? "": data.segundoApellido}`}</TableCell>
          <TableCell className="text-center"><ObtenerDescripciones id={"LICENCIAS"} valor={"categoria"} campo={data.procedure} /></TableCell>
          <TableCell className="text-center"><ObtenerDescripciones id={16} campo={data.type} /></TableCell>
          <TableCell className="text-center">
            {data.estatus.toUpperCase()}
          </TableCell>
          <TableCell className="text-center">
            {data.estatus === "Aprobado" ? 
            <Button variant='link' onClick={()=> navigate("/tramite/DPC-Licencias/Pago", { state: data })}>PROCEDER AL PAGO</Button>
            : data.estatus === "Pagado"? 
            <Button variant='link'onClick={() => window.open(data.reciboPagoUrl, "_blank")} >Ver recibo</Button>
            :

            <Button variant='link' onClick={()=>handleEditar(data)}>{data.estatus === "Revisión" || data.estatus === "Bloqueado" ? "Ver Detalles" : "EDITAR"}</Button>
            }
          </TableCell>

        </TableRow >

      );
    });
  };
  return (
    <>
      <div className='HeaderTramite'>
        <View className='FormTitle'>Mis Trámites</View>
      </div>
      <View className="ViewPrincipal">
        <Row className='mb-3'>
          <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
            <button type="button" onClick={() => navigate("/tramite/DPC-Licencias")}
              className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
              <FaArrowLeft /> Volver al Inicio
            </button>
          </Form.Group>

          <Form.Group as={Col} md="5">
          </Form.Group>
          <Form.Group className='d-grid gap-2' as={Col} md="4">
            <InputGroup>
            <Form.Select onChange={handleChange}>
              <option value={1}>AUTOMOVILISTA</option>
              <option value={2}>MOTOCICLISTA</option>
            </Form.Select>
            <Button variant='secondary' onClick={() => navigate("/tramite/DPC-Licencias/Verificacion", {state:selectedOption})}>Crear Nueva Licencia</Button>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} md="2" className="mb-2">
              <Form.Control
                type="text"
                name="Folio"
                value={filters.ticketID}
                onChange={e => handleFilterChange('ticketID', e.target.value)}
                placeholder="Id de Registro"
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-2">
              <Form.Control
                type="text"
                value={filters.nombre.toUpperCase()}
                onChange={e => handleFilterChange('nombre', e.target.value.toUpperCase())}
                name="nombre"
                placeholder="Nombre y/o Apellido"
              />
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-2"> 
              <Form.Select
                type="text"
                onChange={e => handleFilterChange('procedure', e.target.value)}
                value={filters.procedure}
                name="procedure"
              >
                <option value="">Seleccionar Tipo</option>
                <option value="1">Automovilista</option>
                <option value="2">Motociclista</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-2"> 
              <Form.Select
                type="text"
                onChange={e => handleFilterChange('type', e.target.value)}
                value={filters.type}
              
              >
                <option value="">Seleccionar Tipo Tramite</option>
                <option value="232">Nueva</option>
                <option value="233">Refrendo</option>
                <option value="234">Reposicion</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-2"> 
              <Form.Select
                type="text"
                onChange={e => handleFilterChange('estatus', e.target.value)}
                value={filters.estatus}
                name="estatus"
              >
                <option value="">Seleccionar Estatus</option>
                <option value="Captura">Captura</option>
                <option value="Revisión">Revisión</option>
                <option value="Aprobado">Aprobado</option>
                <option value="Rechazado">Rechazado</option>
                <option value="Bloqueado">Bloqueado</option>
                <option value="Cancelado">Cancelado</option>
                <option value="Liosto para pago">Listo para pago</option>
                <option value="Pendiente de pago">Pendiente de pago</option>
                <option value="Pagado">Pagado</option>
                <option value="Lista para envio">Lista para envío</option>
                <option value="Enviado">Enviado</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}   style={{display:"flex", alignItems:"center", justifyContent:"right"}}>
              <Button variant="link" onClick={()=>setReload(!reload)} ><TfiReload /></Button>
            </Form.Group>
          </Row>
        <View className="ViewContent">
          {datos !== "" &&

            RenderTable(datos)
          }
        </View>
      </View>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <h1>Cargando Registros</h1>
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
