import React, {  useState } from 'react'
import { useTheme } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';

import { View, } from "@aws-amplify/ui-react";
import { Button as ButtonB } from 'react-bootstrap';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { toast, ToastContainer } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CompareFaces from "./CompareFaces"

const steps = ['Captura Face', 'Subir imagen'];
const dictionary = {
  // use default strings for english
  en: null,
  es: {
    photosensitivyWarningHeadingText: 'Advertencia de fotosensibilidad',
    photosensitivyWarningBodyText:
      'Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.',
    goodFitCaptionText: 'Buen ajuste',
    tooFarCaptionText: 'Demasiado lejos',
    hintCenterFaceText: 'Centra tu cara',
    startScreenBeginCheckText: 'Comenzar a verificar',
  },
};
export default function PruebasLive() {
  const [language, setLanguage] = React.useState('es');
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [loading, setLoading] = React.useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] =React.useState(null);

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch("https://8daoedz1lj.execute-api.us-east-1.amazonaws.com/master/createSession", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          setCreateLivenessApiData(result.SessionId);
          setLoading(false);

        })
        .catch((error) => console.error(error));


    };

    fetchCreateLiveness();
  }, []);

  const handleAnalysisComplete = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "sessionId": createLivenessApiData
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://8daoedz1lj.execute-api.us-east-1.amazonaws.com/master/sessionResult", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (parseInt(result.Confidence) > 70) {
          handleNext()
        } 
      })
      .catch((error) => console.error(error));

  };
  const { tokens } = useTheme();
  const theme = {
    name: 'Face Liveness Example Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral['90'].value,
          },
          secondary: {
            value: tokens.colors.neutral['100'].value,
          },
        },
        font: {
          primary: {
            value: tokens.colors.white.value,
          },
        },
        brand: {
          primary: {
            '10': tokens.colors.teal['100'],
            '80': tokens.colors.teal['40'],
            '90': tokens.colors.teal['20'],
            '100': tokens.colors.teal['10'],
          },
        },
      },
    },
  };

  return (
    <>
      <div className='HeaderTramite'>
        <View className='FormTitle'>Prueba Face Liveness</View>
        <View className='FormSubTitle'></View>
        <View className='FormDescripcion'>
        </View>
      </div>

      <View className='ViewPrincipal'>
        {
          <View className='FormContent'>

            <Box sx={{ width: '100%' }} className="ViewActa">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};


                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel  {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <></>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <ThemeProvider theme={theme}>
                      {loading ? (
                        <Loader />
                      ) :  (
                        <FaceLivenessDetector
                          sessionId={createLivenessApiData}
                          region="us-east-1"
                          onAnalysisComplete={handleAnalysisComplete}
                          onError={(error) => {
                            console.error(error);
                          }}
                          displayText={dictionary[language]}
                        />

                      )}
                    </ThemeProvider>
                  ) : (
                    activeStep === 1 && (
                      <CompareFaces sessionId={createLivenessApiData} />
                    )
                  )}
                </React.Fragment>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {
                  activeStep === steps.length - 1 &&
                  <ButtonB
                    variant='secondary'
                    disabled={activeStep === 0}
                    onClick={handleBack}


                  >
                    Anterior
                  </ButtonB>
                }
                <Box sx={{ flex: '1 1 auto' }} />
                <ButtonB variant='secondary'
                  onClick={handleNext}
                  disabled={loading || (activeStep === steps.length - 1)}
                >
                  {activeStep !== steps.length - 1 ? "siguiente" : 'Terminar'}
                </ButtonB>
              </Box>
            </Box>



          </View>
        }
      </View >



      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}

      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}