import React, { useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
import { Backdrop, CircularProgress } from '@mui/material';
export const FormDatosMedicos = (props) => {
    const navigate = useNavigate();
    const { datos, setChangeData, changeData } = props;
    const [editar, setEditar] = useState(datos.mediaFiliacion !== null ? true : false);
    const [loadingSave, setLoadingSave] = useState(false);
    const schema = yup.object().shape({

        tipoSangre: yup.string().required("Este valor es Obligatorio"),
        estatura: yup.string().required("Este valor es Obligatorio"),
        complexion: yup.string().required("Este valor es Obligatorio"),
        colorOjos: yup.string().required("Este Campo es Obligatorio"),
        piel: yup.string().required("Este Campo es Obligatorio"),
        requiereLentes: yup.string().required("Este Campo es Obligatorio"),
        donadorOrganos: yup.string().required("Este valor es Obligatorio"),
    });



    return (
        <>


            <View className="ViewPrincipal">

                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{

                            tipoSangre: datos.mediaFiliacion !== null ? datos.mediaFiliacion.tipoSangre : "",
                            estatura: datos.mediaFiliacion !== null ? datos.mediaFiliacion.estatura : "",
                            complexion: datos.mediaFiliacion !== null ? datos.mediaFiliacion.complexion : "",
                            colorOjos: datos.mediaFiliacion !== null ? datos.mediaFiliacion.colorOjos : "",
                            piel: datos.mediaFiliacion !== null ? datos.mediaFiliacion.piel : "",
                            requiereLentes: datos.mediaFiliacion !== null ? datos.mediaFiliacion.requiereLentes : "",
                            donadorOrganos: datos.mediaFiliacion !== null ? datos.mediaFiliacion.donadorOrganos : "",
                        }}
                        onSubmit={async values => {
                            setLoadingSave(true)
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": datos.type,
                                "usuarioID": user.username,
                                "mediaFiliacion": {
                                    "tipoSangre": parseInt(values.tipoSangre),
                                    "estatura": parseInt(values.estatura),
                                    "complexion": parseInt(values.complexion),
                                    "colorOjos": parseInt(values.colorOjos),
                                    "piel": parseInt(values.piel),
                                    "requiereLentes": parseInt(values.requiereLentes),
                                    "donadorOrganos": parseInt(values.donadorOrganos),
                                    "isComplete": true,
                                },
                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                    setEditar(true)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                setLoadingSave(false)
                            }).catch(error => {
                                setLoadingSave(false)
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, resetForm }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>

                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Media Filiación</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Sangre*</Form.Label>
                                        <OpcionesSelect
                                            id={8}
                                            setFieldValue={setFieldValue}
                                            campo={values.tipoSangre}
                                            name={"tipoSangre"}
                                            isDisabled={editar}
                                            value={values.tipoSangre}
                                            onBlur={handleBlur}
                                            touched={touched.tipoSangre}
                                            errors={errors.tipoSangre}
                                        />
                                        {/*   <Form.Select
                                            name='tipoSangre'
                                            type='text'
                                            value={values.tipoSangre}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.tipoSangre && !errors.tipoSangre}
                                            isInvalid={touched.tipoSangre && !!errors.tipoSangre}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>


                                                :
                                                null
                                            }
                                            <OpcionesSelect id={8} setFieldValue={setFieldValue} campo={values.tipoSangre} name={"tipoSangre"}/>

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoSangre}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estatura*</Form.Label>
                                        <OpcionesSelect
                                            id={9}
                                            setFieldValue={setFieldValue}
                                            campo={values.estatura}
                                            name={"estatura"}
                                            isDisabled={editar}
                                            value={values.estatura}
                                            onBlur={handleBlur}
                                            touched={touched.estatura}
                                            errors={errors.estatura}
                                        />
                                        {/*  <Form.Select
                                            name='estatura'
                                            type='text'
                                            placeholder='Estatura'
                                            value={values.estatura}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.estatura && !errors.estatura}
                                            isInvalid={touched.estatura && !!errors.estatura}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={9} setFieldValue={setFieldValue} campo={values.estatura} name={"estatura"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estatura}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Complexion*</Form.Label>
                                        <OpcionesSelect
                                            id={10}
                                            setFieldValue={setFieldValue}
                                            campo={values.complexion}
                                            name={"complexion"}
                                            isDisabled={editar}
                                            value={values.complexion}
                                            onBlur={handleBlur}
                                            touched={touched.complexion}
                                            errors={errors.complexion}
                                        />
                                        {/*   <Form.Select
                                            name='complexion'
                                            type='text'
                                            placeholder='Complexion'
                                            value={values.complexion}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.complexion && !errors.complexion}
                                            isInvalid={touched.complexion && !!errors.complexion}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>

                                                :
                                                null
                                            }
                                            <OpcionesSelect id={10} setFieldValue={setFieldValue} campo={values.complexion} name={"complexion"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.complexion}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Color de Ojos*</Form.Label>
                                        <OpcionesSelect
                                            id={11}
                                            setFieldValue={setFieldValue}
                                            campo={values.colorOjos}
                                            name={"colorOjos"}
                                            isDisabled={editar}
                                            value={values.colorOjos}
                                            onBlur={handleBlur}
                                            touched={touched.colorOjos}
                                            errors={errors.colorOjos}
                                        />
                                        {/*  <Form.Select
                                            name='colorOjos'
                                            type='text'
                                            placeholder='Color de Ojos'
                                            value={values.colorOjos}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.colorOjos && !errors.colorOjos}
                                            isInvalid={touched.colorOjos && !!errors.colorOjos}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={11} setFieldValue={setFieldValue} campo={values.colorOjos} name={"colorOjos"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.colorOjos}</Form.Control.Feedback> */}
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Color de Piel*</Form.Label>
                                        <OpcionesSelect
                                            id={12}
                                            setFieldValue={setFieldValue}
                                            campo={values.piel}
                                            name={"piel"}
                                            isDisabled={editar}
                                            value={values.piel}
                                            onBlur={handleBlur}
                                            touched={touched.piel}
                                            errors={errors.piel}
                                        />
                                        {/*  <Form.Select
                                            name='piel'
                                            type='text'
                                            value={values.piel}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.piel && !errors.piel}
                                            isInvalid={touched.piel && !!errors.piel}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>
                                                :
                                                null}
                                            <OpcionesSelect id={12} setFieldValue={setFieldValue} campo={values.piel} name={"piel"} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.piel}</Form.Control.Feedback> */}
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Requiere Lentes*</Form.Label>
                                        <OpcionesSelect
                                            id={21}
                                            setFieldValue={setFieldValue}
                                            campo={values.requiereLentes}
                                            name={"requiereLentes"}
                                            isDisabled={editar}
                                            value={values.requiereLentes}
                                            onBlur={handleBlur}
                                            touched={touched.requiereLentes}
                                            errors={errors.requiereLentes}
                                        />
                                        {/*   <Form.Select
                                            name='requiereLentes'
                                            type='text'
                                            value={values.requiereLentes}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.requiereLentes && !errors.requiereLentes}
                                            isInvalid={touched.requiereLentes && !!errors.requiereLentes}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                <>
                                                    <option value="">SELECCIONA</option>
                                                </>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={21} setFieldValue={setFieldValue} campo={values.requiereLentes} name={"requiereLentes"}/>

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.requiereLentes}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Donador de Organos*</Form.Label>
                                        <OpcionesSelect
                                            id={22}
                                            setFieldValue={setFieldValue}
                                            campo={values.donadorOrganos}
                                            name={"donadorOrganos"}
                                            isDisabled={editar}
                                            value={values.donadorOrganos}
                                            onBlur={handleBlur}
                                            touched={touched.donadorOrganos}
                                            errors={errors.donadorOrganos}
                                        />
                                        {/*   <Form.Select
                                            name='donadorOrganos'
                                            type='text'
                                            value={values.donadorOrganos}
                                            onChange={handleChange}
                                            disabled={editar}
                                            isValid={touched.donadorOrganos && !errors.donadorOrganos}
                                            isInvalid={touched.donadorOrganos && !!errors.donadorOrganos}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                    <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={22} setFieldValue={setFieldValue} campo={values.donadorOrganos} name={"donadorOrganos"}/>

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.donadorOrganos}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                    <>
                                        {datos.type === 233 || datos.type === 234 ? null :

                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='d-grid'>
                                                    {editar === false && datos.mediaFiliacion !== null ?

                                                        <Button variant='secondary' onClick={() => {
                                                            setEditar(true)
                                                            resetForm()
                                                        }} >CANCELAR</Button>
                                                        :
                                                        <Button variant='secondary' disabled={datos.mediaFiliacion !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} className='d-grid'>

                                                    <Button variant='secondary' disabled={editar} type='submit'>GUARDAR</Button>
                                                </Form.Group>
                                            </Row>
                                        }
                                    </>
                                    : null
                                }
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
