import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom'
import { CrearIteamAPI } from "../../api/Licencias"
import { toast, ToastContainer } from 'react-toastify';
import { getCurrentUser } from 'aws-amplify/auth';
import { useTramiteDataGeneral } from '../../TramiteContext';
import OpcionesSelect from './OpcionesSelect';
import { Backdrop, CircularProgress } from '@mui/material';
import Swal from "sweetalert2";
export const FormVerificacionResi = (props) => {
    const { handleBack, datos, info, infoContr } = props;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { saveJsonToLocalStorage } = useTramiteDataGeneral();
    console.log(infoContr)
    console.log(info)
    const schema = yup.object().shape({
        residencia: yup.string().required("Este valor es Obligatorio"),
        paisResidencia: yup.string().required("Este valor es Obligatorio"),
        estadoResidencia: yup.string().when('residencia', (residencia, schema) => {
            return residencia === "1"
                ? schema.required('Este valor es obligatorio')
                : schema;
        }),
    });


    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "curp") {
            values = values.replace(/[^A-Z0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z\s]/g, '');

            setFieldValue(name, values);
        }
    };
    const CrearIteam = async (values) => {
        setLoading(true)
        console.log(values);
        const user = await getCurrentUser();
        let data = "";
        if (info.tipoTramite === "233") {
            data = {
                "procedure": datos,
                "type": info.tipoTramite,
                "usuarioID": user.username,
                "nombre": infoContr.persona.nombre,
                "primerApellido": infoContr.persona.apellido1,
                "segundoApellido": infoContr.persona.apellido2 === "" ? null : infoContr.persona.apellido2,
                "curp": infoContr.persona.curp,
                "pais": parseInt(values.paisResidencia),
                "personaId": infoContr.persona.personaId,
                "numeroLicencia": info.numLicencia,
                "estado": parseInt(values.estadoResidencia),
                "domicilioOrigen": values.residencia,
                "datosGenerales": {
                    "nombre": infoContr.persona.nombre,
                    "primerApellido": infoContr.persona.apellido1,
                    "segundoApellido": infoContr.persona.apellido2 === "" ? null : infoContr.persona.apellido2,
                    "curp": infoContr.persona.curp,
                    "sexo": infoContr.persona.sexoId,
                    "estadoCivil": infoContr.persona.estadoCivilId,
                    "nacionalidad": infoContr.persona.nacionalidadId,
                    "estadoNacimiento": infoContr.persona.estadoNacimientoId,
                    "fechaNacimiento": infoContr.persona.fechaNacimiento
                },
                "contactoEmergencia": {
                    "nombre": infoContr.emergencia.nombreEmergencia,
                    "domicilio": infoContr.emergencia.domicilioEmergencia,
                    "estado": infoContr.emergencia.estadoEmergencia,
                    "municipio": infoContr.emergencia.municipioEmergencia,
                    "telefono": infoContr.emergencia.telefonoEmergencia,
                    "isComplete": infoContr.emergencia.nombreEmergencia === null || infoContr.emergencia.domicilioEmergencia == null || infoContr.emergencia.estadoEmergencia === null || infoContr.emergencia.municipioEmergencia === null || infoContr.emergencia.telefonoEmergencia === null ? false : true,
                    "otpData": {
                        "verificado": true
                    }
                },
                "domicilioResidencia": {
                    "municipio": infoContr.domicilio.municipio,
                    "localidad": infoContr.domicilio.localidad,
                    "colonia": infoContr.domicilio.colonia,
                    "tipoCalle": infoContr.domicilio.tipoCalleId,
                    "calle": infoContr.domicilio.calle,
                    "numeroExterior": infoContr.domicilio.numExterior,
                    "letra": infoContr.domicilio.letra,
                    "pais": parseInt(values.paisResidencia),
                    "numeroInterior": infoContr.domicilio.numInterior,
                    "codigoPostal": infoContr.domicilio.cp,
                    "origenDomicilio": values.residencia,
                    "estado": parseInt(values.estadoResidencia),
                    "isComplete": false,

/*                     "isComplete":  infoContr.domicilio.municipio === null || infoContr.domicilio.localidad === null || infoContr.domicilio.colonia === null || infoContr.domicilio.tipoCalleId === null || infoContr.domicilio.calle === null || infoContr.domicilio.numExterior === null || infoContr.domicilio.cp === null ? false : true,
 */                },
                "datosContacto": {
                    "telefono": infoContr.contacto.telefono,
                    "correo": infoContr.contacto.correo,
                    "isComplete": infoContr.contacto.telefono === null || infoContr.contacto.correo === null ? false : true,
                    "otpData": {
                        "verificado": true
                    }
                },
                "mediaFiliacion": {
                    "tipoSangre": infoContr.mediafiliacion.tipoSangreId,
                    "estatura": infoContr.mediafiliacion.estaturaId,
                    "complexion": infoContr.mediafiliacion.complexionId,
                    "colorOjos": infoContr.mediafiliacion.colorOjosId,
                    "piel": infoContr.mediafiliacion.pielId,
                    "requiereLentes": infoContr.mediafiliacion.lentesId,
                    "donadorOrganos": infoContr.mediafiliacion.donadorOrganosId,
                    "isComplete": true
                },
                "identidadArchivo": {
                    "fotografia": infoContr.biometricos.foto,
                    "firma": infoContr.biometricos.firma
                }

            }

        } else if (info.tipoTramite === "234") {
            console.log("REPOSICION")
            data = {
                "procedure": datos,
                "type": info.tipoTramite,
                "usuarioID": user.username,
                "nombre": infoContr.persona.nombre,
                "primerApellido": infoContr.persona.apellido1,
                "segundoApellido": infoContr.persona.apellido2 === "" ? null : infoContr.persona.apellido2,
                "curp": infoContr.persona.curp,
                "personaId": infoContr.persona.personaId,
                "numeroLicencia": info.numLicencia,
                "pais": parseInt(values.paisResidencia),
                "estado": values.estadoResidencia !== "" ? parseInt(values.estadoResidencia) : "",
                "domicilioOrigen": values.residencia,
                "datosGenerales": {
                    "nombre": infoContr.persona.nombre,
                    "primerApellido": infoContr.persona.apellido1,
                    "segundoApellido": infoContr.persona.apellido2 === "" ? null : infoContr.persona.apellido2,
                    "curp": infoContr.persona.curp,
                    "sexo": infoContr.persona.sexoId,
                    "estadoCivil": infoContr.persona.estadoCivilId,
                    "nacionalidad": infoContr.persona.nacionalidadId,
                    "estadoNacimiento": infoContr.persona.estadoNacimientoId,
                    "fechaNacimiento": infoContr.persona.fechaNacimiento,
                    "isComplete": true

                },
                "contactoEmergencia": {
                    "nombre": infoContr.emergencia.nombreEmergencia,
                    "domicilio": infoContr.emergencia.domicilioEmergencia,
                    "estado": infoContr.emergencia.estadoEmergencia,
                    "municipio": infoContr.emergencia.municipioEmergencia,
                    "telefono": infoContr.emergencia.telefonoEmergencia,
                    "isComplete": true,
                    "otpData": {
                        "verificado": true
                    }
                },
                "domicilioResidencia": {
                    "municipio": infoContr.domicilio.municipio,
                    "localidad": infoContr.domicilio.localidad,
                    "colonia": infoContr.domicilio.colonia,
                    "tipoCalle": infoContr.domicilio.tipoCalleId,
                    "calle": infoContr.domicilio.calle,
                    "numeroExterior": infoContr.domicilio.numExterior,
                    "pais": parseInt(values.paisResidencia),
                    "letra": infoContr.domicilio.letra,
                    "numeroInterior": infoContr.domicilio.numInterior,
                    "codigoPostal": infoContr.domicilio.cp,
                    "estado": parseInt(values.estadoResidencia),
                    "origenDomicilio": parseInt(values.residencia),
                    "isComplete": true

                },
                "datosContacto": {
                    "telefono": infoContr.contacto.telefono,
                    "correo": infoContr.contacto.correo,
                    "isComplete": true,
                    "otpData": {
                        "verificado": true
                    }
                },
                "mediaFiliacion": {
                    "tipoSangre": infoContr.mediafiliacion.tipoSangreId,
                    "estatura": infoContr.mediafiliacion.estaturaId,
                    "complexion": infoContr.mediafiliacion.complexionId,
                    "colorOjos": infoContr.mediafiliacion.colorOjosId,
                    "piel": infoContr.mediafiliacion.pielId,
                    "requiereLentes": infoContr.mediafiliacion.lentesId,
                    "donadorOrganos": infoContr.mediafiliacion.donadorOrganosId,
                    "isComplete": true
                },
                "identidadArchivo": {
                    "fotografia": infoContr.biometricos.foto,
                    "firma": infoContr.biometricos.firma
                }
            }
        } else {
            if (info.tipoTramite === 232 && infoContr?.tipoLicenciaId !== undefined && datos !== infoContr?.tipoLicenciaId) {

                data = {
                    "procedure": datos,
                    "type": info.tipoTramite,
                    "usuarioID": user.username,
                    "nombre": infoContr.nombre,
                    "primerApellido": infoContr.apellido1,
                    "segundoApellido": infoContr.apellido2 === "" ? null : infoContr.apellido2,
                    "curp": infoContr.curp,
                    "personaId": infoContr.personaId,
                    "pais": parseInt(values.paisResidencia),
                    "estado": values.estadoResidencia !== "" ? parseInt(values.estadoResidencia) : "",
                    "domicilioOrigen": values.residencia,
                    "datosGenerales": {
                        "nombre": infoContr.nombre,
                        "primerApellido": infoContr.apellido1,
                        "segundoApellido": infoContr.apellido2 === "" ? null : infoContr.apellido2,
                        "curp": infoContr.curp,
                        "sexo": infoContr.sexoId,
                        "estadoCivil": infoContr.estadoCivilId,
                        "nacionalidad": infoContr.nacionalidadId,
                        "estadoNacimiento": values.estadoResidencia !== "" ? parseInt(values.estadoResidencia) : "",
                        "fechaNacimiento": infoContr.fechaNacimiento,
                        "origenDomicilio": values.residencia,
                        "isComplete": true

                    },
                }
            } else {

                data = {
                    "procedure": datos,
                    "type": info.tipoTramite,
                    "usuarioID": user.username,
                    "nombre": infoContr.nombre,
                    "personaId": null,
                    "primerApellido": infoContr.primerApellido,
                    "segundoApellido": infoContr.segundoApellido === "" ? null : infoContr.segundoApellido,
                    "curp": infoContr.curp,
                    "pais": parseInt(values.paisResidencia),
                    "estado": values.estadoResidencia !== "" ? parseInt(values.estadoResidencia) : null,
                    "domicilioOrigen": values.residencia
                }
            }

        }
        console.log(data)
        try {
            const result = await CrearIteamAPI(data); // Llamada a la API
            console.log(result);

            if (result.ok) {
                saveJsonToLocalStorage("DatosLicencias", result.message.item);
                navigate("/tramite/DPC-Licencias/DatosGenerales");
            } else {
                toast.error(result.message, { /* opciones del toast */ });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Asegura que se detenga siempre
        }
    }
    return (
        <>
            <View className="">
                <Formik
                    enableReinitialize={true}
                    validationSchema={schema}
                    initialValues={{
                        residencia: infoContr?.persona?.nacionalidadId === 426 || infoContr.nacionalidadId === 426 ? "1" : "",
                        paisResidencia: infoContr?.persona?.nacionalidadId === 426 || infoContr.nacionalidadId === 426 ? 159 : "",
                        estadoResidencia: info.tipoTramite === "234" ? 320 : "",
                    }}
                    onSubmit={async (values) => {
                        console.log(values)
                        console.log(info.tipoTramite)

                        if (info.tipoTramite === 232) {
                            Swal.fire({
                                text: "Por el momento este trámite en línea no se encuentra disponible, por favor acude al módulo de atención más cercano. ",
                                icon: "error",
                                confirmButtonColor: "#607472",
                                confirmButtonText: "Aceptar"
                            }).then((result) => {
                                if (result.isConfirmed) {

                                }
                            });

                        } else {

                            if (values.residencia === "1" && values.estadoResidencia === 320 && info.tipoTramite === 232) {
                                Swal.fire({
                                    text: "Por el momento este trámite en línea no se encuentra disponible para tu estado, por favor acude al módulo de atención más cercano. ",
                                    icon: "error",
                                    confirmButtonColor: "#607472",
                                    confirmButtonText: "Aceptar"
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                    }
                                });

                            } else if (values.residencia === "2" && values.paisResidencia === 159) {
                                Swal.fire({
                                    text: "No puedes seleccionar México. ",
                                    icon: "error",
                                    confirmButtonColor: "#607472",
                                    confirmButtonText: "Aceptar"
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                    }
                                });


                            } else {
                                if (values.residencia === "1" && values.estadoResidencia === "") {

                                    toast.error("Seleccione un estado de residencia", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                } else {
                                    CrearIteam(values);
                                }

                            }
                        }

                    }}
                >
                    {({ handleSubmit, handleChange, values, touched, errors, setFieldTouched, setFieldValue, handleBlur }) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            if (values.residencia === "1") {
                                setFieldValue("paisResidencia", 159)
                                /* if (info.tipoTramite === "233" || info.tipoTramite === "234") {
                                    setFieldValue("estadoResidencia", "320")

                                } */
                            }
                        }, [values.residencia])
                        return (

                            <Form className='mt-3 ViewActa' onSubmit={handleSubmit}>
                                <Row className='mb-3s'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Datos Requeridos</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Residencia*</Form.Label>
                                        <Form.Select
                                            name='residencia'
                                            type='text'
                                            disabled={info.tipoTramite === "234" ? true : false}
                                            value={values.residencia}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const selectedResidencia = e.target.value;
                                                if (selectedResidencia !== values.residencia) {
                                                    setFieldValue('paisResidencia', '');
                                                    setFieldValue('estadoResidencia', '');
                                                }
                                            }}
                                            isValid={touched.residencia && !errors.residencia}
                                            isInvalid={touched.residencia && !!errors.residencia}
                                        >
                                            <option value="">SELECCIONA</option>
                                            <option value="1">NACIONAL</option>
                                            <option value="2">EXTRANJERO</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>
                                            {errors.residencia}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Pais de Residencia*</Form.Label>
                                        <OpcionesSelect
                                            id={"PAISES"}
                                            setFieldValue={setFieldValue}
                                            campo={values.paisResidencia}
                                            name={"paisResidencia"}
                                            isDisabled={values.residencia === "1" ? true : false}
                                            valor={"categoria"}
                                            value={values.paisResidencia}
                                            onBlur={handleBlur}
                                            touched={touched.paisResidencia}
                                            errors={errors.paisResidencia}
                                        />
                                        {/*    <Form.Select
                                            name='paisResidencia'
                                            type='text'
                                            value={values.paisResidencia}
                                            onChange={handleChange}
                                            disabled={values.residencia === "1" ? true : false}
                                            isValid={touched.paisResidencia && !errors.paisResidencia}
                                            isInvalid={touched.paisResidencia && !!errors.paisResidencia}
                                        >
                                            <option value="">SELECCIONA</option>

                                            <OpcionesSelect id={"PAISES"} valor={"categoria"} setFieldValue={setFieldValue} campo={values.estadoResidencia} name={"estadoResidencia"} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.paisResidencia}</Form.Control.Feedback> */}
                                    </Form.Group>
                                </Row>
                                {values.residencia === "1" &&
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado de Residencia*</Form.Label>
                                                <OpcionesSelect
                                                    id={19}
                                                    setFieldValue={setFieldValue}
                                                    campo={values.estadoResidencia}
                                                    name={"estadoResidencia"}
                                                    isDisabled={info.tipoTramite === "234" ? true : false}
                                                    value={values.estadoResidencia}
                                                    onBlur={handleBlur}
                                                    required
                                                    touched={touched.estadoResidencia}
                                                    errors={errors.estadoResidencia}
                                                />
                                                {/*   <Form.Select
                                                    name='estadoResidencia'
                                                    type='text'

                                                    value={values.estadoResidencia}
                                                    onChange={handleChange}
                                                    isValid={touched.estadoResidencia && !errors.estadoResidencia}
                                                    isInvalid={touched.estadoResidencia && !!errors.estadoResidencia}
                                                >
                                                    <option value="">SELECCIONA</option>
                                                    <OpcionesSelect id={19} setFieldValue={setFieldValue} campo={values.estadoResidencia} name={"estadoResidencia"} />

                                                </Form.Select> 
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estadoResidencia}</Form.Control.Feedback>
                                                */}
                                            </Form.Group>
                                        </Row>
                                    </>

                                }
                                <Row className='mb-3'>
                                    <Button variant='secondary' type='submit' disabled={loading}>SIGUIENTE</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link' onClick={() => handleBack()}>REGRESAR</Button>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
                <ToastContainer />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}

                >
                    <h1>Validando la Información</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </View>

        </>
    )
}