import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTramiteDataGeneral } from '../../TramiteContext';
import numeral from 'numeral';

const DeclaracionesAnt = ({ tipo = "general" , annio}) => {
    const { declaracion } = useTramiteDataGeneral()
    const [decData, setDecData] = useState({})
    const [show, setShow] = useState(false)
    const [montoAnteriorPag, setMontoAnteriorPag] = useState(0);
    const montoAnterior=(data)=>{
        
        let valor = 0;
        if(data === 0){
            setMontoAnteriorPag(valor)
        }else{
           Array(data).fill(undefined).map((_, index)=>(
            valor = valor + declaracion[index].total
            ))
            setMontoAnteriorPag(valor)
        }
    }
    function obtenerValorPorDescripcion(arr, descripcionBuscada) {
        if (!arr || arr.length <= 0) {
            return null
        }
        const elementoEncontrado = arr.find(item => item.descripcion === descripcionBuscada);

        if (elementoEncontrado) {
            return elementoEncontrado.valor;
        } else {
            return null; // O algún valor por defecto o un mensaje indicando que no se encontró
        }
    }

    const bodyDetails = () => {
        if (tipo === "general") {
            const baseimp = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BASE_IMPUESTO');
            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{numeral(baseimp).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto causado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento a la educación:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización hospedaje:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos hospedaje:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        } 
        if (tipo === "bienes") {
            const baseimp = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BASE_IMPUESTO');
            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{numeral(baseimp).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento educativo:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        } 
        if (tipo === "gases") {
            const bioxidoDeCarbono = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BIOXIDO_CARBONO');
            const metano = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'METANO');
            const oxidoNitroso = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'OXIDO_NITROSO');
            const GCn = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'CARBONO_NEGRO');
            const GHfc1 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CHF3');
            const GHfc2 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CHF2CF3');
            const GHfc3 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CH2FCF3');
            const GHfc4 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CH3CHF2');
            const GHfc5 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CF3CHFCF3');
            const GHfc6 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CF3CH2CF3');
            const GHfc7 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'HIDROFLUOROCARBONOS_CF3CHFCHFCF2CF3');
            const GPfc1 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PERFLUOROCARBONOS_CF4');
            const GPfc2 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PERFLUOROCARBONOS_C2F6');
            const GPfc3 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PERFLUOROCARBONOS_C4F10');
            const GPfc4 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PERFLUOROCARBONOS_C6F14');
            const GPfc5 = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PERFLUOROCARBONOS_SF6')

            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Toneladas de bióxido de carbono:</Col><Col className='flex justify-end'>{numeral(bioxidoDeCarbono).format('$0,0.00')}</Col></Row>
                    <Row><Col>Toneladas de metano:</Col><Col className='flex justify-end'>{numeral(metano).format('$0,0.00')}</Col></Row>
                    <Row><Col>Toneladas de óxido nitroso:</Col><Col className='flex justify-end'>{numeral(oxidoNitroso).format('$0,0.00')}</Col></Row>    
                    { annio >= 2024 && 
                    <Row><Col>Toneladas de carbón negro:</Col><Col className='flex justify-end'>{numeral(GCn).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 && 
                    <Row><Col>Toneladas de GHfc1:</Col><Col className='flex justify-end'>{numeral(GHfc1).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 && 
                    <Row><Col>Toneladas de GHfc2:</Col><Col className='flex justify-end'>{numeral(GHfc2).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 && 
                    <Row><Col>Toneladas de GHfc3:</Col><Col className='flex justify-end'>{numeral(GHfc3).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GHfc4:</Col><Col className='flex justify-end'>{numeral(GHfc4).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GHfc5:</Col><Col className='flex justify-end'>{numeral(GHfc5).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GHfc6:</Col><Col className='flex justify-end'>{numeral(GHfc6).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GHfc7:</Col><Col className='flex justify-end'>{numeral(GHfc7).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GPfc1:</Col><Col className='flex justify-end'>{numeral(GPfc1).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GPfc2:</Col><Col className='flex justify-end'>{numeral(GPfc2).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GPfc3:</Col><Col className='flex justify-end'>{numeral(GPfc3).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GPfc4:</Col><Col className='flex justify-end'>{numeral(GPfc4).format('$0,0.00')}</Col></Row>
                    }
                    { annio >= 2024 &&
                    <Row><Col>Toneladas de GPfc5:</Col><Col className='flex justify-end'>{numeral(GPfc5).format('$0,0.00')}</Col></Row>
                    }
                    <Row><Col>Impuesto causado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento educativo:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    {/* <Row><Col>Otros incentivos:</Col><Col className='flex justify-end'>{numeral(0).format('$0,0.00')}</Col></Row> */}
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        }
        
        if (tipo === "emision") {
            const Suelo = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'IMPORTE_SUELO');
            const Agua = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'IMPORTE_AGUA');
            
            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Suelo y Subsuelo:</Col><Col className='flex justify-end'>{numeral(Suelo).format('$0,0.00')}</Col></Row>
                    <Row><Col>Agua:</Col><Col className='flex justify-end'>{numeral(Agua).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento a la educación:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                    {/* {console.log("datos ant", decData)} */}
                </Modal.Body>
            )
        }
        if (tipo === "bienes") {
            const Base_impuesto = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BASE_IMPUESTO');
            
            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{parseInt(Number(Base_impuesto))}</Col></Row>
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento Educativo:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
{/*                     <Row><Col>Pagos Provisionales:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
   <Row><Col>Retenciones:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
 */}              
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto Anterior Pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        }
        if (tipo === "materiales") {
            
       
            const MGra = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'GRAVA');
            const MAre = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'ARENA');
            const MArl = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'ARCILLA');
            const MMeg = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'MAT_GREÑA');
            const MRoc = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'ROCAS');
            const MPsc = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PIEDRAS_SUSTRATO');
            const MPac = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'PIEDRAS_ADORNO');
            const MCan = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'CANTERA');
            const MTra = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'TRAVERTINOS');
            const MAgp = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'AG_PETREOS');
            const MClz = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'CALIZA');
            const MOtr = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'MAT_OTROS');
            const MAnd = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'ANDESITA');
            const MCao= obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'CAOLÍN');
            const MRio = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'RIOLITA');

            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    {/* <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{numeral(baseimp).format('$0,0.00')}</Col></Row> */}
                    <Row><Col>Grava:</Col><Col className='flex justify-end'>{numeral(MGra).format('$0,0.00')}</Col></Row>
                    <Row><Col>Arena:</Col><Col className='flex justify-end'>{numeral(MAre).format('$0,0.00')}</Col></Row>
                    <Row><Col>Arcilla y Limo:</Col><Col className='flex justify-end'>{numeral(MArl).format('$0,0.00')}</Col></Row>
                    { annio < 2024 &&
                    <Row><Col>Materiales en Greña:</Col><Col className='flex justify-end'>{numeral(MMeg).format('$0,0.00')}</Col></Row>
                    }
                    <Row><Col>Roca:</Col><Col className='flex justify-end'>{numeral(MRoc).format('$0,0.00')}</Col></Row>
                    <Row><Col>Piedras y sustrato o capa fértil:</Col><Col className='flex justify-end'>{numeral(MPsc).format('$0,0.00')}</Col></Row>
                    { annio < 2024 &&
                    <Row><Col>Piedras de construcción y de adorno de mármol, de granito y ónix:</Col><Col className='flex justify-end align-items-center'>{numeral(MPac).format('$0,0.00')}</Col></Row>
                    }
                    <Row><Col>Cantera:</Col><Col className='flex justify-end'>{numeral(MCan).format('$0,0.00')}</Col></Row>
                    <Row><Col>Travertinos:</Col><Col className='flex justify-end'>{numeral(MTra).format('$0,0.00')}</Col></Row>
                    <Row><Col>Agregados pétreos:</Col><Col className='flex justify-end'>{numeral(MAgp).format('$0,0.00')}</Col></Row>
                    <Row><Col>Caliza:</Col><Col className='flex justify-end'>{numeral(MClz).format('$0,0.00')}</Col></Row>
                    { annio < 2024 &&
                    <Row><Col>Otros:</Col><Col className='flex justify-end'>{numeral(MOtr).format('$0,0.00')}</Col></Row>
                    }
                    {  annio >= 2024 &&
                    <Row><Col>Andesita:</Col><Col className='flex justify-end'>{numeral(MAnd).format('$0,0.00')}</Col></Row>
                    }
                    {  annio >= 2024 &&
                    <Row><Col>Caolín:</Col><Col className='flex justify-end'>{numeral(MCao).format('$0,0.00')}</Col></Row>
                    }
                    {  annio >= 2024 &&
                    <Row><Col>Riolita:</Col><Col className='flex justify-end'>{numeral(MRio).format('$0,0.00')}</Col></Row>
                    }
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento Educativo:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto Anterior Pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        }
        if (tipo === "bebidas") {
            const baseimp = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BASE_IMPUESTO');
            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{numeral(baseimp).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto causado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        } 
        if (tipo === "isan") {
            const unidades = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'UNIDADES_ENAJENADAS');
            const importe = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'IMPORTE_ENAJENACIONES');

            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Número de unidades enajenadas:</Col><Col className='flex justify-end'>{unidades}</Col></Row>
                    <Row><Col>Importe de enajenaciones:</Col><Col className='flex justify-end'>{numeral(importe).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización ISAN:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos ISAN:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        }
        if (tipo === "residuos") {
           /*  const unidades = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'DEPOSITO_RESIDUOS');
            const importe = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'DEPOSITO_RESIDUOS'); */
            const Toneladas_Residuos = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, "TONELADAS");

            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Número de Toneladas de Residuos:</Col><Col className='flex justify-end'>{numeral(Toneladas_Residuos).format('0,0.000000')}</Col></Row>
                    <Row><Col>Impuesto determinado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento a la educación:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Multas:</Col><Col className='flex justify-end'>{numeral(decData.multas).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                   
                </Modal.Body>
            )
        }
        if (tipo === "nomina") {
            const baseimp = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'BASE_IMPUESTO');
            const empleados = obtenerValorPorDescripcion(decData.mdDetallesDeclaracionesDTO, 'NUMERO_EMPLEADOS');

            return (
                <Modal.Body>
                    <Row><Col>Fecha de pago anterior:</Col><Col className='flex justify-end'>{renderDate(decData.fechaDeclaracion)}</Col></Row>
                    <Row><Col>Número de empleados:</Col><Col className='flex justify-end'>{parseInt(Number(empleados))}</Col></Row>
                    <Row><Col>Base del impuesto:</Col><Col className='flex justify-end'>{numeral(baseimp).format('$0,0.00')}</Col></Row>
                    <Row><Col>Impuesto causado:</Col><Col className='flex justify-end'>{numeral(decData.impuesto).format('$0,0.00')}</Col></Row>
                    <Row><Col>Fomento a la educación:</Col><Col className='flex justify-end'>{numeral(decData.fomentoEducacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Actualización nómina:</Col><Col className='flex justify-end'>{numeral(decData.actualizacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Recargos nómina:</Col><Col className='flex justify-end'>{numeral(decData.recargos).format('$0,0.00')}</Col></Row>
                    <Row><Col>Otros incentivos:</Col><Col className='flex justify-end'>{numeral(decData.otrosIncentivos).format('$0,0.00')}</Col></Row> 
                    <Row><Col>Compensación:</Col><Col className='flex justify-end'>{numeral(decData.compensacion).format('$0,0.00')}</Col></Row>
                    <Row><Col>Saldo a favor:</Col><Col className='flex justify-end'>{numeral(decData.saldoFavor).format('$0,0.00')}</Col></Row>
                    <Row><Col>Monto anterior pagado:</Col><Col className='flex justify-end'>{numeral(montoAnteriorPag).format('$0,0.00')}</Col></Row>
                    <Row><Col>Total:</Col><Col className='flex justify-end'>{numeral(decData.total).format('$0,0.00')}</Col></Row>
                </Modal.Body>
            )
        }
    }

    const ModalDetails = (props) => {
        return (
            <Modal show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Declaración</Modal.Title>
                </Modal.Header>
                {bodyDetails()}
                <Modal.Footer>
                    <Button style={{ width: "50%", margin: "auto" }} variant='secondary' onClick={() => setShow(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    const renderTipo = (valor, complementario) => {
        let dato = valor === 0 ? valor + 1 : valor
        const inicial = valor === 0 ? 'Pago Normal' : 'Pago complementario ' + dato
        return inicial
    }
    const renderDate = (fechaini) => {
        const [anio, mes, dia] = fechaini ? fechaini.split('-') : ['','',''];

        // Formatear la fecha en el orden deseado: día, mes, año
        const fechaTransformada = `${dia}-${mes}-${anio}`;

        return fechaTransformada;
    }



    const renderingData = declaracion ?
        (<Row className='m-2'>
            <Row className='flex items-center justify-center text-center'>
                <Col style={{fontWeight:"bold"}}>Tipo de Declaración</Col>
                <Col style={{fontWeight:"bold"}}>Fecha</Col>
                <Col style={{fontWeight:"bold"}}>Total</Col>
            </Row>
            {declaracion.length !== 0 && declaracion.slice(0, 4).map((dec, index) => {
                if (dec.tipoDeclaracion === null) {
                    return <Row key={index} className='flex justify-center text-gray-300'>*No hay declaraciones anteriores.</Row>
                } else if (dec.tipoDeclaracion === 0){
                    return <Row
                    key={index + "row"}
                    onClick={() => {
                        console.log("has hecho click", );
                        setDecData(dec)
                        setShow(true)
                    }} className='border border-gray-300 rounded-md flex justify-center text-center cursor-pointer hover:shadow-lg'>
                    <Col
                        key={index + "coltipo"}
                        className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                    >{
                            //dec.tipoDeclaracion
                            renderTipo(index, dec.esComplementaria)
                        }</Col>
                    
                    
                </Row>
                }
                return (
                    <Row
                        key={index + "row"}
                        onClick={() => {
                        
                            setDecData(dec)
                            setShow(true)
                            montoAnterior(index);
                          
                        }} className='border border-gray-300 rounded-md flex justify-center text-center cursor-pointer hover:shadow-lg'>
                        <Col
                            key={index + "coltipo"}
                            className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                        >{
                                //dec.tipoDeclaracion
                                renderTipo(index, dec.esComplementaria)
                            }</Col>
                        <Col
                            key={index + "colfecha"}
                            className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                        >{renderDate(dec.fechaDeclaracion)}</Col>
                        <Col
                            key={index + "coltotal"}
                            className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                        >{numeral(dec.total).format('$0,0.00')}</Col>
                    </Row>
                )
            })}
            <ModalDetails />
        </Row>)
        :
        (<Row></Row>)
    return renderingData;
};
export default DeclaracionesAnt;